<template>
  <Teleport to="body">
    <div class="m-slip modal fade" :id="`slipMarket-${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-fullscreen-sm-down">
        <div class="modal-content modal-condition form bg-main">
          <div class="modal-header">
            <h2 class="subtitle-medium"><div class="line-left float-start"></div> <span>{{ $t('slip.head') }}</span>
              <a href="javascript:void(0)" @click="close"><img src="@/assets/image/close.png" class="mx-auto float-end mx-2" width="35"></a>
            </h2>
          </div>
          <div class="modal-body">
            <div class="text-center" v-show="flagLoading">
              <LoadingComponet :loading="flagLoading"/>
            </div>
            <div class="text-center" v-show="!flagLoading">
              <div :id="`slipPrint${id}`">
                <div>
                  <div class="tax-header2 text-center">
                    <h2 class="head-slip  text-white">{{ $t('slip.transConfirm') }}</h2>
                    <p class="text-white mb-0"><small>{{ item.trans_date }}</small></p>
                  </div>
                  <div class="slip-body">
                    <div class="row mb-4">
                      <div class="col-2"><img src="@/assets/image/portfolio3.png" class="mx-auto"></div>
                      <div class="col-10 text-start">
                        <h5 class="card-kkp-title">{{ item.unitholder_name }} ({{ item.agent_shortname }})</h5>
                        <p class="card-kkp-text gray2"><small> {{ $t('slip.unitholderId') }}: {{ item.unitholder_id }}</small></p>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-2"></div>
                      <div class="col-10 text-start">
                        <p class="card-kkp-text gray3"><small> {{ getTitle(item.trans_type) }}</small></p>
                        <h5 class="card-kkp-title">{{ item.fund_code }}</h5>
                        <p class="card-kkp-text gray2"><small>{{ item.fund_name_TH }}</small></p>
                      </div>
                    </div>
                    <div class="row mb-4" v-if="item.fund_code_sw">
                      <div class="col-2"></div>
                      <div class="col-10 text-start">
                        <p class="card-kkp-text gray3"><small> {{ $t('slip.to') }}</small></p>
                        <h5 class="card-kkp-title">{{ item.fund_code_sw }}</h5>
                        <p class="card-kkp-text gray2"><small>{{ item.fund_name_TH_sw }}</small></p>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-2"></div>
                      <div class="col-10 text-start">
                        <p class="card-kkp-text gray3"><small> {{ $t('slip.amount') }}</small></p>
                        <h5 class="card-kkp-amount">{{ TransactionServices.convertTransAmount(item) }} <span>{{ item.unit }}</span></h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-2"></div>
                      <div class="col-10">
                        <p class="card-kkp-text float-start"><small class="fs-d-14"> {{ $t('slip.transStatus') }}</small></p>
                        <p class="card-kkp-text float-end"><small class="fs-d-14" :class="item.trans_status=='C'?'red':''"> {{ TransactionServices.getStatus(status, item.trans_status) }}</small></p>
                        <div class="clearfix"></div>

                        <div v-if="transStatus=='transDCA_status'">
                          <p class="card-kkp-text float-start gray3"><small class="fs-d-14"> {{ $t('slip.transDateStart') }}</small></p>
                          <p class="card-kkp-text float-end"><small class="fs-d-14"> <span>{{ convertDate(item.start_dca_eff_date) }}</span></small></p>
                          <div class="clearfix"></div>
                          <p class="card-kkp-text float-start gray3"><small class="fs-d-14"> {{ $t('slip.transDateEnd') }}</small></p>
                          <p class="card-kkp-text float-end"><small class="fs-d-14"> <span>{{ (item.end_dca_eff_date)?convertDate(item.end_dca_eff_date):$t('slip.notSpecified') }}</span></small></p>
                          <div class="clearfix"></div>
                          <p class="card-kkp-text float-start gray3"><small class="fs-d-14"> {{ $t('slip.dcaOrderDay') }}</small></p>
                          <p class="card-kkp-text float-end"><small class="fs-d-14"> <span>{{ item.dca_order_day }} {{ $t('slip.ofMonth') }}</span></small></p>
                          <div class="clearfix"></div>
                        </div>

                        <div>
                          <p class="card-kkp-text float-start gray3"><small class="fs-d-14"> {{ $t('slip.effDate') }}</small></p>
                          <p class="card-kkp-text float-end"><small class="fs-d-14"> <span>{{ (transStatus=='transDCA_status')?convertDate(item.start_dca_eff_date):item.eff_date }}</span></small></p>
                          <div class="clearfix"></div>
                        </div>
                        <p class="card-kkp-text float-start gray3"><small class="fs-d-14"> {{ $t('slip.refNo') }}</small></p>
                        <p class="card-kkp-text float-end"><small class="fs-d-14"> <span>{{ item.trans_no }}</span></small></p>
                      </div>
                    </div>
                  </div>
                  <div class="tax-footer">
                    <div class="row">
                      <div class="col-2">
                        <p class="card-kkp-text gray3"><small> {{ $t('slip.note') }}:</small></p>
                      </div>
                      <div class="col-10 text-start">
                        <p class="card-kkp-text gray"><small class="gray"> {{ $t('slip.note3') }}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix slip-shadow"></div>
              <div class="d-block d-lg-none mt-5">
                <div class="row mt-3 mb-3">
                  <div class="col-4 col-sm-4">
                    <center>
                      <a :id="`close${id}`" href="javascript:void(0)" @click="close"><img src="@/assets/image/b-home.png" class="mx-auto mb-1"></a>
                      <p class="body2-regular">{{ $t('global.home') }}</p>
                    </center>
                  </div>
                  <div class="col-4 col-sm-4">
                    <center>
                      <a :id="`save${id}`" @click="saveImageHandle"><img src="@/assets/image/b-download.png" class="mx-auto mb-1"></a>
                      <p class="body2-regular">{{ $t('global.save') }}</p>
                    </center>
                  </div>
                  <div class="col-4 col-sm-4">
                    <center>
                      <a :id="`share${id}`" href="javascript:void(0)" @click="shareHandle"><img src="@/assets/image/b-share.png" class="mx-auto mb-1"></a>
                      <p class="body2-regular">{{ $t('global.share') }}</p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-none d-lg-block">
            <div class="row g-3">
              <div class="col-12 col-sm-6 d-none d-md-block"></div>
              <div class="col-12 col-sm-2"></div>
              <div class="col-12 col-sm-4">
                <a :id="`save2${id}`" @click="saveImageHandle" type="button" class="btn w-100 btn-main hvr-float">ดาวน์โหลด</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AlertComponent ref="alertModal"/>
    <EditFundModalComponent ref="editModal"/>
  </Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import LoadingComponet from "@/components/includes/LoadingComponet";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import TransactionServices from "@/services/TransactionServices";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import EditFundModalComponent from "@/components/includes/page/market/buy/modal/EditFundModalComponent";
import html2canvas from 'html2canvas';

export default {
  name: "SlipTransactionModalComponent",
  components: {LoadingComponet, AlertComponent, EditFundModalComponent},
  data() {
    return {
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      item: {},
      types: [],
      status: [],
      flagLoading: false,
      TransactionServices: TransactionServices,
      tranNo: '',
      transStatus: '',
      page: ''
    }
  },
  methods: {
    async open(tranNo, transStatus, page=''){
      this.tranNo = tranNo;
      this.transStatus = transStatus;
      this.flagLoading = true;
      this.modal.show();
      await this.getData(tranNo, transStatus);
      this.flagLoading = false;
      if(page == 'market'){
        this.page = page;
        window?.AdvanceNoticeComponent?.reloadData();
      }
    },

    close(){
      this.modal.hide();
      this.item = {};
      this.types = [];
      this.status = [];
      this.tranNo = '';
      this.transStatus = '';
      this.$emit('cancel');
      // if(this.page == 'market'){
      //   this.$router.go(0);
      // }
    },

    async saveImageHandle(){
      await this.download();
      this.modal.hide();
      this.$emit('cancel');
      // if(this.page == 'market'){
      //   this.$router.go(0);
      // }
    },

    async shareHandle(){
      this.modal.hide();
      this.item = {};
      this.types = [];
      this.status = [];
      this.tranNo = '';
      this.transStatus = '';

      // get slip
      const el = document.getElementById(`slipPrint${this.id}`);
      const canvas = await html2canvas(el);

      canvas.toBlob(async (blob) => {
        const files = [new File([blob], 'image.png', { type: blob.type })];
        const shareData = {
          text: 'Some text',
          title: 'Some title',
          files,
        };
        
        if(navigator.canShare(shareData)){
          try{
            await navigator.share(shareData);
          }
          catch(err){
            if(err.name !== 'AbortError'){
              console.error(err.name, err.message);
            }
          }
        }
        else{
          console.warn('Sharing not supported', shareData);
        }
      });

      this.$emit('cancel');
    },

    getTitle(data){
      let text = '';
      if(data == "BUY"){
        text = this.$t('slip.buyTitle');
        if(this.transStatus == "transDCA_status"){
          text = this.$t('slip.buyDCATitle');
        }
      }
      else if(data == "BUY(DCA)"){
        text = this.$t('slip.buyDCATitle');
      }
      else if(data == "SELL"){
        text = this.$t('slip.sellTitle');
      }
      else{
        text = this.$t('slip.swTitle');
      }

      return text;
    },

    convertDate(date){
      let temp = '';
      let _date = date;
      if(date != undefined){
        if(this.item.trans_status=='C'){
          return _date;
        }

        const tempDate = _date.split('/');
        let date = tempDate[0];
        let mth = tempDate[1];
        let year = tempDate[2];

        if(this.lang == 'TH'){
          year = '25'+year;
          if(mth == '01') mth = 'ม.ค.';
          else if(mth == '02') mth = 'ก.พ.';
          else if(mth == '03') mth = 'มี.ค.';
          else if(mth == '04') mth = 'เม.ย.';
          else if(mth == '05') mth = 'พ.ค.';
          else if(mth == '06') mth = 'มิ.ย.';
          else if(mth == '07') mth = 'ก.ค.';
          else if(mth == '08') mth = 'ส.ค.';
          else if(mth == '09') mth = 'ก.ย.';
          else if(mth == '10') mth = 'ต.ค.';
          else if(mth == '11') mth = 'พ.ย.';
          else if(mth == '12') mth = 'ธ.ค.';
          else mth = '';
        }
        else{
          year = '25'+year;
          year = parseInt(year)-543;
          year = year.toString();
          if(mth == '01') mth = 'Jan.';
          else if(mth == '02') mth = 'Feb.';
          else if(mth == '03') mth = 'Mar.';
          else if(mth == '04') mth = 'Apr.';
          else if(mth == '05') mth = 'May';
          else if(mth == '06') mth = 'Jun.';
          else if(mth == '07') mth = 'Jul.';
          else if(mth == '08') mth = 'Aug.';
          else if(mth == '09') mth = 'Sep.';
          else if(mth == '10') mth = 'Oct.';
          else if(mth == '11') mth = 'Nov.';
          else if(mth == '12') mth = 'Dec.';
          else mth = '';
        }

        temp = date+' '+mth+' '+year;
      }

      return temp;
    },

    editHandle(obj){
      this.modal.hide();
      this.$refs.editModal.open(obj)
    },

    async getLookupTransactionType(){
      const params = {data_name:'trans_type_part1'};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.types = result;
      }, ()=>{this.types = Constants.transaction.types});
    },

    async getLookupTransactionStatus(transStatus){
      const params = {data_name:transStatus};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.status = result;
      }, ()=>{this.types = Constants.transaction.types});
    },

    async getData(transNo, transStatus){
      const el = document.getElementById(`slipPrint${this.id}`);
      el.style.display = 'block';
      await this.getLookupTransactionStatus(transStatus);
      let api = "/trans/GetTransInfo";
      if(transStatus == 'transDCA_status'){
        api = "/trans/GetDCATransInfo";
      }
      const params = {username: this.username, trans_no: transNo};
      const resp = await this.$HttpServices.postData(api, params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.item = result[0];
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null);
      });
    },

    async download(){
      var v_this = this;
      html2canvas(document.getElementById(`slipPrint${this.id}`)).then(function (canvas) {
        var link = document.createElement("a");
        link.download =  v_this.tranNo + ".jpg";
        link.href = canvas.toDataURL("image/jpeg");
        link.target = '_blank';
        link.click();
      });
    }

  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.modal);
  }
}
</script>

<style scoped>

</style>