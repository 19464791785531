import axios from "axios";
import DeviceDetector from "device-detector-js";
import Constants from "@/utils/Constants";
import HttpServices from "@/services/HttpServices";

const bankList = {
    "TH": {
        "ธนาคารแห่งประเทศไทย": "BANK.png",
        "ธนาคารกรุงเทพ": "BBL.png",
        "ธนาคารกสิกรไทย": "KBANK.png",
        "ธนาคารกรุงไทย": "KTB.png",
        "ธนาคารทหารไทยธนชาต": "TTB.png",
        "ธนาคารไทยพาณิชย์": "SCB.png",
        "ธนาคารซิตี้แบงก์ เอ็น เอ": "CITI.png",
        "ธนาคารยูโอบี": "UOBT.png",
        "ธนาคารสแตนดาร์ดชาร์เตอร์ด": "SCNB.png",
        "ธนาคารซีไอเอ็มบี": "CIMBT.png",
        "ธนาคารกรุงศรีอยุธยา": "BAY.png",
        "ธนาคารออมสิน": "GSB.png",
        "ธนาคารฮ่องกงและเซี่ยงไฮ้": "HSBC.png",
        "ธนาคารดอยซ์แบงก์": "DB.png",
        "ธนาคารอาคารสงเคราะห์": "GHB.png",
        "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร": "BAAC.png",
        "ธนาคารมิซูโฮ": "Mizuho.png",
        "ธนาคารแห่งประเทศจีน": "BOCHK.png",
        "ธนาคารอิสลาม": "ISBT.png",
        "ธนาคารทิสโก้": "TISCO.png",
        "ธนาคารเกียรตินาคินภัทร": "KKP.png",
        "ธนาคารไอซีบีซี": "BKCH.png",
        "ธนาคารไทยเครดิต เพื่อรายย่อย": "TCD.png",
        "ธนาคารแลนด์ แอนด์ เฮ้าส์": "LHFG.png",
        "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อม": "SME.png",
        "Capital One": "BANK.png",
        "JPMorgan": "J.P. Morgan.png"
    },
    "EN": {
        "BANK OF THAILAND": "BANK.png",
        "BANGKOK BANK": "BBL.png",
        "KASIKORNBANK": "KBANK.png",
        "KRUNG THAI BANK": "KTB.png",
        "TMBTHANACHART BANK": "TTB.png",
        "SIAM COMMERCIAL BANK": "SCB.png",
        "CITIBANK N.A.": "CITI.png",
        "UOB": "UOBT.png",
        "STANDARD CHARTERED BANK": "SCNB.png",
        "CIMB THAI BANK": "CIMBT.png",
        "BANK OF AYUDHAYA": "BAY.png",
        "GOVERNMENT SAVING BANK": "GSB.png",
        "HONGKONG & SHANGHAI": "HSBC.png",
        "DEUTSCHE BANK": "DB.png",
        "GOVERNMENT HOUSING BANK": "GHB.png",
        "BANK FOR AGRICULTURAL AND AGRICULTURAL CO-OPERATIVES": "BAAC.png",
        "MIZUHO CORPORATE BANK": "Mizuho.png",
        "BANK OF CHINA": "BOCHK.png",
        "ISLAMIC BANK": "ISBT.png",
        "TISCO BANK": "TISCO.png",
        "KIATNAKIN PHATRA": "KKP.png",
        "INDUSTRIAL AND COMMERCIAL BANK OF CHINA": "BKCH.png",
        "THE THAI CREDIT RETAIL": "TCD.png",
        "LAND AND HOUSES BANK": "LHFG.png",
        "SMALL AND MEDIUM ENTERPRISE DEVELOPMENT": "SME.png",
        "Capital One": "BANK.png",
        "JPMorgan": "J.P. Morgan.png"
    }
}

const StringUtils = {
    secretEmailText(str){
        if(!str){
            return '';
        }
        const tmps = str.split('@');
        const len = tmps[0].length;
        let start = 0;
        if(len>5){
            start = len-6;
        }else if(len>3){
            start = len-2;
        }
        let sss = tmps[0].split('');
        for(let i=start;i<len-1;i++){
            sss[i] = "*";
        }
        str = sss.join('');
        return `${str}${(tmps.length>1?`@${tmps[1]}`:'')}`;
    },
    secretMobileText(str){
        if(!str){
            return '';
        }
        const len = str.length;
        let start = 0;
        if(len>5){
            start = len-6;
        }else if(len>3){
            start = len-2;
        }
        str = str.split('');
        for(let i=start;i<len-1;i++){
            str[i] = "*";
        }
        str = str.join('');
        return str;
    },
    async getClientIpAddress(username = "", idCard = ""){
        try {
            let resp = await axios.get('https://api.ipify.org?format=json');
            let ip = (resp&&resp?.data)? resp?.data?.ip: '';
            const params = {
                "ipify": ip,
                "username": username,
                "idCard": idCard
            };

            resp = await HttpServices.postRestData("/utils/getClientIp", params);
            if(resp?.dotnetIp) {
                ip = resp.dotnetIp;
            }

            return ip;
        } catch (error) {
            return '';
        }
    },
    getClientDeviceInfo(){
        const deviceDetector = new DeviceDetector();
        const userAgent = window.navigator.userAgent;
        const device = deviceDetector.parse(userAgent);

        const obj = {
            os: device.os.name+(device.os.version?' '+device.os.version:''),
            device: device.device.brand+(device.device.model?' '+device.device.model:''),
            browser: device.client.name+(device.client.version?' '+device.client.version:'')
        };
        return obj;
    },

    getUUIDV4() {
        return 'uuxxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    getEditable(editable){
        return editable&&editable=='Y'?true:false;
    },
    sortObject(objData){
        const ordered = Object.keys(objData).sort().reduce(
            (obj, key) => {
                obj[key] = objData[key];
                return obj;
            },
            {}
        );

        return ordered;
    },
    copyJSON(src){
        return JSON.parse(JSON.stringify(src));
    },
    decimalFormat(value){
        if(value==undefined||value==""||value==null){
            return "0.00";
        }
        return Number(""+value).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    },
    fileToByteArray(file){
        return new Promise((resolve)=>{
            const reader = new FileReader();
            let fileByteArray = [];
            reader.readAsArrayBuffer(file);
            reader.onloadend = async function (evt) {
                if (evt.target.readyState == FileReader.DONE) {
                    let arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for(let i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                    resolve(fileByteArray);
                }
            }
        });
    },
    byteArrayToBase64(bytes){
        const base64 = btoa(
            new Uint8Array(bytes)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return base64;
    },
    getColorFund(fundType){
        let color = "";
        if(fundType){
            const obj = Constants.fundColor[fundType.toLowerCase()];
            if(obj){
                color = obj;
            }
        }
        return color;
    },
    getColorOrder(index){
        let color = "";
        // if(fundType){
            const obj = Constants.colorOrder[index];
            if(obj){
                color = obj;
            }
        // }
        return color;
    },
    getRiskImage(riskLevel){
        let img = '';
        try{
            img = require(`@/assets/image/risk0${riskLevel}.png`);
        }catch(e){
            img = '';
        }
        return img;
    },
    getRiskIPQImage(riskLevel){
        let img = '';
        try{
            img = require(`@/assets/image/ipq${riskLevel}.png`);
        }catch(e){
            img = '';
        }
        return img;
    },
    randomString(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    bankImage(bankName, lang){
        let img = require(`@/assets/image/BANK.png`);
        let bankListLang = bankList?.[lang];
        if(bankListLang !== undefined && bankListLang) {
            try {
                let banks = Object.keys(bankListLang);
                banks?.forEach((bank) => {
                    if(bankName.includes(bank)) {
                        img = require(`@/assets/image/${bankListLang[bank]}`);
                        throw "BreakException";
                    }
                });
            // eslint-disable-next-line
            } catch (e) {}
        }

        return img;
    },
    displayAddress(obj, amphur, province, country) {
        let v = obj;
        if(!(obj instanceof Object)){
          v = JSON.parse(obj);
        }

        let addr = '';
        if(v.addr != '' && v.addr){
          addr += v.addr + ' ';
        }

        if(v.moo != '' && v.moo){
          addr += v.moo + ' ';
        }

        if(v.village != '' && v.village){
          addr += v.village + ' ';
        }

        if(v.building != '' && v.building){
          addr += v.building + ' ';
        }

        if(v.room != '' && v.room){
          addr += v.room + ' ';
        }

        if(v.floor != '' && v.floor){
          addr += v.floor + ' ';
        }

        if(v.lane != '' && v.lane){
          addr += v.lane + ' ';
        }

        if(v.road != '' && v.road){
          addr += v.road + ' ';
        }

        if(v.district != '' && v.district){
            addr += v.district + ' ';
        }

        if(v.amphur != '' && v.amphur){
          const c = amphur.find(c=>c.data_map==v.amphur);
          if(c){
            addr += c.data_value + ' ';
          }
        }

        if(v.province != '' && v.province){
          const c = province.find(c=>c.data_map==v.province);
          if(c){
            addr += c.data_value + ' ';
          }
        }

        if(v.country != '' && v.country){
          const c = country.find(c=>c.data_map==v.country);
          if(c){
            addr += c.data_value + ' ';
          }
        }

        if(v.zipcode != '' && v.zipcode){
          addr += v.zipcode;
        }

        return addr;
    }
};

export default StringUtils;