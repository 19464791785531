import HttpServices from "@/services/HttpServices";
import Constants from "@/utils/Constants";
import store from "@/utils/store";
import StringUtils from "@/utils/StringUtils";

const SignatureServices = {
    async generateToken(loginPage, reqUnitholderId = null, reqIdCard = null, manual = false, recursive = false){
        let ipAddress = store.getData(Constants.session.clientIp);

        let username = loginPage?loginPage:Constants.global.apiGuestLoginPage;
        let unitholderId = loginPage?loginPage:Constants.global.apiGuestLoginPage;
        let idCard = loginPage?loginPage:Constants.global.apiGuestLoginPage;

        if(!ipAddress) {
            ipAddress = await StringUtils.getClientIpAddress(username, idCard);
            store.setData(Constants.session.clientIp, ipAddress);
        }

        const user = store.getJsonData(Constants.session.user);
        if(user&&user.type==Constants.userType.user){
            username = user.username;
            unitholderId = Constants.global.apiMemberLoginPage;
            idCard = Constants.global.apiMemberLoginPage;
        }else if(user&&user.type==Constants.userType.guest){
            username = Constants.global.apiGuestLoginPage;
            unitholderId = user.unitholder?user.unitholder:Constants.global.apiGuestLoginPage;
            idCard = user.idCard?user.idCard:Constants.global.apiGuestLoginPage;
        }else if(manual) {
            username = loginPage;
            unitholderId = user?.unitholder?user.unitholder:reqUnitholderId;
            idCard = user?.idCard?user.idCard:reqIdCard;
        }
        const sigParams = {
            username: username,
            unitholder_id: unitholderId,
            idCard: idCard,
            client_ip_addr: ipAddress
        };
        let resp = await HttpServices.postRestData("/signature", sigParams);
        if(resp){
            try {
                const params = {
                    client_code: resp.clientCode,
                    request_time: resp.requestTime,
                    username: username,
                    unitholder_id: unitholderId,
                    idCard: idCard,
                    signature: resp.signature,
                    lang: store.getData(Constants.session.lang),
                    client_ip_addr: ipAddress
                };
                resp = await HttpServices.postGetTokenData("/auth/GetToken", params);
                if(resp&&resp.header&&resp.header[0].status==Constants.response.header.success){
                    let result = resp.result[0];
                    store.setJsonData(Constants.session.token, result);
                    return resp;
                }
            } catch (error) {
                if(typeof recursive == "number") {
                    recursive = recursive - 1;
                } else {
                    recursive = Constants.reSendApi;
                }
                if(recursive > 0) {
                    return await this.generateToken(loginPage, reqUnitholderId, reqIdCard, manual, recursive);
                }
            }

            return resp;
        }

        return null;
    }
};

export default SignatureServices;