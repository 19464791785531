<template>
  <Teleport to="body">
    <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
    <div class="m-fund-detail modal fade" :id="`detail-sell${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
      <div class="modal-content modal-condition form bg-main">
        <div class="modal-header">
          <h2 class="subtitle-medium d-flex align-items-center">
            <div class="line-left"></div>
            <span class="head-hidden-m flex-grow-1">{{ $t('market.sell.head') }}</span>

            <a href="javascript:void(0)" class="kkp-toggle dropdown-toggle btn-toggle" type="button" :id="`dropdownMenuButton1-${id}`">
              <p class="body2-medium float-end mb-0"><img src="@/assets/image/portfolio3.png" width="30" class="mx-auto">{{ data.curUnitHolder }} </p>
            </a>
            <a :id="`close-${id}`" href="javascript:void(0)" @click="closeConfirm"><img src="@/assets/image/close.png" class="mx-auto px-2"></a>

        </h2>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-2 col-sm-1"></div>
            <div class="col-12 col-lg-8 col-sm-10">
              <div class="content-box6">
                <h4 class="subtitle-regular">{{ $t('market.sell.check') }}</h4>

                <div class="subbox">
                  <p class="body2-regular">{{ $t('market.sell.head') }}</p>
                  <div class="subbox m-b8" v-if="data.fundData">
                    <h5 class="body-medium">{{ data.fundData.fund_code }}</h5>
                    <p class="body2-regular hint-text">{{ (lang=='TH')?data.fundData.fund_name_TH:data.fundData.fund_name_EN }}</p>
                    <p class="float-start me-2 head-tag">Risk LV. <img :src="getRiskImage(data.fundData.fund_risk_level)" class="mx-auto"></p>
                    <p class="float-start mb-2  head-tag">{{ data.fundData.fund_class_type }}</p>
                    <div class="clearfix"></div>
                  </div>

                  <div class="subbox none-br m-b16" v-if="data.item">
                    <div class="row">
                      <div class="col-6"><h5 class="body2-regular c-placeholder mb-0">{{ $t('market.sell.total') }}</h5></div>
                      <div class="col-6">
                        <h5 class="subtitle-medium float-end mb-0">{{ data.item.unitSell }} {{ data.unit }}</h5>
                      </div>
                    </div>
                  </div>
                  <h4 class="body2-regular">{{ $t('market.sell.detail') }}</h4>
                  <div class="subbox m-b16" v-if="data.item" :style="[validateFund?{border: '2px solid red'}:'']">
                    <div class="row">
                      <div class="col-6 col-sm-6">
                        <p class="body2-regular float-start c-placeholder m-0">{{ $t('market.sell.transDate') }}</p>
                      </div>
                      <div class="col-6 col-sm-6">
                        <p class="body-regular float-end f-17 m-0">{{ data.today }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-sm-6">
                        <p class="body2-regular float-start c-placeholder m-0" :style="[validateFund?{color: 'red'}:'']">{{ $t('market.sell.effDate') }}</p>
                      </div>
                      <div class="col-6 col-sm-6">
                        <p class="body-regular float-end f-17 m-0" :style="[validateFund?{color: 'red'}:'']">{{ convertDate(data.item.dateSell) }}</p>
                      </div>
                    </div>
                  </div>
                  <p class="va hvr-float label-required" v-if="validateFund" style="color: red;">
                    <img src="@/assets/image/Information2.png" class="mx-auto me-2"> {{ effDateValid }}
                  </p>
                  <div class="clearfix"></div>

                  <h4 class="body2-regular">{{ $t('market.sell.payment') }}</h4>
                  <div class="subbox">
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <div class="head-slider" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                          <img :src="bankImage(data.curBankName)" class="mx-auto float-start me-3">
                          <h5 class="subtitle-medium mb-0">{{ data.curBankName }}</h5>
                          <p class="float-start me-2 mb-0 head-tag m-0"><img src="@/assets/image/book.png" class="mx-auto"> {{ data.curPayment }}</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row g-3 m-l-t24 d-block d-sm-none">
              <div class="col-12 col-sm-6 d-none d-md-block"></div>
              <div class="col-12 col-sm-3">
                <button :id="`c1-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="confirm">{{ $t('global.confirm') }}</button>
              </div>
              <div class="col-12 col-sm-3 order-sm-1 order-2">
                <button :id="`e1-${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="edit">{{ $t('global.edit') }}</button>
              </div>
            </div>

          </div>
          <div class="col-12 col-lg-2 col-sm-1"></div>
        </div>
      </div>
      <div class="modal-footer d-none d-sm-block">
        <div class="container-md">
          <div class="row g-3">
            <div class="col-12 col-sm-6 d-none d-lg-block"></div>
            <div class="col-12 col-lg-3 col-sm-12 order-lg-1 order-2">
              <button :id="`e2-${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="edit">{{ $t('global.edit') }}</button>
            </div>
            <div class="col-12 col-lg-3 col-sm-12 order-lg-2 order-1">
              <button :id="`c2-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="confirm">{{ $t('global.confirm') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="m-pwd modal fade logout" :id="`password${id}`" ref="oldPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content modal-logout modal-medium">
      <div class="modal-body p-0">
        <div class="text-center">
          <center><img src="@/assets/image/password1.png" class="mx-auto mt-56 mb-48"></center>
          <h2 class="title-medium m-s-b16">{{ $t('passwordSetting.inputPassword') }}</h2>
          <p class="body2-regular description-text m-m-b32">{{ $t('passwordSetting.inputPasswordDetail') }}</p>
          <div class="m-b56">
            <div class="position-relative form-toggle-password ">
              <input :type="passwordType" class="form-control body-regular icon icon-password" placeholder="*********" v-model="item.password" v-on:keyup.enter="verifyPasswordHandle" :disabled="flagLoadingOverlay">
              <div class="toggle-password" style="cursor: pointer;margin-left: -3px;" @click="togglePassword">
                <img data-status-icon="close" :src="imgEye">
              </div>
            </div>
            <div class="va hvr-float label-required" v-if="validate.password">
              <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.passwordRequired') }}
            </div>
            <div class="va hvr-float px-2 label-required" v-if="apiError.password">
              <img src="@/assets/image/Information2.png" class="mx-auto me-2">  <span v-html="apiError.password?apiError.password:''"></span>
            </div>
          </div>

          <div class="row g-3">
            <div class="col-12 col-sm-12 col-lg-6 order-lg-1 order-md-2  order-sm-2 order-2">
              <button :id="`closeP-${id}`" type="button" data-bs-dismiss="modal" class="btn w-100 btn-sec hvr-float" @click="closePassword">
                {{ $t('global.cancel') }}
              </button>
            </div>
            <div class="col-12 col-sm-12 col-lg-6 order-lg-2  order-md-1  order-sm-1  order-1">
              <button :id="`cP-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="verifyPasswordHandle" :disabled="flagLoadingOverlay">
                {{ $t('global.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<SlipTransactionModalComponent ref="modalSlip" @cancel="cancel"/>
<AlertComponent ref="alertModal"/>
<AlertConfirmComponent ref="alertConfirmModal"/>
</Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import SlipTransactionModalComponent from "@/components/includes/modals/SlipTransactionModalComponent";
import moment from 'moment';
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";
import AlertConfirmComponent from "@/components/includes/alerts/AlertConfirmComponent";

export default {
  name: "SellFundDetailModalComponent",
  components: {SlipTransactionModalComponent, AlertComponent, LoadingOverlayComponet, AlertConfirmComponent},
  data() {
    return {
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      data: {},
      item: {},
      validate: {},
      apiError: {},
      flagLoading: false,
      flagLoadingOverlay: false,
      validateFund: false,
      passwordType: 'password',
      imgEye: require('@/assets/image/close.svg'),
      effDateValid: this.$t('market.sell.effDateValid')
    }
  },
  methods: {
    open(data) {
      this.flagLoadingOverlay = true;
      this.data = data;
      // await this.validateFundDatetime();
      this.validateFund = false;
      this.modal.show();
      this.flagLoadingOverlay = false;
    },

    closeConfirm(){
      this.$refs.alertConfirmModal.show([this.$t('market.sell.confirmCloseDesc')], 'warning', ()=>{
        this.close();
      }, this.$t('global.warning'), this.$t('market.sell.confirmCloseBTN'));
    },

    close() {
      this.validateFund = false;
      this.modal.hide();
      this.$emit('clear');
    },

    async confirm() {
      this.flagLoadingOverlay = true;
      this.modal.hide();
      await this.validateFundDatetime();
      // this.PasswordModal.show();
      this.flagLoadingOverlay = false;
    },

    edit() {
      this.modal.hide();
      this.$emit('close');
    },

    convertDate(date){
      return moment(String(date), 'DD/MM/YYYY').format(Constants.date.dateShortYearDisplay);
    },

    checkValidateVerifyPassword(){
      let flagRequired = true;
      this.validate = {};
      if(!this.item.password){
        this.validate.password = true;
        flagRequired = false;
      }

      return flagRequired;
    },

    closePassword(){
      this.item.password = '';
      if(this.passwordType != 'password'){
        this.togglePassword();
      }
      this.PasswordModal.hide();
      this.modal.show();
    },

    cancel(){
      this.$emit('clear');
    },

    togglePassword(){
      if(this.passwordType == 'password'){
        this.passwordType = 'text';
        this.imgEye = require('@/assets/image/open.svg');
      }
      else{
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
      }
    },

    async validateFundDatetime(){
      let eff_date = '';
      let tempDate = '';
      tempDate = this.data.item.dateSell.split('/');
      eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
      eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
      const params = {username: this.username, fund_no: this.data.fund_no, trans_type: "SELL", eff_date: eff_date};
      const resp = await this.$HttpServices.postData("/fund/ValidateFundDatetime", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'N'){
            this.data.item.dateSell = v.eff_date;
            this.validateFund = true;
            this.modal.show();
          }
          else{
            this.PasswordModal.show();
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null, ()=>{
          this.modal.show();
        });
        const headers = resp.header;
        const result = resp.result;
        const focusMessages = MessageUtils.getFocusMessageError(headers);
        if(focusMessages.length>0){
          focusMessages.map(v=>{
            if(v.column == 'eff_date'){
              if(v.msg_desc) this.effDateValid = v.msg_desc;

              for(const v of result){
                if(v.is_valid == 'N'){
                  this.data.item.dateSell = v.eff_date;
                  this.validateFund = true;
                  this.modal.show();
                }
              }

            }
          });
        }
      });
    },

    async verifyPasswordHandle(){
      if(!this.checkValidateVerifyPassword()){
        return;
      }
      this.flagLoadingOverlay = true;
      const params = {username: this.username, validated_field: 'password', validated_value: this.item.password};
      const resp = await this.$HttpServices.postData("/customer/ValidateDataByUsername", params);
      this.item.password = '';
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        this.validate = {};
        this.apiError = {};
        this.PasswordModal.hide();
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'Y'){
            await this.saveTrans();
          }
        }

      }, ()=>{
        this.PasswordModal.hide();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.PasswordModal.show()});
      });
      this.flagLoadingOverlay = false;
    },

    async saveTrans(){
      this.flagLoadingOverlay = true;
      let req_amt = 0;
      let req_unit = 0;
      let reqAllUnit = 'N';
      let eff_date = '';
      let tempDate = '';
      let amt = this.data.item.unitSell;
      if(typeof amt == "string") {
        amt = amt.replaceAll(',', '');
      }
      if(this.data.unit == 'THB'){
        req_amt = parseFloat(amt);
      }
      else{
        req_unit = parseFloat(amt);
      }

      if(this.data.item.switch){
        reqAllUnit = 'Y';
      }

      tempDate = this.data.item.dateSell.split('/');
      eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
      eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
      const params = {username: this.username, fund_no: this.data.fund_no, trans_type: "SELL", fund_no_sw: 0, unitholder_id: this.data.curUnitHolder, req_amt: req_amt, req_unit: req_unit, eff_date: eff_date, payment: this.data.payment, req_all_unit: reqAllUnit};
      const resp = await this.$HttpServices.postData("/trans/SaveTrans", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.save_result == 'Y'){
            this.$refs.modalSlip.open(v.trans_no, 'trans_status', 'market');
            this.$emit('clear');
            window.$("body").trigger("refreshEnquiry");
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
      this.flagLoadingOverlay = false;
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    bankImage(data){
      return StringUtils.bankImage(data, this.lang);
    },
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.modal);
    this.PasswordModal = new Modal(this.$refs.oldPasswordModal);
  }
}
</script>

<style scoped>

</style>