import Constants from "@/utils/Constants";
import MessageUtils from "@/utils/MessageUtils";

const ValidateUtils = {
    validateUsername(username){
        const regex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,12}');
        return regex.test(username);
    },
    validatePassword(password){
        /* eslint-disable */
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z!@#$%^&*()_+=\[{\]};:<>|./?,]{8,64}$/;
        return regex.test(password);
    },
    validateEmail(email){
        /* eslint-disable */
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    },
    validateResponseSuccess(headers){
        if(headers.length>0&&headers[0].status.toLowerCase()==Constants.response.header.success){
            return true;
        }
        return false;
    },
    async validateResponse(resp, alertRef, successCb, errorCb, alertCb){
        if(resp&&resp.header){
            const headers = resp.header;
            if(this.validateResponseSuccess(headers)){
                if(successCb){
                    await successCb();
                }
                MessageUtils.displayMessage(resp, alertRef, null);
            }else{
                if(errorCb){
                    await errorCb();
                }
            }
        }else{
            if(alertRef&&resp&&resp.errorMessage){
                alertRef.show([resp.errorMessage], 'error', alertCb);
            }
        }
    },

    validateResponse2(resp, alertRef, successCb, errorCb, alertCb){
        if(resp&&resp.header){
            const headers = resp.header;
            if(this.validateResponseSuccess(headers)){
                const alertMessages = MessageUtils.getPopupMessageError(headers);
                if(alertMessages.length<=0){
                    if(successCb){
                        successCb();
                    }
                }
                else{
                    MessageUtils.displayMessage(resp, alertRef, null, ()=>{
                        if(successCb){
                            successCb();
                        }
                    });
                }
            }else{
                if(errorCb){
                    errorCb();
                }
            }
        }else{
            if(alertRef&&resp&&resp.errorMessage){
                alertRef.show([resp.errorMessage], 'error', alertCb);
            }
        }
    }
};

export default ValidateUtils;