<template>
  <div class="m-suit modal fade logout" ref="modal" :id="`suitAlert${id}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout">
        <div class="modal-body p-0">
          <img src="@/assets/image/bg-02.jpg" class="mx-auto w-100 top-br-16">
          <div class="p-4">
            <h2>{{ header }}</h2>
            <p class="va hvr-float label-required mb-0" v-if="parseInt(expireDay)>=0&&parseInt(expireDay)<=30">
              <img src="@/assets/image/Information2.png" class="mx-auto" v-if="parseInt(expireDay)<=30">
              <small v-if="parseInt(expireDay)>0&&parseInt(expireDay)<=30"> {{ $t('modal.suit.expire') }} {{ expireDay }} {{ $t('modal.suit.day') }}</small>
              <small v-else-if="parseInt(expireDay)==0"> {{ $t('modal.suit.expired') }}</small>
              <small v-else></small>
            </p>

            <div class="text-center">
              <div class="clearfix"></div>
              <div class="row m-t56 g-2">
                <div class="col-12 col-sm-12 col-lg-6 order-lg-1 order-md-2 order-sm-2 order-2">
                  <button :id="`close${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="closeHandle">{{ $t('global.close') }}</button>
                </div>
                <div class="col-12 col-sm-12 col-lg-6 order-lg-2 order-md-1 order-sm-1 order-1">
                  <button :id="`cf${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="submitHandle">{{ $t('global.continue') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import ValidateUtils from "@/utils/ValidateUtils";
import {Modal} from "bootstrap";
import Constants from "@/utils/Constants";

export default {
  name: "SuitAlertComponent",
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      requestPage: 'popup_suit_page',
      username: '',
      modal: null,
      expireDay: '',
      unitholderId: '',
      header: this.$t('modal.suit.header'),
      loginType: '',
      flagLoadingOverlay: false
    }
  },
  methods: {
    async getData(){
      let params = '';
      if(this.loginType == Constants.userType.user){
        params = {username: this.username, unitholder_id: '', data_name: 'suit_expired_days', request_page: this.requestPage};
      }
      else{
        params = {username: 'guestlogin_page', unitholder_id: this.username, data_name: 'suit_expired_days', request_page: this.requestPage};
      }
      let resp = await this.$HttpServices.postData("/customer/GetSingleDataByUsername", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.expireDay = result[0].data_value;
      }, ()=>{
        this.expireDay = 0;
      });
    },
    // async getExpire(){
    //   let params = {unitholder_id: this.unitholderId, data_name: 'suit_expired_days', request_page: this.requestPage};
    //   let resp = await this.$HttpServices.postData("/customer/GetSingleDataByUnitholder", params);
    //   ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
    //     const result = resp.result;
    //     this.expireDay = result[0].data_value;
  
    //   }, ()=>{
    //     this.expireDay = 0;
  
    //   });
    // },
    async getNoti(){
      const params = {username: this.username, noti_category_list: 'SUIT'};
      const resp = await this.$HttpServices.postData("/noti/GetNotiList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        let result = resp.result;
        if(result){
          this.header = result[0].noti_detail;
        }
        else{
          this.header = this.$t('modal.suit.header');
        }
      }, null);
    },

    closeHandle(){
      this.$emit('cancel');
      this.modal.hide();
    },
    submitHandle(){
      this.$emit('submit', {from: 'suit', unitholder: this.unitholderId});
      this.modal.hide();
      this.$router.replace({path: Constants.basePath + 'form-consent', query: {from: 'suit', unitholder: this.unitholderId}});
    },

    /****** common ******/
    async initialData(unitholderId){
      this.unitholderId = unitholderId;

      this.modal.show();
      this.$emit('valid', true);
      try{
        if(this.loginType==Constants.userType.user) {
          await this.getNoti();
        }
        await this.getData();
        // await this.getExpire();
      }finally {
        //
      }

    }
    /****** common ******/
  },
  mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;
    this.loginType = user.type;
    if(this.loginType == Constants.userType.guest){
      this.username = user.unitholder;
    }

    this.modal = new Modal(this.$refs.modal);
  }
}
</script>

<style scoped>

</style>