import { createWebHistory, createRouter } from "vue-router";
import routes from '@/routes';
import store from "@/utils/store";
import Constants from "@/utils/Constants";

// configure router
const router = createRouter({
    history: createWebHistory(),
    routes, // short for routes: routes
    linkActiveClass: 'active',
    scrollBehavior: (to, from ,savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach(async (to, from, next) => {
    // let lang = store.getData(Constants.session.lang);
    // lang = lang?lang.toLowerCase():'th';
    // loadLanguageAsync(lang).then(() => next());
    if (to.meta.middleware) {
        const validUser = store.getJsonData(Constants.session.user);
        if(validUser&&validUser.type==Constants.userType.guest){
            let fromPath = from.fullPath;
            const fromName = from.name;
            const toName = to.name;
            if(!(toName=='DownloadDocPage'||toName=='FormConsentPage')){
                if(!fromName){
                    fromPath = 'form-consent';
                }
                return next(Constants.basePath + fromPath);
            }
        }
        const userId = store.getCookie(Constants.cookies.user);
        if(validUser&&userId!=validUser.userId){
            store.removeAllData();
            if(validUser){
                const loginType = validUser.type;
                let redirect = 'auth';
                if(loginType==Constants.userType.guest){
                    const query = validUser.query;
                    redirect = `guest${query?`?p=${query}`:''}`;
                }
                return next(Constants.basePath + redirect);
            }
        }

        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
