<template>
  <div id="pendingListSidebar" class="sidenav drawer-sidenav-6 actionsidebar">
    <div class="m-pending content">

      <div class="hd">
          <h4 class="mb-0 d-flex align-items-center subtitle-medium">
            <div class="line-left float-start"></div>
            {{ $t('pending.header') }}
          </h4>
          <div class="d-flex align-items-center ms-auto">
            <a id="bx" href="javascript:void(0)" @click="NavCtrl.closeAllNavHandle()">
            <img src="@/assets/image/x-round.png" class="">
            </a>
          </div>
      </div>

    <!-- @rewrite-html
    <div class="top-logo">
      <a id="bx" href="javascript:void(0)" class="closebtn" @click="NavCtrl.closeAllNavHandle()"><img src="@/assets/image/x.svg" width="40" class="mx-auto pe-2"></a>
    </div>
    -->

    <div class="bd">
      <!--
      <h5 class="head-title">{{ $t('pending.header') }}</h5>
      -->

      <ul class="nav nav-tab-filter p-0" id="myTab">
        <li class="nav-item">
          <a id="b1" href="#tabPendingAll" class="nav-link head-tag active" data-bs-toggle="tab">{{ $t('pending.all') }}</a>
        </li>
        <li class="nav-item">
          <a id="b2" href="#tabPendingPending" class="nav-link head-tag" data-bs-toggle="tab">{{ $t('pending.pending') }}</a>
        </li>
        <li class="nav-item">
          <a id="b3" href="#tabPendingCancel" class="nav-link head-tag" data-bs-toggle="tab">{{ $t('pending.cancel') }}</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade show active" id="tabPendingAll">
          <div v-show="flagLoading">
            <div class="content-box4 d-flex">
              <div class="col-12">
                <LoadingComponet :loading="flagLoading"/>
              </div>
            </div>
          </div>
          <div v-show="!flagLoading">
            <div class="content-box4 d-flex" v-if="items.length==0">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/NotFound.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
            <div class="card card-pending-list" :type="(o.channel=='KKPAM Online')?'button':''" v-for="(o,index) of items" :key="`pendingListResult${index}`" :id="`pendingListResult${index}`" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']" @click="openDetail(o)">
              <div class="card-body">
                <div class="row">
                  <div class="col-3 col-md-3">
                    <!--
                    <h5 class="text-center title">
                      {{ TransactionServices.displayDate(o.eff_date) }}<br>
                      <small>{{ TransactionServices.displayMonthYear(o.eff_date) }}</small>
                    </h5>
                    -->

                    <h1 class="text-center mb-0">{{ TransactionServices.displayDate(o.eff_date) }}</h1>
                    <p class=" text-center body2-regular description-text mb-0">{{ TransactionServices.displayMonthYear(o.eff_date) }}</p>

                  </div>
                  <div class="col-9 col-md-9">
                    <h5 class="body-medium mb-0">
                      {{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}
                    </h5>
                    <p class="caption hint-text mb-0 text-truncate">{{ TransactionServices.getDetail(o) }}</p>
                    <p class="caption description-text mb-0">
                      {{ $t('global.amount') }}: {{ TransactionServices.convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--
            <div class="card-kkp mt-5" type="button">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <a href="#" @click="NavCtrl.openDcaNavHandle()"><h5 class="card-kkp-title"><img src="@/assets/image/dca.png" width="40" class="mx-auto pe-2"> รายการ DCA <img src="@/assets/image/Next.png" width="40" class="float-end mx-auto pe-2"></h5></a>
                  </div>
                </div>
              </div>
            </div>
            -->

          </div>
        </div>
        <div class="tab-pane fade" id="tabPendingPending">
          <div v-show="flagLoading">
            <div class="content-box4 d-flex">
              <div class="col-12">
                <LoadingComponet :loading="flagLoading"/>
              </div>
            </div>
          </div>
          <div v-show="!flagLoading">
            <div class="content-box4 d-flex" v-if="pendings.length==0">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/NotFound.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
            <div class="card card-pending-list" :type="(o.channel=='KKPAM Online')?'button':''" v-for="(o,index) of pendings" :key="`pendingPendingListResult${index}`" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']"
                 @click="openDetail(o)">
              <div class="card-body">
                <div class="row">
                  <div class="col-3 col-md-3">
                    <!--
                    <h5 class="text-center title">
                      {{ TransactionServices.displayDate(o.eff_date) }}<br>
                      <small>{{ TransactionServices.displayMonthYear(o.eff_date) }}</small>
                    </h5>
                    -->

                    <h1 class="text-center mb-0">{{ TransactionServices.displayDate(o.eff_date) }}</h1>
                    <p class=" text-center body2-regular description-text mb-0">{{ TransactionServices.displayMonthYear(o.eff_date) }}</p>

                  </div>
                  <div class="col-9 col-md-9">
                    <h5 class="body-medium mb-0">
                      {{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}
                    </h5>
                    <p class="caption hint-text mb-0 text-truncate">{{ TransactionServices.getDetail(o) }}</p>
                    <p class="caption description-text mb-0">
                      {{ $t('global.amount') }}: {{ TransactionServices.convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tabPendingCancel">
          <div v-show="flagLoading">
            <div class="content-box4 d-flex">
              <div class="col-12">
                <LoadingComponet :loading="flagLoading"/>
              </div>
            </div>
          </div>
          <div v-show="!flagLoading">
            <div class="content-box4 d-flex" v-if="cancels.length==0">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/NotFound.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
            <div class="card card-pending-list" :type="(o.channel=='KKPAM Online')?'button':''" v-for="(o,index) of cancels" :key="`pendingCancelListResult${index}`" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']"
                 @click="openDetail(o)">
              <div class="card-body">
                <div class="row">
                  <div class="col-3 col-md-3">
                    <!--
                    <h5 class="text-center title">
                      {{ TransactionServices.displayDate(o.eff_date) }}<br>
                      <small>{{ TransactionServices.displayMonthYear(o.eff_date) }}</small>
                    </h5>
                    -->

                    <h1 class="text-center mb-0">{{ TransactionServices.displayDate(o.eff_date) }}</h1>
                    <p class=" text-center body2-regular description-text mb-0">{{ TransactionServices.displayMonthYear(o.eff_date) }}</p>

                  </div>
                  <div class="col-9 col-md-9">
                    <h5 class="body-medium mb-0">
                      {{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}
                    </h5>
                    <p class="caption hint-text mb-0 text-truncate">{{ TransactionServices.getDetail(o) }}</p>
                    <p class="caption description-text mb-0">
                      {{ $t('global.amount') }}: {{ TransactionServices.convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ft">
      <div class="card-kkp p-card">
        <div class="">
        <div class="row">
          <div class="col-12 col-md-12">
            <a href="#" @click="NavCtrl.openDcaNavHandle()"><h5 class="m-ss text-d-title"><img src="@/assets/image/dca1.png" width="40" class="mx-auto pe-2"> รายการ DCA <img src="@/assets/image/Next.png" width="40" class="float-end mx-auto pe-2"></h5></a>
          </div>
        </div>
        </div>
      </div>
    </div>

  </div>
  </div>
  <SlipTransactionModalComponent ref="slipTransactionModal" @close="cancleHandle"/>
  <OrderDetailModalComponent ref="orderDetailModal" @close="cancleHandle"/>
</template>

<script>
import NavCtrl from "@/utils/NavCtrl";
import TransactionServices from "@/services/TransactionServices";
import DateUtils from "@/utils/DateUtils";
import Constants from "@/utils/Constants";
import ValidateUtils from "@/utils/ValidateUtils";
import SlipTransactionModalComponent from "@/components/includes/modals/SlipTransactionModalComponent";
import OrderDetailModalComponent from "@/components/includes/modals/OrderDetailModalComponent";
import LoadingComponet from "@/components/includes/LoadingComponet";

export default {
  name: "PendingListComponent",
  components: {SlipTransactionModalComponent, LoadingComponet, OrderDetailModalComponent},
  data() {
    return {
      TransactionServices: TransactionServices,
      NavCtrl: NavCtrl,
      username: '',
      userType: '',
      types1: [],
      types2: [],
      items: [],
      pendings: [],
      cancels: [],
      flagLoading: false
    }
  },
  methods: {
    async getData(){
      const today = DateUtils.currentDateFormat(Constants.date.requestDateFormat);
      const params = {key_type: 'username', key_data: this.username, fund_no_list: "All", fund_filter: '',
        start_eff_date: today, end_eff_date: '', trans_type_list: 'All', trans_status_list: 'P,C'};
      let resp = await this.$HttpServices.postData("/trans/getTransList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.cancels = result.filter(v=>v.trans_status=='C');
        this.pendings = result.filter(v=>v.trans_status=='P');
        this.items = result;
        this.flagLoading = false;
      }, ()=>{
        this.flagLoading = false;
      });
    },
    async getLookup1(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types1.push(v);
        }
      }, ()=>{this.types1 = Constants.transaction.types});
    },
    async getLookup2(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types2.push(v);
        }
      }, ()=>{this.types2 = Constants.transaction.types});
    },

    openDetail(obj){
      if(obj.channel == 'KKPAM Online'){
        if(obj.trans_status=='P'){
          this.$refs.orderDetailModal.open(obj.trans_no, 'trans_status');
        }
        else{
          this.$refs.slipTransactionModal.open(obj.trans_no, 'trans_status');
        }
      }
    },

    async cancleHandle(){
      await this.getData();
    },

    async openTab(){
      this.flagLoading = true;
      if(this.userType==Constants.userType.user){
        if(this.types1.length == 0){
          await this.getLookup1('trans_type_part1');
        }
        if(this.types2.length == 0){
          await this.getLookup2('trans_type_part2');
        }
        await this.getData();
      }
    }
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;
    this.userType = user.type;
    this.types = [];
    window.PendingListComponent = this;
  }
}
</script>

<style scoped>

</style>