<template>
  <div id="userAccountSidebar" class="rs-account actionsidebar sidenav  drawer-sidenav-6" style="overflow: scroll; height: 100vh;">
    <div class="content">
      <div class="hd">
          <h4 class="mb-0 d-flex align-items-center subtitle-medium">
            <div class="line-left float-start"></div>
            {{ $t('menu.userAccount.title') }}
          </h4>
          <div class="d-flex align-items-center ms-auto">
            <a id="aTagU2" href="javascript:void(0)" @click="NavCtrl.closeAllNavHandle()">
            <img src="@/assets/image/x-round.png" class="">
            </a>
          </div>
      </div>

      <div class="bd">
          <div class="profile" style="background-position: center;background-size: contain;" :style='{ backgroundImage: `url("${user.profilePic}")` }'>
            <a id="aTagU1" href="javascript:void(0)">
                <input type="file" ref="fileInput" class="d-none" accept="image/png, image/jpg, image/svg, image/gif" @change="uploadProfilePic"/>
                <!-- TODO translate -->
                <div class="btn-profile body2-regular" @click="profileClickHandle">เปลี่ยน</div>
            </a>
          </div>
          <h4 class="title-medium color-title text-center m-0">
            {{ user.fullname }}
          </h4>
          <hr>
          <div class="clearfix"></div>

          <button id="BTNU1" type="button" class="head-tag body2-regular" v-for="(o,index) of user.unitholders" :key="`unitholderUserAccountList${index}`">
            <img src="@/assets/image/book.png" width="24" class="mx-auto pe-2">{{ o.unitholder_id }}
          </button>

          <div class="box-status min-425 m-m-t24">
            <div class="row">
                <div class="col-12">
                  <h4 class="subtitle-medium color-title mb-3">{{ $t('menu.userAccount.header') }}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                  <p class="body-regular c-placeholder mb-0 body">{{ $t('menu.userAccount.email') }}</p>
                </div>
                <div class="col-8">
                  <p class="mb-0 body-regular color-title">{{ user.email }}</p>
                </div>
            </div>
            <hr class="mt-3 mb-3">
            <div class="row">
                <div class="col-4">
                  <p class="body-regular c-placeholder body">{{ $t('menu.userAccount.tel') }}</p>
                </div>

                <div class="col-8">
                  <p class="body-regular color-title">{{ user.tel }}</p>
                </div>
            </div>
          </div>
          <button id="BTNU2" type="button" class="btn w-100 btn-sec hvr-float m-m-t24" @click="openKycHandle('')" v-if="editable.profile">
            <img src="@/assets/image/edit1.png" width="30" class="mx-auto pe-2"> {{ $t('menu.userAccount.actionEdit') }}
          </button>
      </div>

      <!-- @rewrite-html
      <h5 class="head-title"><div class="line-left float-start"></div>{{ $t('menu.userAccount.title') }}</h5>
      <a id="aTagU1" href="javascript:void(0)">
        <center>
          <input type="file" ref="fileInput" class="d-none" accept="image/png, image/jpg, image/svg, image/gif" @change="uploadProfilePic"/>
          <img :src="user.profilePic" width="150" class="mx-auto px-3 mt-5" @click="profileClickHandle">
        </center>
      </a>
      <h5 class="text-center mt-2 mb-3">{{ user.fullname }}</h5>
      <hr>
      <a id="aTagU2" href="javascript:void(0)" class="closebtn" @click="NavCtrl.closeAllNavHandle()">
        <img src="@/assets/image/x.svg" width="40" class="mx-auto mt-3">
      </a>
      <div class="clearfix"></div>

      <button id="BTNU1" type="button" class="btn btn-sm head-tag f-15" v-for="(o,index) of user.unitholders" :key="`unitholderUserAccountList${index}`">
        <img src="@/assets/image/book.png" width="24" class="mx-auto pe-2">{{ o.unitholder_id }}
      </button>

      <div class="box-status min-350 mb-3 mt-3">
        <div class="row">
          <div class="col-12">
            <h4 class="head-title mb-4">{{ $t('menu.userAccount.header') }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <p class="text-muted mb-0 f-17">{{ $t('menu.userAccount.email') }}</p>
          </div>
          <div class="col-8">
            <p class="mb-0 f-17">{{ user.email }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-4">
            <p class="text-muted f-17">{{ $t('menu.userAccount.tel') }}</p>
          </div>

          <div class="col-8">
            <p class="f-17">{{ user.tel }}</p>
          </div>
        </div>
      </div>

      <button id="BTNU2" type="button" class="btn w-100 btn-sec hvr-float" @click="openKycHandle('')" v-if="editable.profile">
        <img src="@/assets/image/edit1.png" width="25" class="mx-auto pe-2"> {{ $t('menu.userAccount.actionEdit') }}
      </button>
      -->
    </div>
  </div>
  <AlertComponent ref="alertModal"/>
  <KycAlertComponent ref="kycAlertModal"/>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import NavCtrl from "@/utils/NavCtrl";
import ValidateUtils from "@/utils/ValidateUtils";
import Constants from "@/utils/Constants";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import KycAlertComponent from "@/components/includes/alerts/KycAlertComponent";

export default {
  name: "UserAccountComponent",
  components: {KycAlertComponent, AlertComponent},
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      NavCtrl: NavCtrl,
      username: '',
      user:{
        fullname: '',
        email: '',
        tel: '',
        profilePic: '',
        unitholders: []
      },
      editable: {
        profile: false
      },
      flagLoading: false
    }
  },
  methods: {
    async getSingleData(dataname){
      let params = {username: this.username, data_name: dataname, request_page: 'userAccount'};
      let resp = await this.$HttpServices.postData("/customer/getSingleDataByUsername", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        if(result&&result.length>0){
          if(dataname=='client_fullname'){
            this.user.fullname = result[0].data_value;
          }else if(dataname=='client_email'){
            this.user.email = result[0].data_value;
          }else if(dataname=='client_mobile'){
            this.user.tel = result[0].data_value;
          }
        }
      }, null);
    },
    async getProfilePic(){
      let params = {username: this.username};
      let resp = await this.$HttpServices.postData("/customer/GetProfilePic", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        let avatar = '';
        try{
          avatar = require('@/assets/image/profile.png');
        }catch(e){
          avatar = '';
        }
        if(result[0].picture){
          avatar = 'data:image/png;base64, '+result[0].picture;
        }
        this.user.profilePic = avatar;
      }, ()=>{
        let avatar = '';
        try{
          avatar = require('@/assets/image/avatar.svg');
        }catch(e){
          avatar = '';
        }
        this.user.profilePic = avatar;
      });
    },
    async getUnitHolderList(){
      const params = {username: this.username};
      const resp = await this.$HttpServices.postData("/customer/GetUnitholderList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        const dupArr = [];
        for(const v of result){
          const obj = dupArr.find(s=>s.unitholder_id==v.unitholder_id);
          if(!obj){
            dupArr.push(v);
          }
        }
        this.user.unitholders = dupArr;
      }, null);
    },
    async uploadProfilePic(e){
      const files = e.target.files;
      if(files.length>0){
        const f = files[0];
        const byteArray = await StringUtils.fileToByteArray(f);
        const fBase64 = StringUtils.byteArrayToBase64(byteArray);
        this.user.profilePic = 'data:image/png;base64, '+fBase64;
        let params = {username: this.username, picture: fBase64};
        let resp = await this.$HttpServices.postData("/customer/UploadProfilePic", params);
        ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
          const res = resp.result;
          if(res[0].upload_result == 'Y'){
            this.$refs.alertModal.show([], 'success', null, this.$t('global.successAlertHeader'));
          }
          this.$emit('reload');
        }, null);
      }
    },
    async getCanEditProfileHandle(){
      const params = {username: this.username, data_name: "can_edit_kyc", request_page: "useraccount_page"};
      const resp = await this.$HttpServices.postData("/customer/getSingleDataByUsername", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        if(result.length>0){
          this.editable.profile = StringUtils.getEditable(result[0].data_value);
        }
      }, null);
    },
    profileClickHandle(){
      this.$refs.fileInput.click();
    },
    openKycHandle(){
      this.$refs.kycAlertModal.initialData('');
    },
    async initialData(){
      this.flagLoading = true;
      await this.getCanEditProfileHandle();
      await this.getUnitHolderList();
      await this.getProfilePic();
      await this.getSingleData('client_fullname');
      await this.getSingleData('client_email');
      await this.getSingleData('client_mobile');
      this.flagLoading = false;
    }
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    const userType = user.type;

    if(userType==Constants.userType.user){
      this.username = user.username;
      await this.initialData();
    }
  }
}
</script>

<style scoped>

</style>