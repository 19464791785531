<template>
  <div class="bg-login d-flex flex-column min-vh-100" :style="{ backgroundImage: 'url(' + require('@/assets/image/bg-login.jpg') + ')' }">
    <section class="p-404 d-flex flex-grow-1 justify-content-center align-items-center">
      <div class="box-login pa404 mx-auto align-self-center">
        <div class="box-set2 box-line">
          <div class="wraper-top">
            <div class="float-start">
            </div>
            <div class="float-end">
              <AuthSwitchLangComponent/>
            </div>
          </div>
          <div class="box-wraper pa404 p-l">
            <div class="text-center">
              <img src="@/assets/image/404.png" class="mx-auto w-100 m-s-b24">
              <h1 class="title-medium m-s-b16">KKPAM Online</h1>
              <p class="body2-regular m-s-b24">ตัวช่วยที่ทำให้คุณซื้อขายกองทุนได้รวดเร็ว</p>
            </div>
            <form class="row g-3">
                <div class="col-12 col-sm-12">
                  <router-link :to="basePath"><button type="button" class="btn w-100 btn-main hvr-float mb-sm-0">กลับไปหน้าหลัก</button></router-link>
                </div>
            </form>
          </div>
          <div class="footer-wraper pa404 body2">
            <div class="body2-medium color-title  float-start">
              Contact Center<a class="tel color-title" href="#"> 02 305 9800</a>
            </div>
            <div class="float-end  body2">
              <a class="body2-regular color-hyper-text" href="#">มีปัญหาในการใช้งาน</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer footer-kkp mt-auto py-3 fixed-bottom">
      <div class="container-fluid">
        <div class="float-start">
          <ul>
            <li><a class="body2-regular" :href="manual_link" target="_blank">คู่มือการใช้งาน</a></li>
            <li><a class="body2-regular" :href="privacy_policy_link" target="_blank">นโยบายความเป็นส่วนตัว</a></li>
            <li><a class="body2-regular" href="#" @click.prevent="openTerm">ข้อมูลบริการ KKPAM Online</a></li>
          </ul>
        </div>
        <div class="body2-regular float-end d-none d-lg-block">
          © สงวนลิขสิทธิ์ 2563 บริษัทหลักทรัพย์จัดการกองทุน เกียรตินาคินภัทร จำกัด
        </div>
      </div>
    </footer>
  </div>

  <ConditionModalComponent ref="conditionModal"/>
</template>

<script>
import AuthSwitchLangComponent from "@/components/includes/AuthSwitchLangComponent";
import Constants from "@/utils/Constants";
import ConditionModalComponent from "@/components/includes/modals/ConditionModalComponent";

export default {
  name: "NotFoundPage",
  components: {AuthSwitchLangComponent, ConditionModalComponent},
  data() {
    return {
      username: 'guestlogin_page',
      basePath: Constants.basePath,
      manual_link: Constants.global.manualLink,
      privacy_policy_link: Constants.global.privacyPolicyLink
    }
  },
  methods: {
    async openTerm(){
      await this.$refs.conditionModal.openModal(this.username, 'privacy_policy', false);
    }
  },
  mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    if(user){
      const userType = user.type;
      if(userType==Constants.userType.guest){
        this.username = 'guestlogin_page';
      }
      else{
        this.username = user.username;
      }
    }
  }
}
</script>

<style scoped>

</style>