<template>
    <div :key="component_id" :class="component_setting['class']" :style="component_setting['style']">
        <select :id="component_id" :name="component_id" :multiple="component_setting.multi"
            :required="component_setting.required" :disabled="component_setting.disabled">
            <option v-if="component_setting.placeholder"></option>
            <template v-if="type != 'select2-child'">
                <option v-for="item in component_setting.choice" :key="component_id + '-' + item.id" :data-icon="item.image" :data-text="item.text" :value="item.id">{{ item.text }}</option>
            </template>
        </select>
        <label class="text-muted" v-text="component_setting.comment"></label>
    </div>
</template>

<script>

import StringUtils from "@/utils/StringUtils";

//"select2", "select2-child"
export default {
    name: "Select2Component",
    props: {
        id: {default: ''},
        type: {default: ''},
        setting: {default: ''},
        value: {default: ''}
    },
    data() {
        return {
            list_key: new Date(),
            component_id: null,
            component_setting: {},
            setting_default: {
                default_value: '',
                selected_first: true,
                selected: '',
                searchable: true,
                required: false,
                disabled: false,
                placeholder: "",
                class: "",
                style: "",
                multi: false,
                select2_tags: false,
                choice: [],
                prefix_text: "",
                key_child: "children",
                key_id: "data_map",
                key_text: "data_value",
                key_image: "image",
            },
            template_select2: this.templateDefaultSelect2,
            html_child_select2: ""
        }
    },
    watch: {
        id: function (newVal) {
            if(newVal !== this.component_id) {
                this.destroy(this.component_id);
                this.initComponent();
            }
        },
        type: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.destroy(this.component_id);
                this.initComponent();
            }
        },
        setting: function (newVal) {
            if(JSON.stringify(newVal.choice) !== JSON.stringify(this.component_setting.choice)) {
                this.destroy(this.component_id);
                this.initComponent();
            }
        },
        value: function (newVal) {
            if(newVal !== this.component_setting.selected) {
                this.destroy(this.component_id);
                this.initComponent();
            }
        }
    },
    methods: {
        initComponent() {
            let v_this = this;
            v_this.component_setting = window.$.extend(StringUtils.copyJSON(v_this.setting_default), StringUtils.copyJSON(v_this.setting));
            if (!v_this.id) {
                var id = null;
                do {
                    id = StringUtils.getUUIDV4();
                } while (window.$("#" + id).length > 0);
                v_this.component_id = id;
            } else {
                v_this.component_id = v_this.id;
            }

            if (!v_this.value && v_this.component_setting?.["default_value"]) {
                v_this.component_setting.selected = StringUtils.copyJSON(v_this.component_setting["default_value"]);
                v_this.$emit('selected', v_this.component_setting.selected);
            } else if(v_this.value) {
                v_this.component_setting.selected = v_this.value;
            }

            v_this.fetchBoolean();
            v_this.fetchDataChoice();
            if (v_this.type == "relate-data" || (v_this.type == "select2" && v_this.component_setting.multi)) {
                v_this.setValueArray();
            }
            if (["select2", "select2-child"].includes(v_this.type)) {
                v_this.initSelect2();
            }
        },
        fetchBoolean() {
            let v_this = this;
            let keys = Object.keys(v_this.component_setting);
            keys.forEach(key => {
                let value = v_this.component_setting?.[key];
                if (value === true || value === "true" || value === "yes") {
                    v_this.component_setting[key] = true;
                } else if (value === false || value === "false" || value === "no") {
                    v_this.component_setting[key] = false;
                }
            });
        },
        setValueArray() {
            let v_this = this;
            if (!Array.isArray(v_this.value) && v_this.value && typeof v_this.value == 'string') {
                if (v_this.value.charAt(0) == "[") {
                    v_this.component_setting.selected = JSON.parse(v_this.value);
                } else if (v_this.value.includes(",")) {
                    v_this.component_setting.selected = v_this.value.split(",");
                } else {
                    v_this.component_setting.selected = [];
                }
            } else if (!v_this.value) {
                v_this.component_setting.selected = [];
            }
        },
        fetchDataChoice() {
            let v_this = this;
            let choice = [];
            v_this.component_setting.choice.forEach(element => {
                let id = '';
                if (Object.prototype.hasOwnProperty.call(element, v_this.component_setting.key_id)) {
                    if (element[v_this.component_setting.key_id]) {
                        id = element[v_this.component_setting.key_id];
                    }
                } else {
                    id = element;
                }
                let text = '';
                if (Object.prototype.hasOwnProperty.call(element, v_this.component_setting.key_text)) {
                    if (element[v_this.component_setting.key_text]) {
                        text = element[v_this.component_setting.key_text];
                    }
                } else {
                    text = element;
                }
                let image = '';
                if (element[v_this.component_setting.key_image]) {
                    image = '<img class="w-24" src="' + element[v_this.component_setting.key_image] + '" />'
                }
                choice.push({
                    id: id,
                    text: v_this.component_setting.prefix_text + text,
                    image: image
                })
            });
            v_this.component_setting.choice = StringUtils.copyJSON(choice);
        },
        initSelect2() {
            let v_this = this;
            v_this.$nextTick(function () {
                if (v_this.type == "select2-child") {
                    v_this.childSelect2();
                }
                v_this.$nextTick(function () {
                    if (v_this.type == "select2-child") {
                        v_this.template_select2 = v_this.templateChildSelect2;
                    } else if (v_this.component_setting.select2_theme == "text_image") {
                        v_this.template_select2 = v_this.templateIconSelect2;
                    }

                    var elem_parent = window.$("#" + v_this.component_id).closest(".modal-content")[0];
                    if(!elem_parent) {
                        elem_parent = window.$("#" + v_this.component_id).closest("body")[0];
                    }

                    var $select2 = window.$("#" + v_this.component_id).select2({
                        dropdownParent: window.$(elem_parent),
                        minimumResultsForSearch: !v_this.component_setting.searchable? Infinity: 0,
                        placeholder: v_this.component_setting.placeholder,
                        tags: (v_this.component_setting.multi && v_this.component_setting.select2_tags),
                        templateResult: v_this.template_select2,
                        templateSelection: function (data) {
                            if (data.id === '' && v_this.component_setting.placeholder) {
                                return v_this.component_setting.placeholder;
                            }
                            return data.text;
                        }
                    }).on('change', function () {
                        if (window.$(this).val() || window.$(this).val() === '') {
                            v_this.component_setting.selected = window.$(this).val();
                            v_this.$emit('selected', v_this.component_setting.selected);
                        }
                    });

                    v_this.$nextTick(function () {
                        if (v_this.type == "select2-child") {
                            if (v_this.component_setting.multi) {
                                if (v_this.component_setting.selected) {
                                    $select2.val(v_this.component_setting.selected).trigger('change');
                                } else if(v_this.component_setting.selected_first && v_this.component_setting.choice?.length > 0 && v_this.component_setting.choice?.[0]?.id) {
                                    v_this.component_setting.selected = v_this.component_setting.choice[0].id;
                                    $select2.val(v_this.component_setting.selected).trigger('change');
                                }
                            } else {
                                if (Array.isArray(v_this.component_setting.selected) && v_this.component_setting.selected.length > 0) {
                                    $select2.val(v_this.component_setting.selected[0]).trigger('change');
                                } else if (v_this.component_setting.selected) {
                                    $select2.val(v_this.component_setting.selected).trigger('change');
                                } else if(v_this.component_setting.selected_first && v_this.component_setting.choice?.length > 0 && v_this.component_setting.choice?.[0]?.id) {
                                    v_this.component_setting.selected = v_this.component_setting.choice[0].id;
                                    $select2.val(v_this.component_setting.selected).trigger('change');
                                }
                            }
                        } else if(v_this.component_setting.selected) {
                            $select2.val(v_this.component_setting.selected).trigger('change');
                        } else if(v_this.component_setting.selected_first && v_this.component_setting.choice?.length > 0 && v_this.component_setting.choice?.[0]?.id) {
                            v_this.component_setting.selected = v_this.component_setting.choice[0].id;
                            $select2.val(v_this.component_setting.selected).trigger('change');
                        }
                    });
                });
            });
        },
        childSelect2() {
            var v_this = this;
            var html = '<option disabled selected hidden></option>';
            v_this.component_setting.choice.forEach(value => {
                if (value[v_this.component_setting.key_child] && value[v_this.component_setting.key_child].length > 0) {
                    html += '<option class="parent" value="' + value[v_this.component_setting.key_id] + '">' + value[v_this.component_setting.key_text] + '</option>';
                    html += v_this.recursiveChildSelect2(value, 1);
                } else {
                    html += '<option value="' + value[v_this.component_setting.key_id] + '">' + value[v_this.component_setting.key_text] + '</option>';
                }
            });
            window.$("#" + v_this.component_id).html(html);
        },
        recursiveChildSelect2(data, level) {
            var v_this = this;
            var html_child = '';
            if (level < v_this.component_setting.max_level_child) {
                data[v_this.component_setting.key_child].forEach((value) => {
                    if (value[v_this.component_setting.key_child] && value[v_this.component_setting.key_child].length > 0) {
                        html_child += '<option class="parent child-' + level + '" value="' + value[v_this.component_setting.key_id] + '">' + value[v_this.component_setting.key_text] + '</option>';
                        html_child += v_this.recursiveChildSelect2(value, (level + 1));
                    } else {
                        html_child += '<option class="child-' + level + '" value="' + value[v_this.component_setting.key_id] + '">' + value[v_this.component_setting.key_text] + '</option>';
                    }
                });
            }
            return html_child;
        },
        templateDefaultSelect2(data) {
            return data.text;
        },
        templateIconSelect2(data) {
            var html = window.$("<span />").html(data.text);
            if (typeof window.$(data.element).attr("data-icon") !== "undefined" && window.$(data.element).attr("data-icon")) {
                html = window.$("<span />").html(window.$(data.element).attr("data-icon") + '&nbsp;&nbsp;' + window.$(data.element).attr("data-text"));
            }
            return html;
        },
        templateChildSelect2(data, container) {
            if (typeof window.$(data.element).attr("class") === "string") {
                if (window.$(data.element).attr("class").includes('child-')) {
                    var number_child = window.$(data.element).attr("class").split(
                        '-')[1];
                    number_child = number_child.split(' ')[0];
                    var indent = 15 * number_child;
                    window.$(container).css('text-indent', indent);
                }
                if (window.$(data.element).attr("class").includes('parent')) {
                    window.$(container).css('font-weight', '600');
                }
            }
            window.$(container).addClass("text-truncate");
            return data.text;
        },
        destroy(id) {
            if(id) {
                if (window.$("#" + id).hasClass("select2-hidden-accessible")) {
                    window.$("#" + id).select2().off();
                    window.$("#" + id).select2('destroy');
                }

                window.$("#" + id).find('*').unbind().removeData();
                window.$("#" + id).unbind().removeData();

                this.component_id = null;
                this.component_setting = {};
            }
        },
    },
    mounted() {
        this.initComponent();
    },
    beforeUnmount() {
        this.destroy(this.id)
    },
}
</script>