<template>
  <Teleport to="body">
    <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
    <div class="m-fund-buy modal fade" :id="`buy-fund${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
        <div class="modal-content modal-condition form bg-main">
          <div class="modal-header">
            <h2 class="subtitle-medium d-flex align-items-center">
              <div class="line-left"></div> <span class="head-hidden-m flex-grow-1">{{ $t('market.buy.head') }}</span>
              <a :id="`su${id}`" href="javascript:void(0)" class="kkp-toggle dropdown-toggle btn-toggle me-2" type="button" @click="openUnitHolder">
                <p class="body2-medium float-end mb-0"><img src="@/assets/image/portfolio3.png" width="30" class="mx-auto me-3"><span>{{ curUnitHolder }}</span> <img src="@/assets/image/more.png" class="mx-2"></p>
              </a>
              <a :id="`close${id}`" href="javascript:void(0)" @click="closeConfirm"><img src="@/assets/image/close.png" class="mx-auto mx-2" width="35"></a>
            </h2>
          </div>
          <div class="modal-body p-0">
            <div class="m-ms">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                  <div class="test-q">
                    <div class="content-box6 pb-0 bg-none">
                      <h5 class="subtitle-regular color-title d-none d-sm-block">{{ $t('market.buy.fund') }}</h5>
                      <div class="subbox m-s-b24">
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <h5 class="body-medium mb-0">{{ fundData.fund_code }}</h5>
                            <p class="body2-regular hint-text m-b8">{{ (lang=='TH')?fundData.fund_name_TH:fundData.fund_name_EN }}</p>
                            <div class="tag-desktop col-12">
                              <p class="float-start head-tag m-0">Risk LV. <img :src="getRiskImage(fundData.fund_risk_level)" class="mx-auto"></p>
                              <div class="wrap-free">
                                <p class="float-start head-tag m-0"> {{ fundData.fund_class_type }}</p>
                              </div>
                              <a :href="fundData.fund_factsheet_link" target="_blank" v-if="fundData.fund_factsheet_link != null && fundData.fund_factsheet_link != ''">
                                <p class="float-end tag-link btntitle text-white m-0">Prospectus <img src="@/assets/image/right.svg" class="mx-auto"></p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 class="head-title mt-2 d-none d-lg-block">{{ $t('market.buy.payment') }}</h5>
                      <div class="subbox d-none d-lg-block">
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <div class="head-slider" type="button" @click="openPayment">
                              <img src="@/assets/image/menu10.png" class="mx-auto float-end mt-2">
                              <img :src="bankImage(curBankName)" class="mx-auto float-start me-3">
                              <div class="row m-w-txt-bank">
                                <h5 class="body-medium m-b4 col-12">{{ curBankName }}</h5>
                                <div class="col-12">
                                  <p class="float-start head-tag m-0"><img src="@/assets/image/book.png" class="mx-auto"> {{ curPayment }}</p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7">
                  <div class="test-q">
                    <div class="content-box6 pb-0 bg-none">
                      <h5 class="subtitle-regular color-title">{{ $t('market.buy.unitBuy') }}</h5>
                      <div class="subbox">
                        <div class="input-group">
                          <AutoNumbericComponent :id="`unitBuy-${id}`" class="form-control text-center" @numberic="item.unitBuy = $event" aria-label="Text input with dropdown button" placeholder="0.00" :value="item.unitBuy" :style="[(validate.unitBuy||validate.unitBuyMin)?{border: '2px solid red'}:'']"></AutoNumbericComponent>
                          <button class="btn-modal dropdown-toggle body-regular color-title" type="button" data-bs-toggle="dropdown" aria-expanded="false">THB</button>
                        </div>
                      </div>
                      <p class="body2-regular color-title m-l-t8 mb-0 d-flex" v-if="!validate.unitBuyMin">
                        <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                        <span>{{ $t('market.buy.buyMin') }} {{ TransactionServices.decimalFormat(min) }} THB</span>
                      </p>

                      <p class="va hvr-float label-required d-flex" v-if="validate.unitBuy">
                        <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                        <span>{{ $t('market.buy.validate.unitBuy') }}</span>
                      </p>

                      <p class="va hvr-float label-required d-flex" v-if="validate.unitBuyMin">
                        <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                        <span>{{ $t('market.buy.buyMin') }} {{ TransactionServices.decimalFormat(min) }} THB</span>
                      </p>

                      <h5 class="subtitle-regular m-m-t24 color-title">{{ $t('market.buy.transDate') }}</h5>
                      <div class="subbox">
                       <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                         <li :id="`l1-${id}`" :class="(dcaTab=='no')?'nav-item active':'nav-item'" role="presentation">
                           <button :class="(dcaTab=='no')?'nav-link active':'nav-link'" id="pills-home-tab22" data-bs-toggle="pill" data-bs-target="#pills-home22" type="button" role="tab" aria-controls="pills-home" aria-selected="true" @click="tabDCA('no')">
                            <h5 class="head-title" :style="[(dcaTab=='yes')?{color: '#bdbdd0'}:'']">{{ $t('market.buy.purchaseOneTime') }}</h5>
                          </button>
                        </li>
                        <li :id="`l2-${id}`" :class="(dcaTab=='yes')?'nav-item active':'nav-item'" role="presentation">
                         <button :class="(dcaTab=='yes')?'nav-link active':'nav-link'" id="pills-profile-tab22" data-bs-toggle="pill" data-bs-target="#pills-profile22" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" @click="tabDCA('yes')">
                          <h5 class="head-title" :style="[(dcaTab=='no')?{color: '#bdbdd0'}:'']">{{ $t('market.buy.purchaseCon') }}</h5>
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent22">
                      <div :class="(dcaTab=='no')?'tab-pane fade active show':'tab-pane fade'" id="pills-home22" role="tabpanel" aria-labelledby="pills-home-tab">
                        <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.transDate') }}</p>
                        <div class="input-group mb-2">
                          <input :id="`datePick1-${id}`" type="text" class="form-control text-center" :disabled="!curUnitHolder" placeholder="27/03/2565" v-model="item.dateBuy1"
                          :style="[(validate.dateBuy1||validateFund)?{border: '2px solid red'}:'']" autocomplete="off">
                          <button class="btn-modal" type="button" id="datepicker" @click="openDate('datePick1-'+id)">
                            <img v-if="!curUnitHolder" src="@/assets/image/date-disabled.svg" class="mx-auto">
                            <img v-else src="@/assets/image/date.svg" class="mx-auto">
                          </button>
                        </div>
                        <p class="va hvr-float label-required d-flex" v-if="validate.dateBuy1">
                          <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                          <span>{{ $t('market.buy.validate.dateBuy') }}</span>
                        </p>
                        <p class="va hvr-float label-required d-flex" v-if="validateFund" style="color: red;">
                          <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                          <span>{{ effDateValid }}</span>
                        </p>

                      </div>
                      <div :class="(dcaTab=='yes')?'tab-pane fade active show':'tab-pane fade'" id="pills-profile22" role="tabpanel" aria-labelledby="pills-profile-tab22">
                        <form class="row g-3">
                          <div class="col-12 col-xl-6 col-lg-12 col-sm-12">
                            <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.makeEveryDay') }} </p>
                            <div class="select2-wrap">
                              <Select2Component type="select2" :setting="{
                                  'placeholder': $t('market.buy.selectDay'),
                                  'style': [(validate.day)?{border: '2px solid red'}:''],
                                  'class': 'text-center',
                                  'searchable': false,
                                  'choice': Array.from({length: 25}, (_, i) => i + 1),
                                  'required': true,
                              }" :value="day" @selected="day = $event"></Select2Component>

                              <p class="va hvr-float label-required d-flex" v-if="validate.day">
                                <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                                <span>{{ $t('market.buy.validate.day') }}</span>
                              </p>

                            </div>
                          </div>
                          <div class="col-12 col-xl-6 col-lg-12 col-sm-12 ">
                            <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.startDate') }}</p>
                            <div class="input-group mb-2">
                              <input type="text" class="form-control text-center" :disabled="!curUnitHolder" placeholder="27/03/2565" v-model="item.dateBuy2" :id="`datePick2-${id}`"
                              :style="[(validate.dateBuy2||validateFund)?{border: '2px solid red'}:'']" autocomplete="off">
                              <button class="btn-modal" type="button" id="button-addon2" @click="openDate('datePick2-'+id)">
                                <img v-if="!curUnitHolder" src="@/assets/image/date-disabled.svg" class="mx-auto">
                                <img v-else src="@/assets/image/date.svg" class="mx-auto">
                              </button>
                            </div>
                            <p class="va hvr-float label-required d-flex" v-if="validate.dateBuy2">
                              <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                              <span>{{ $t('market.buy.validate.dateBuy') }}</span>
                            </p>
                            <p class="va hvr-float label-required d-flex" v-if="validateFund" style="color: red;">
                              <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                              <span>{{ effDateValid }}</span>
                            </p>
                          </div>
                          <div class="col-8">
                            <p class="body2-medium color-title m-ss-b8"> {{ $t('market.buy.setEndDate') }}</p>
                          </div>
                          <div class="col-4">
                            <div class="switch float-end">
                              <input type="checkbox" :id="`switch-${id}`" v-model="item.switch">
                              <label :for="`switch-${id}`">Toggle</label>
                            </div>
                          </div>
                          <div class="col-12" v-show="item.switch">
                            <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.endDate') }}</p>
                            <div class="input-group mb-2">
                              <input type="text" class="form-control text-center" :disabled="!curUnitHolder" :placeholder="$t('global.pleaseInput')" :id="`datePick3-${id}`" v-model="item.dateBuy3"
                              :style="[(validate.dateBuy3)?{border: '2px solid red'}:'']" autocomplete="off">
                              <button class="btn-modal" type="button" id="button-addon3" @click="openDate('datePick3-'+id)">
                                <img v-if="!curUnitHolder" src="@/assets/image/date-disabled.svg" class="mx-auto">
                                <img v-else src="@/assets/image/date.svg" class="mx-auto">
                              </button>
                            </div>
                            <p class="va hvr-float label-required d-flex" v-if="validate.dateBuy3">
                              <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                              <span>{{ $t('market.buy.validate.dateBuy') }}</span>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <p class="body2-regular color-title m-ss-b8 mt-2 d-flex">
                    <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                    <span>{{ $t('market.buy.note1a') }} <span class="color-BE1E2D">{{ (lang=='TH')?cutOffTime+' น.':cutOffTime }}</span> {{ $t('market.buy.note1b') }}</span>
                  </p>
                  <p class="body2-regular color-title d-flex">
                    <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                    <span>{{ $t('market.buy.note2') }}</span>
                  </p>

                  <h5 class="subtitle-regular mt-4 d-block d-lg-none">{{ $t('market.buy.payment') }}</h5>
                  <div class="subbox d-block d-lg-none">
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <div class="head-slider" type="button" @click="openPayment">
                          <img src="@/assets/image/menu10.png" class="mt-3 mx-auto float-end">
                          <img :src="bankImage(curBankName)" class="mx-auto float-start me-3">
                          <div class="row m-w-txt-bank">
                            <h5 class="subtitle-regular m-b4 col-12">{{ curBankName }}</h5>
                            <div class="col-12">
                              <p class="float-start me-2 mb-0 head-tag"><img src="@/assets/image/book.png" class="mx-auto"> {{ curPayment }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-3 m-t-sm-24 d-block d-sm-none">
                    <div class="col-12 col-sm-6 d-none d-md-block"></div>
                    <div class="col-12 col-sm-3"></div>
                    <div class="col-12 col-sm-3">
                      <button :id="`next-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="next" :disabled="!ValidDisabled()">{{ $t('global.next') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-none d-sm-block">
        <div class="row g-3">
          <div class="col-12 col-sm-6 d-none d-lg-block"></div>
          <div class="col-12 col-lg-3 col-sm-12 order-sm-1 order-1 d-none d-lg-block"></div>
          <div class="col-12 col-lg-3 col-sm-12 order-sm-2 order-2 m-0">
            <button :id="`next2-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="next" :disabled="!ValidDisabled()">{{ $t('global.next') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade logout" :id="`selectUID-${id}`" ref="selectUID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content modal-logout p-0 bg-white">
      <div class="modal-body p-0">

        <template v-for="(v,k,index) in unitHolders" :key="`uh_${index}`">
          <div class="mx-3 m-l-t8">
            <p class="fund-group extra-font13 hint-text">{{ getTitle(v[0].unitholder_type) }}</p>
            <div class="clearfix"></div>
          </div>
          <div v-for="(o,index) of v" :key="`select_uid_list_${index}`" type="button" @click="selectUnitHolder(o.unitholder_id, o.risk_level)">
            <div :class="['fund-select', (o.unitholder_id==curUnitHolder)?'active':'']">
              <div class="head-slider d-flex">
                <div>
                  <img :src="getImg(o.unitholder_type)" class="mx-auto float-start me-3"/>
                </div>
                <div>
                  <h5 class="subtitle-medium m-0">{{o.unitholder_name}} {{ o.agent_shortname?`(${o.agent_shortname})`:'' }}</h5>
                  <p class="float-start me-2 head-tag body2-regular purple"><img src="@/assets/image/book.png" class="mx-auto"> {{ o.unitholder_id }}</p>
                  <p class="float-start head-tag body2-regular purple">Risk LV. <img :src="getRiskIPQImage(o.risk_level)" class="mx-auto"></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </template>

      </div>
    </div>
  </div>
</div>

<div class="modal fade logout" :id="`selectPayment-${id}`" ref="selectPayment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content modal-logout p-0" style="background: #FFFFFF;">
      <div class="modal-body p-0">
        <div v-for="(p, index) in paymentList" :key="`p_${index}`" type="button" @click="selectBank(p)">
          <div :class="['fund-select', (p.acc_no==curPayment)?'active':'']">
            <div class="head-slider d-flex">
              <div>
                <img :src="bankImageObj(p)" class="mx-auto float-start me-3"/>
              </div>
              <div>
                <h5 class="subtitle-medium m-0">{{ (lang=='TH')?p.acc_bank_name_TH:p.acc_bank_name_EN }}</h5>
                <p class="float-start me-2 head-tag m-0"><img src="@/assets/image/book.png" class="mx-auto"> {{ p.acc_no }}</p>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<BuyFundDetailModalComponent ref="buyFundDetail" @close="buyfundDetailCloseHandle" @clear="clear"/>
<AlertComponent ref="alertModal"/>
<RiskConditionModalComponent ref="riskConditionModal" @confirm="confirm" @back="back"/>
<AlertConfirmComponent ref="alertConfirmModal"/>
</Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import BuyFundDetailModalComponent from "@/components/includes/page/market/buy/modal/BuyFundDetailModalComponent";
import DateUtils from "@/utils/DateUtils";
import moment from 'moment';
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import AutoNumbericComponent from "@/components/includes/AutoNumbericComponent";
import RiskConditionModalComponent from "@/components/includes/modals/RiskConditionModalComponent";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";
import AlertConfirmComponent from "@/components/includes/alerts/AlertConfirmComponent";
import TransactionServices from "@/services/TransactionServices";
import Select2Component from "@/components/includes/Select2Component";

export default {
  name: "BuyFundModalComponent",
  components: {BuyFundDetailModalComponent, AlertComponent, AutoNumbericComponent, RiskConditionModalComponent, LoadingOverlayComponet, AlertConfirmComponent, Select2Component},
  data() {
    return {
      TransactionServices: TransactionServices,
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      modalSelectUID: null,
      modalSelectPayment: null,
      actionPage: 'market_purchaseform_page',
      unitHolders: [],
      tempUH: [],
      oldUnitHolder: '',
      curUnitHolder: '',
      curRisk: '',
      fullname: '',
      fundData: [],
      fund_no: '',
      paymentList: [],
      curBankName: '',
      curPayment: '',
      dcaTab: 'no',
      item: {
        unitBuy: '',
        dateBuy1: '',
        dateBuy2: '',
        dateBuy3: '',
        switch: false,
      },
      validate: {},
      min: 5000,
      payment: [],
      day: '',
      dayShow: '',
      flagLoadingOverlay: false,
      cutOffTime: '',
      validateFund: false,
      checkValidateFund: false,
      checkValidateFundErr: false,
      effDateValid: this.$t('market.buy.effDateValid')
    }
  },

  methods: {
    async open(fund_no = '', obj, unitholder_id = ''){
      this.flagLoadingOverlay = true;
      await this.clear();
      this.item.unitBuy = '';
      this.item.dateBuy1 = '';
      this.item.dateBuy2 = '';
      this.item.dateBuy3 = '';
      this.item.switch = false;
      this.fund_no = fund_no;
      this.fundData = obj;
      this.min = obj.minimum_amt_buy;
      this.cutOffTime = obj.cut_off_time_buy;

      await this.getUnitholderList();
      this.curUnitHolder = unitholder_id;
      if(unitholder_id != ''){
        await this.checkCustTrans(unitholder_id);
      }
      else{
        // get/show unitholder list
        if(this.tempUH.length > 1){
          this.flagLoadingOverlay = false;
          this.modalSelectUID.show();
        }
        else{
          this.curUnitHolder = this.tempUH[0].unitholder_id;
          await this.checkCustTrans(this.curUnitHolder);
        }
      }

      let date1 = new Date();
      let date2 = new Date();
      date2.setDate(date2.getDate() + 1);
      window.$('#datePick1-' + this.id).datepicker('setDate', date1);
      window.$('#datePick2-' + this.id).datepicker('setDate', date2);
    },

    async checkCustTrans(unitholder_id){
      this.flagLoadingOverlay = true;
      let key_type = 'username';
      let key_data = this.username;
      if(unitholder_id != ''){
        key_type = 'unitholder_id';
        key_data = unitholder_id;
      }
      const params = {key_type: key_type, key_data: key_data, fund_no: this.fund_no, request_page: this.actionPage};
      const resp = await this.$HttpServices.postData("/trans/CheckCustTransRightsByFund", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        const obj = result.find(vv=>(vv.rights_to_buy=='Y'));
        if(obj){
          let countCallback = 0;
          let v_this = this;
          this.getFullname(function() {
            if(++countCallback == 2) {
              v_this.modal.show();
              v_this.flagLoadingOverlay = false;
            }
          });
          this.getPaymentInfo(function() {
            if(++countCallback == 2) {
              v_this.modal.show();
              v_this.flagLoadingOverlay = false;
            }
          });
        }
      }, ()=>{
        this.curUnitHolder = this.oldUnitHolder;
        this.flagLoadingOverlay = false;
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    closeConfirm(){
      this.$refs.alertConfirmModal.show([this.$t('market.buy.confirmCloseDesc')], 'warning', ()=>{
        this.close();
      }, this.$t('global.warning'), this.$t('market.buy.confirmCloseBTN'));
    },

    close(){
      this.oldUnitHolder = '';
      this.curUnitHolder = '';
      this.unitHolders = [];
      this.tempUH = [];
      this.paymentList = [];
      this.curPayment = '';
      this.curBankName = '';
      this.item.unitBuy = '';
      this.item.dateBuy1 = '';
      this.item.dateBuy2 = '';
      this.item.dateBuy3 = '';
      this.item.switch = false;
      this.dcaTab = 'no';
      this.selectDay('');
      this.validate = {};
      window.$('#unitBuy-'+this.id).val('');
      this.$refs.riskConditionModal.clear();
      let date1 = new Date();
      let date2 = new Date();
      date2.setDate(date2.getDate() + 1);
      window.$('#datePick1-' + this.id).datepicker('setDate', date1);
      window.$('#datePick2-' + this.id).datepicker('setDate', date2);
      this.validateFund = false;
      this.checkValidateFund = false;
      this.effDateValid = this.$t('market.buy.effDateValid');
      this.modal.hide();
    },

    clear(){
      this.oldUnitHolder = '';
      this.curUnitHolder = '';
      this.unitHolders = [];
      this.tempUH = [];
      this.paymentList = [];
      this.curPayment = '';
      this.curBankName = '';
      this.item.unitBuy = '';
      this.item.dateBuy1 = '';
      this.item.dateBuy2 = '';
      this.item.dateBuy3 = '';
      this.item.switch = false;
      this.dcaTab = 'no';
      this.selectDay('');
      this.validate = {};
      window.$('#unitBuy-'+this.id).val('');
      let date1 = new Date();
      let date2 = new Date();
      date2.setDate(date2.getDate() + 1);
      window.$('#datePick1-' + this.id).datepicker('setDate', date1);
      window.$('#datePick2-' + this.id).datepicker('setDate', date2);
      this.validateFund = false;
      this.checkValidateFund = false;
      this.effDateValid = this.$t('market.buy.effDateValid');
      this.$refs.riskConditionModal.clear();
    },

    async validateFundDatetime(){
      this.checkValidateFund = false;
      this.checkValidateFundErr = false;
      let eff_date = '';
      let tempDate = '';
      let trans_type = "BUY";
      if(this.dcaTab == 'yes'){
        tempDate = this.item.dateBuy2.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        trans_type = "BUY(DCA)";
      }
      else{
        tempDate = this.item.dateBuy1.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
      }

      const params = {username: this.username, fund_no: this.fund_no, trans_type: trans_type, eff_date: eff_date};
      const resp = await this.$HttpServices.postData("/fund/ValidateFundDatetime", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'N'){
            if(this.dcaTab == 'yes'){
              this.item.dateBuy2 = v.eff_date;
            }
            else{
              this.item.dateBuy1 = v.eff_date;
            }
            this.validateFund = true;
            this.checkValidateFund = true;
            this.modal.show();
          }
          else{
            this.checkValidateFund = false;
            this.checkValidateFundErr = false;
          }
        }
      }, ()=>{
        this.checkValidateFundErr = true;
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null, ()=>{
          this.modal.show();
        });
        const headers = resp.header;
        const result = resp.result;
        const focusMessages = MessageUtils.getFocusMessageError(headers);
        if(focusMessages.length>0){
          focusMessages.map(v=>{
            if(v.column == 'eff_date'){
              if(v.msg_desc) this.effDateValid = v.msg_desc;

              for(const v of result){
                if(v.is_valid == 'N'){
                  if(this.dcaTab == 'yes'){
                    this.item.dateBuy2 = v.eff_date;
                  }
                  else{
                    this.item.dateBuy1 = v.eff_date;
                  }
                  this.validateFund = true;
                  this.checkValidateFund = true;
                  this.modal.show();
                }
              }

            }
          });
        }
      });
    },

    async next(){
      this.flagLoadingOverlay = true;
      let value = window.$('#unitBuy-'+this.id).val();
      this.item.unitBuy = value;

      if(!this.checkValidate()){
        this.flagLoadingOverlay = false;
        return;
      }

      await this.validateFundDatetime();
      if(this.checkValidateFund||this.checkValidateFundErr){
        this.flagLoadingOverlay = false;
        return;
      }

      this.modal.hide();
      let params = {};
      let api = '';
      // let eff_date = this.item.dateBuy1;
      let amt = this.item.unitBuy;
      if(typeof amt == "string") {
        amt = amt.replaceAll(',', '');
      }

      let tempDate = this.item.dateBuy1.split('/');
      let eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
      eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);

      if(this.dcaTab == 'yes'){
        tempDate = this.item.dateBuy2.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        // eff_date = this.item.dateBuy2;
        // eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);

        let end_dca_eff_date = "";
        if(this.item.dateBuy3 != "" && this.item.dateBuy3 != undefined){
          tempDate = this.item.dateBuy3.split('/');
          end_dca_eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
          end_dca_eff_date = moment(String(end_dca_eff_date)).format(Constants.date.requestDateFormat);
          // end_dca_eff_date = moment(String(this.item.dateBuy3)).format(Constants.date.requestDateFormat);
        }

        params = {
          fund_no: this.fund_no, trans_type: "BUY", unitholder_id: this.curUnitHolder, req_amt: parseFloat(amt), req_unit: 0, start_dca_eff_date: eff_date, end_dca_eff_date: end_dca_eff_date, frequency: "MTH", dca_order_day: parseInt(this.day), payment: this.payment, fund_no_sw: 0, req_all_unit: 'N'
        };

        api = "/trans/ValidateDCATrans";
      }
      else{
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        params = {
          fund_no: this.fund_no, trans_type: "BUY", unitholder_id: this.curUnitHolder, req_amt: parseFloat(amt), req_unit: 0, eff_date: eff_date, payment: this.payment, fund_no_sw: 0, req_all_unit: 'N'
        };
        api = "/trans/ValidateTrans";
      }

      const resp = await this.$HttpServices.postData(api, params);
      ValidateUtils.validateResponse2(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'Y'){
            // get risk message
            await this.getRisk();
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.modal.show()});
      });
      this.flagLoadingOverlay = false;
    },

    back(){
      this.modal.show();
    },

    buyfundDetailCloseHandle(){
      this.modal.show();
    },

    async confirm(){
      let today = DateUtils.currentDateFormat(Constants.date.datetimeDisplay);
      let tempDate = today.split('/');
      let temp2 = tempDate[2].split(' ');
      let yearTH = parseInt(temp2[0])+543;
      today = tempDate[0] +'/'+ tempDate[1] +'/'+ yearTH + ' ' + temp2[1];
      today = moment(String(today), Constants.date.datetimeDisplay).format(Constants.date.datetimeShortYearDisplay);

      const data = {
        fund_no: this.fund_no,
        fundData: this.fundData,
        paymentList: this.paymentList,
        curBankName: this.curBankName,
        curPayment: this.curPayment,
        payment: this.payment,
        unitHolders: this.unitHolders,
        curUnitHolder: this.curUnitHolder,
        fullname: this.fullname,
        item: this.item,
        dcaTab: this.dcaTab,
        day: this.day,
        today: today
      }

      this.$refs.buyFundDetail.open(data);
    },

    async selectUnitHolder(uid, curRisk){
      this.oldUnitHolder = this.curUnitHolder;
      this.curUnitHolder = uid;
      this.curRisk = curRisk;
      this.modalSelectUID.hide();
      // await this.getPaymentInfo();
      // this.modal.show();
      await this.checkCustTrans(uid);
    },

    tabDCA(toggle){
      if(toggle == 'yes'){
        this.dcaTab = 'yes';
        this.item.dateBuy1 = '';
      }
      else{
        this.dcaTab = 'no';
        this.item.dateBuy2 = '';
        this.item.dateBuy3 = '';
        this.item.switch = false;
        this.selectDay('');
      }
      let date1 = new Date();
      let date2 = new Date();
      date2.setDate(date2.getDate() + 1);
      window.$('#datePick1-' + this.id).datepicker('setDate', date1);
      window.$('#datePick2-' + this.id).datepicker('setDate', date2);
      this.validateFund = false;
      this.checkValidateFund = false;
    },

    checkValidate(){
      this.validate = {};
      let flagRequired = true;

      if(!this.item.unitBuy){
        this.validate.unitBuy = true;
        flagRequired = false;
      }

      let amt = this.item.unitBuy;
      if(typeof amt == "string") {
        amt = amt.replaceAll(',', '');
      }
      if(parseFloat(amt) < parseFloat(this.min)){
        this.validate.unitBuyMin = true;
        flagRequired = false;
      }

      if(this.dcaTab == 'no'){
        if(!this.item.dateBuy1){
          this.validate.dateBuy1 = true;
          flagRequired = false;
        }
      }
      else{
        if(!this.day){
          this.validate.day = true;
          flagRequired = false;
        }

        if(!this.item.dateBuy2){
          this.validate.dateBuy2 = true;
          flagRequired = false;
        }

        if(this.item.switch){
          if(!this.item.dateBuy3){
            this.validate.dateBuy3 = true;
            flagRequired = false;
          }
        }

      }

      return flagRequired;
    },

    selectBank(p){
      this.curPayment = p.acc_no;
      if(this.lang == 'TH'){
        this.curBankName = p.acc_bank_name_TH;
      }
      else{
        this.curBankName = p.acc_bank_name_EN;
      }

      this.payment = [];
      this.payment.push(p);
      this.modalSelectPayment.hide();
    },

    selectDay(day){
      this.day = day;
      if(day != ''){
        this.dayShow = day;
      }
      else{
        this.dayShow = 'เลือกวันที่';
      }
    },

    edit(){
      this.modal.show();
    },

    openDate(ele){
      window.$('#'+ele).datepicker().focus();
    },

    checkDisabled(){
      if(this.checkboxs.length == 0){
        return true;
      }
      else{
        if(this.checkboxs.length != window.$('.styled-checkbox').length){
          return true;
        }
        else{
          return false;
        }
      }
    },

    async getFullname(callback = function() {}){
      const params = {username: this.username, data_name: 'client_fullname', request_page: 'dashboard'};
      const resp = await this.$HttpServices.postData("/customer/getSingleDataByUsername", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        if(result&&result.length>0){
          this.fullname = result[0].data_value;
        }
        callback();
      }, () => {
        callback();
      });
    },

    async getUnitholderList(){
      this.unitHolders = [];
      this.tempUH = [];
      let params = {username: this.username};
      let resp = await this.$HttpServices.postData("/customer/GetUnitholderList", params);
      ValidateUtils.validateResponse(resp, null, async ()=>{
        const result = resp.result;
        let objData = null;
        for(const v of result){
          // const obj = unitHList.find(vv=>(v.unitholder_id==vv.unitholder_id&&vv.rights_to_buy=='Y'));
          // if(obj){
            const key = v.unitholder_type;
            if(objData!=null&&objData[key]){
              const arr = objData[key];
              arr.push(StringUtils.copyJSON(v));
            }else{
              if(objData==null) objData = {};
              const arr = [];
              arr.push(StringUtils.copyJSON(v));
              objData[key] = arr;
            }
            this.tempUH.push(v);
          // }
        }
        this.unitHolders = objData;

      }, null);
    },

    async getPaymentInfo(callback = function() {}){
      this.payment = [];
      this.paymentList = [];
      let i = 0;
      let params = {unitholder_id: this.curUnitHolder, acc_for: "B", payment_type_list: "T"};
      let resp = await this.$HttpServices.postData("/payment/GetInfo", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(i == 0){
            this.curPayment = v.acc_no;
            this.payment = [];
            this.payment.push(v);

            if(this.lang == 'TH'){
              this.curBankName = v.acc_bank_name_TH;
            }
            else{
              this.curBankName = v.acc_bank_name_EN;
            }
          }
          this.paymentList.push(v);
          i++;
        }
        callback();
      }, () => {
        callback();
      });
    },

    async getRisk(){
      const params = {username: this.username, unitholder_id: this.curUnitHolder, risk_awn_type: 'BUY', fund_no: this.fund_no};
      const resp = await this.$HttpServices.postData("/riskawn/GetMessage", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          this.risk_header = v.header;
          this.risk_detail = v.details;
        }
        this.$refs.riskConditionModal.openModal(this.risk_header, this.risk_detail, 'BUY', this.fund_no, this.curUnitHolder);
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    getRiskIPQImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/ipq${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    bankImage(data){
      return StringUtils.bankImage(data, this.lang);
    },

    bankImageObj(p){
      let img = '';
      if(this.lang=='TH'){
        img = StringUtils.bankImage(p.acc_bank_name_TH, this.lang);
      }else{
        img = StringUtils.bankImage(p.acc_bank_name_EN, this.lang);
      }

      return img;
    },

    openUnitHolder(){
      this.modalSelectUID.show();
    },

    openPayment(){
      this.modalSelectPayment.show();
    },

    getImg(type){
      let img = '';
      try{
        if(type=='COA'){
          img = require(`@/assets/image/portfolio4.png`);
        }
        else{
          img = require(`@/assets/image/portfolio3.png`);
        }
      }catch(e){
        img = require(`@/assets/image/portfolio3.png`);
      }
      return img;
    },

    getTitle(type){
      const lang = this.$store.getData(Constants.session.lang);
      let title = '';
      if(type=='COA'){
        if(lang=='TH') title = this.$t('global.accountType2');
        else title = this.$t('global.accountType2');
      }
      else{
        if(lang=='TH') title = this.$t('global.accountType1');
        else title = this.$t('global.accountType1');
      }

      return title;
    },
    ValidDisabled() {
      let flagRequired = true;

      if(!this.item.unitBuy){
        flagRequired = false;
      }

      if(this.dcaTab == 'no'){
        if(!this.item.dateBuy1){
          flagRequired = false;
        }
      }
      else{
        if(!this.day){
          flagRequired = false;
        }

        if(!this.item.dateBuy2){
          flagRequired = false;
        }

        if(this.item.switch){
          if(!this.item.dateBuy3){
            flagRequired = false;
          }
        }

      }

      return flagRequired;
    }
  },

  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.modal);
    this.modalSelectUID = new Modal(this.$refs.selectUID);
    this.modalSelectPayment = new Modal(this.$refs.selectPayment);

    window.$('#datePick1-' + this.id).datepicker({
      format: "dd/mm/yyyy",
      todayHighlight: true,
      autoclose: true,
      language: 'th',
      daysOfWeekDisabled: "0,6",
      startDate: new Date(),
      container: "#buy-fund" + this.id
    }).on("changeDate", (e) => {
      this.item.dateBuy1 = e.format();
    });

    window.$('#datePick2-' + this.id).datepicker({
      format: "dd/mm/yyyy",
      todayHighlight: true,
      autoclose: true,
      language: 'th',
      daysOfWeekDisabled: "0,6",
      startDate: '+1d',
      setDate: '+1d',
      container: "#buy-fund" + this.id
    }).on("changeDate", (e) => {
      this.item.dateBuy2 = e.format();
    });

    window.$('#datePick3-' + this.id).datepicker({
      format: "dd/mm/yyyy",
      todayHighlight: true,
      autoclose: true,
      language: 'th',
      daysOfWeekDisabled: "0,6",
      startDate: new Date(),
      container: "#buy-fund" + this.id
    }).on("changeDate", (e) => {
      this.item.dateBuy3 = e.format();
    });
  },
  beforeUnmount() {
    if(window.$('#datePick1-' + this.id).data('datepicker')) {
      window.$('#datePick1-' + this.id).datepicker("destroy");
    }
    if(window.$('#datePick2-' + this.id).data('datepicker')) {
      window.$('#datePick2-' + this.id).datepicker("destroy");
    }
    if(window.$('#datePick3-' + this.id).data('datepicker')) {
      window.$('#datePick3-' + this.id).datepicker("destroy");
    }
  }
}
</script>

<style scoped>
</style>