<template>
  <a v-if="multiLang" class="caption px-2" :class="lang=='EN'?'active':''" href="javascript:void(0)" @click="changeLangHandle('EN')">EN</a>
  <span v-if="multiLang">/</span>
  <a v-if="multiLang" class="caption px-2" :class="lang=='TH'?'active':''" href="javascript:void(0)" @click="changeLangHandle('TH')">TH</a>
</template>

<script>
import Constants from "@/utils/Constants";

export default {
  name: "AuthSwitchLangComponent",
  data() {
    return {
      multiLang: Constants.mutiLang,
      lang: this.$store.getData(Constants.session.lang)
    }
  },
  methods: {
    async changeLangHandle(lang){
      this.lang = lang;
      this.$store.setData(Constants.session.lang, lang);
      this.$root.$i18n.locale = lang.toLowerCase();
      const resp = await this.$HttpServices.getRestData(`/utils/lang/${lang.toLowerCase()}`);
      this.$root.$i18n.setLocaleMessage(lang.toLowerCase(), resp);
    }
  }
}
</script>

<style scoped>

</style>