<template>
  <div id="sideNavRight" class="rs-menu sidenav actionsidebar">
    <div class="p-sm">
      <h5 class="title-medium">
        <img :src="profilePic" class="mx-auto float-start me-3" width="56">
        <span class="float-start f-name"> {{ fullname }}</span><br/>
        <span id="sideRBTN1" type="button" class="head-tag body2-regular"><img src="@/assets/image/user.png" width="16" class="body2-regular mx-auto float-start me-2 mt-1">
          {{ getUsername() }}
        </span>
      </h5>
      <a href="javascript:void(0)" class="closebtn" @click="NavCtrl.closeAllNavHandle()"><img src="@/assets/image/x.svg" width="40" class="mx-auto mt-3 "></a>
      <div class="clearfix"></div>
      <hr>

      <template v-if="!guestType">
        <button id="sideRBTN2" type="button" class="head-tag body2-regular" v-for="(item, index) of unitholders" :key="`unitholder_menu_list_${index}`">
          <img src="@/assets/image/book.png" width="24" class="mx-auto pe-2">{{ item.unitholder_id }}
        </button>
      </template>

      <template v-if="logs.length>0">
        <p class="c-placeholder m-t-16-b-8 body2-regular">{{ $t('menu.sideRight.history') }}</p>
        <div class="box-status mb-24">
          <!-- @rewrite-html
          <div class="row">
            <div class="col-6">
              <h5 class="text-muted f-13">{{ $t('menu.sideRight.date') }}</h5>
            </div>
            <div class="col-6">
              <h5 class="text-muted f-13">{{ $t('menu.sideRight.device') }}</h5>
            </div>
          </div>
          <div class="row" v-for="(item, index) of logs" :key="`login_log_list_${index}`">
            <div class="col-6">
              <p>{{ item.login_datetime }} <br><span class="text-kkp-plus" v-if="index==0">{{ item.active_status }}</span></p>
            </div>
            <div class="col-6">
              <p>{{ item.device }} <br>{{ item.os }}</p>
            </div>
          </div>
          -->

          <div class="row">
            <template v-for="(item, index) of logs" :key="`login_log_list_${index}`">
              <template v-if="index === 0">
                  <div class="col-6">
                      <h5 class="c-placeholder body2-regular">{{ $t('menu.sideRight.date') }}</h5>
                      <p class="m-0 color-title body2-regular">{{ item.login_datetime }}</p>
                  </div>
                  
                  <div class="col-6">
                      <h5 class="c-placeholder caption">{{ $t('menu.sideRight.device') }}</h5>
                      <p class="m-0 color-title body2-regular">{{ item.os }} </p>
                  </div>
                  <div class="col-12">
                      <!-- TODO translate -->
                      <p><span class="success caption">กำลังใช้งาน</span><span class="c-placeholder caption"> &bull; {{ item.device }}</span></p>
                  </div>
              </template>

              <template v-if="index !== 0">
                  <div class="col-6">
                      <p class="c-placeholder body2-regular">{{ item.login_datetime }} <br><span class="caption c-placeholder">{{ item.device }}</span></p>
                  </div>
                  <div class="col-6">
                      <p class="c-placeholder body2-regular">{{ item.os }} </p>
                  </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="box-menu">
      <ul>
        <li class="hvr-float" v-if="!validateUserTypeHandle() && allowPages.AuthenPage">
          <a href="javascript:void(0)" @click="gotoLoginClickHandler" id="sideRLI1">
            <img src="@/assets/image/logo.svg" width="32" class="mx-auto me-3">{{ $t('menu.sideRight.kkpam') }} <img src="@/assets/image/Next.png" width="32" class="mx-auto float-end">
          </a>
        </li>
        <li class="hvr-float" v-if="validateUserTypeHandle()">
          <a href="javascript:void(0)" @click="NavCtrl.openUserAccountNavHandle($refs.userAccount)" id="sideRLI2">
            <img src="@/assets/image/icon1.png" width="32" class="mx-auto me-3">{{ $t('menu.sideRight.userAccount') }} <img src="@/assets/image/Next.png" width="32" class="mx-auto float-end">
          </a>
        </li>
        <li class="hvr-float" v-if="validateUserTypeHandle()">
          <a href="javascript:void(0)" @click="openPasswordSettingModalHandle" id="sideRLI3">
            <img src="@/assets/image/icon2.png" width="32" class="mx-auto me-3">{{ $t('menu.sideRight.setPassword') }} <img src="@/assets/image/Next.png" width="32" class="mx-auto float-end">
          </a>
        </li>
        <li class="hvr-float" v-if="multiLang">
          <a href="javascript:void(0)" @click="openSwitchLangModalHandle" id="sideRLI4">
            <img src="@/assets/image/icon3.png" width="32" class="mx-auto me-3">{{$t('lang.language')}}: {{lang=='EN'?$t('lang.en'):$t('lang.th')}}<img src="@/assets/image/Next.png" width="32" class="mx-auto float-end">
          </a>
        </li>
        <li class="hvr-float">
          <a href="javascript:void(0)" @click="openLogoutModalHandle" id="sideRLI5">
            <img src="@/assets/image/icon4.png" width="32" class="mx-auto me-3">{{ $t('menu.sideRight.logout') }} <img src="@/assets/image/Next.png" width="32" class="mx-auto float-end">
          </a>
        </li>
      </ul>
    </div>
  </div>
  <LogoutComponent ref="logoutModal" />
  <PasswordSettingComponent ref="passwordSettingModal"/>
  <UserAccountComponent ref="userAccount" @reload="reloadHandle"/>
  <SwitchLangComponent ref="switchLang" @lang="lang=$event"/>
</template>

<script>
import LogoutComponent from "@/components/includes/alerts/LogoutComponent";
import Constants from "@/utils/Constants";
import ValidateUtils from "@/utils/ValidateUtils";
import PasswordSettingComponent from "@/components/includes/modals/PasswordSettingComponent";
import NavCtrl from "@/utils/NavCtrl";
import UserAccountComponent from "@/components/includes/menubar/UserAccountComponent";
import SwitchLangComponent from "@/components/includes/alerts/SwitchLangComponent";

export default {
  name: "SideRightComponent",
  components: {LogoutComponent, PasswordSettingComponent, UserAccountComponent, SwitchLangComponent},
  data() {
    return {
      multiLang: Constants.mutiLang,
      allowPages: Constants.allowPages,
      NavCtrl: NavCtrl,
      defaultImg: require('@/assets/image/profile.png'),
      loginType: '',
      guestType: null,
      username: '',
      fullname: '',
      profilePic: '',
      unitholders: [],
      logs: [],
      lang: ''
    }
  },
  methods: {
    openLogoutModalHandle(){
      this.$refs.logoutModal.show();
    },
    openPasswordSettingModalHandle(){
      this.$refs.passwordSettingModal.openModal();
    },
    openSwitchLangModalHandle(){
      this.$refs.switchLang.show();
    },
    gotoLoginClickHandler(){
      this.$store.removeAllData();
      window.location.href = Constants.basePath + 'auth';
    },
    async reloadHandle(){
      this.$emit('reload');
      await this.getProfilePic();
    },

    async getFullname(){
      let url = "/customer/getSingleDataByUsername";
      let params = {username: this.username, data_name: 'client_fullname', request_page: 'dashboard'};
      if(this.loginType==Constants.userType.guest){
        url = "/customer/getSingleDataByUnitholder";
        params = {unitholder_id: this.username, data_name: 'fullname', request_page: 'guestmenu_page'};
      }
      let resp = await this.$HttpServices.postData(url, params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        if(result&&result.length>0){
          this.fullname = result[0].data_value;
        }
      }, null);
    },
    async getProfilePic(){
      let params = {username: this.username};
      let resp = await this.$HttpServices.postData("/customer/GetProfilePic", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        let avatar = this.defaultImg;
        if(result[0].picture){
          avatar = 'data:image/png;base64, '+result[0].picture;
        }
        this.profilePic = avatar;
      }, ()=>{
        let avatar = '';
        try{
          avatar = require('@/assets/image/profile.png');
        }catch(e){
          avatar = '';
        }
        this.profilePic = avatar;
      });
    },
    async getUnitHolderList(){
      const params = {username: this.username};
      const resp = await this.$HttpServices.postData("/customer/GetUnitholderList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        const dupArr = [];
        for(const v of result){
          const obj = dupArr.find(s=>s.unitholder_id==v.unitholder_id);
          if(!obj){
            dupArr.push(v);
          }
        }
        this.unitholders = dupArr;
      }, null);
    },
    async getLogLogin(){
      const params = {username: this.username};
      const resp = await this.$HttpServices.postData("/log/GetLoginLog", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.logs = result;
      }, null);
    },
    validateUserTypeHandle(){
      return this.loginType==Constants.userType.user;
    },
    getUsername(){
      return this.loginType==Constants.userType.user?this.username:'Guest Mode';
    }
  },
  async mounted() {
    this.lang = this.$store.getData(Constants.session.lang);
    const user = this.$store.getJsonData(Constants.session.user);
    this.loginType = user.type;
    if(this.loginType==Constants.userType.user){
      this.username = user.username;
      await this.getProfilePic();
      await this.getUnitHolderList();
      await this.getLogLogin();
    }else if(this.loginType==Constants.userType.guest){
      this.username = user.unitholder;
      this.unitholders.push({unitholder_id: this.username});
      this.profilePic = this.defaultImg;
      this.guestType = user?.guestType;
    }
    await this.getFullname();
  }
}
</script>

<style scoped>

</style>