import { createI18n } from "vue-i18n";
import store from "@/utils/store";
import Constants from "@/utils/Constants";
import HttpServices from "@/services/HttpServices";

let lang = store.getData(Constants.session.lang);
if(!lang){
    store.setData(Constants.session.lang, "TH");
    lang = "TH";
}
lang = lang.toLowerCase();

export const i18n = createI18n({
    locale: lang,
    fallbackLocale: lang
});

//Default load locale message from lang
async function loadLocales(){
    const resp = await HttpServices.getRestData(`/utils/lang/${lang}`);
    i18n.global.setLocaleMessage(lang, resp);
}

loadLocales();

//Load locale
// export function loadLanguageAsync (lang) {
//     return HttpServices.getRestData(`/utils/lang/${lang}`).then(response=>{
//         let msgs = response;
//         i18n.global.setLocaleMessage(lang, msgs);
//         return lang;
//     });
// }