<template>
  <Teleport to="body">
    <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
    <div class="m-fund-switch modal fade show" :id="`switch-fund${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
        <div class="modal-content modal-condition form bg-main">
          <div class="modal-header">
            <h2 class="subtitle-medium d-flex align-items-center"><div class="line-left"></div><span class="head-hidden-m flex-grow-1">{{ $t('market.switch.head') }}</span>

            <a href="javascript:void(0)" class="kkp-toggle dropdown-toggle btn-toggle me-2" type="button" :id="`dropdownMenuButton1-${id}`" data-bs-toggle="dropdown" aria-expanded="false">
              <p class="body2-medium float-end mb-0"><img src="@/assets/image/portfolio3.png" width="30" class="mx-auto me-3 ">{{ curUnitHolder }} </p>
            </a>
            <a :id="`close-${id}`" href="javascript:void(0)" @click="closeConfirm"><img src="@/assets/image/close.png" class="mx-auto mx-2"></a>

          </h2>
        </div>
        <div class="modal-body p-0">
          <div class="m-ms">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                <div class="test-q">
                  <div class="content-box6 pb-0 bg-none">
                    <h5 class="subtitle-regular color-title d-none d-sm-block">{{ $t('market.switch.fund') }}</h5>
                    <div class="subbox m-s-b24">
                      <div class="row">
                        <div class="col-12 col-sm-12">
                          <h5 class="body-medium mb-0">{{ fundData.fund_code }}</h5>
                          <p class="body2-regular hint-text m-b8">{{ (lang=='TH')?fundData.fund_name_TH:fundData.fund_name_EN }}</p>
                          <div class="tag-desktop col-12">
                            <p class="float-start head-tag m-0">Risk LV. <img :src="getRiskImage(fundData.fund_risk_level)" class="mx-auto"></p>
                            <div class="wrap-free">
                              <p class="float-start head-tag m-0"> {{ fundData.fund_class_type }}</p>
                            </div>
                            <a :href="fundData.fund_factsheet_link" target="_blank" v-if="fundData.fund_factsheet_link != null && fundData.fund_factsheet_link != ''">
                              <p class="float-end tag-link btntitle text-white m-0">Prospectus <img src="@/assets/image/right.svg" class="mx-auto"></p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5 class="subtitle-regular color-title m-l-t24">{{ $t('market.switch.switchFund') }}</h5>
                    <div class="subbox card-kkp p-x-16-y-20">
                      <div class="row" v-show="curSWI == 0">
                        <div class="col-12 col-sm-12">
                          <div class="head-slider" type="button" @click="openSwitchFund">
                            <div class="d-flex">
                              <h5 class="head-title my-auto">{{ $t('market.switch.selectFund') }} </h5>
                              <div class="flex-grow-1">
                                <img src="@/assets/image/menu10.png" class="mx-auto float-end"/>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="row" v-show="curSWI != 0">
                        <div class="col-12 col-sm-12">
                          <div class="head-slider" type="button" @click="openSwitchFund">
                            <h5 class="body-medium mb-0">{{ curSWIData.fund_code }}</h5>
                            <p class="body2-regular hint-text m-b8">{{ (lang=='TH')?curSWIData.fund_name_TH:curSWIData.fund_name_EN }}</p>
                          </div>
                          <div class="tag-desktop col-12">
                            <p class="float-start  head-tag m-0">Risk LV. <img :src="getRiskImage(curSWIData.fund_risk_level)" class="mx-auto"></p>
                            <div class="wrap-free">
                              <p class="float-start head-tag m-0"> {{ curSWIData.fund_class_type }}</p>
                            </div>
                            <a @click="openSwitchFund" href="javascript:void(0)">
                              <p class="float-end tag-link btntitle text-white m-0">More <img src="@/assets/image/more.png" class="mx-auto ms-2"></p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5 class="subtitle-regular color-title m-l-t24">{{ $t('market.switch.detail') }}</h5>
                    <div class="subbox">
                      <div class="row">
                        <div class="row">
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-start">{{ $t('market.switch.fundBalUnit') }}</p>
                          </div>
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-end">{{ TransactionServices.unitFormat(fund_bal_unit) }} Unit</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-start">{{ $t('market.switch.fundBalUnitPending') }}</p>
                          </div>
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-end">{{ TransactionServices.unitFormat(fund_bal_pending_unit) }} Unit</p>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-sm-6">
                              <p class="body2-regular mb-1 float-start">{{ $t('market.switch.fundBalAllUnit') }}</p>
                            </div>
                            <div class="col-6 col-sm-6">
                              <p class="body2-regular mb-1 float-end">{{ TransactionServices.unitFormat(fund_bal_unit+fund_bal_pending_unit) }} Units</p>
                            </div>
                          </div>
                        <div class="row" v-if="fundData.fund_class_type=='SSF' || fundData.fund_class_type=='SSFX' || fundData.fund_class_type=='LTF'">
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-start">{{ $t('market.sell.fundBalUnitTax') }}</p>
                          </div>
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-end">{{ TransactionServices.unitFormat(fund_tax_bal_unit) }} Unit</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-start">{{ $t('market.switch.fundBalAmt') }}*</p>
                          </div>
                          <div class="col-6 col-sm-6">
                            <p class="body2-regular mb-1 float-end">{{ TransactionServices.decimalFormat(fund_bal_amt) }} THB</p>
                          </div>
                        </div>
                      </div>
                      <center v-if="fundData.fund_class_type=='SSF' || fundData.fund_class_type=='SSFX' || fundData.fund_class_type=='LTF'">
                        <button class="btn-informaton" @click="openRemarkHandle">
                          <img src="@/assets/image/Infomation3.png" class="mx-auto me-2"> {{ $t('market.sell.note') }}
                        </button>
                      </center>
                    </div> 
                    <p class="mb-4 mt-1 body2-regular d-flex">
                      <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                      <span>*{{ $t('market.switch.fundBalNote') }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7">
                <div class="test-q">
                  <div class="content-box6 bg-none">
                    <h5 class="subtitle-regular color-title">{{ $t('market.switch.switchUnit') }}</h5>
                    <div class="subbox">
                      <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button :class="(unit=='THB')?'nav-link active':'nav-link'" id="pills-home-tab223" data-bs-toggle="pill" data-bs-target="#pills-home22" type="button" role="tab" aria-controls="pills-home" aria-selected="true" @click="changeUnit('THB')" :disabled="item.switch || (fundData.fund_class_type=='SSF' || fundData.fund_class_type=='SSFX' || fundData.fund_class_type=='LTF' || fundData.fund_class_type=='RMF')">
                            <h5 class="body-medium" :style="[(unit=='Units')?{color: '#bdbdd0'}:'']">THB</h5>
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button :class="(unit=='Units')?'nav-link active':'nav-link'" id="pills-profile-tab223" data-bs-toggle="pill" data-bs-target="#pills-profile22" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" @click="changeUnit('Units')" :disabled="item.switch">
                            <h5 class="body-medium" :style="[(unit=='THB')?{color: '#bdbdd0'}:'']">Unit</h5>
                          </button>
                        </li>
                      </ul>
                      <div class="input-group mb-2">

                        <AutoNumbericComponent :id="`unitSW-${id}`" class="form-control text-center" @numberic="item.unitSW = $event" :value="item.unitSW" aria-label="Text input with dropdown button" :placeholder="(unit=='THB')?'0.00':'0.0000'" :disabled="item.switch" :options="{ decimalPlaces: decimal.toString() }"></AutoNumbericComponent>

                        <button class="btn-modal dropdown-toggle body-regular color-title" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="item.switch">{{ unit }}</button>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <p class="body2-medium color-title mb-0 float-start"><small> {{ $t('market.switch.switchAll') }}</small></p>
                        </div>
                        <div class="col-4">
                          <div class="switch float-end">
                            <input type="checkbox" :id="`switch-${id}`" v-model="item.switch" @change="toggleSwitch" 
                            :style="[(validate.unitSW||validate.unitSWMin||validate.unitSWOverAmt||validate.unitSWOverUnit)?{border: '2px solid red'}:'']"
                            >
                            <label :for="`switch-${id}`">Toggle</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="body2-regular color-title mb-4 m-l-t8 d-flex" v-if="!validate.unitSWMin">
                      <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.switch.minimumText') }} {{ TransactionServices.decimalFormat(minTHB) }} THB {{ $t('market.switch.or') }} {{ TransactionServices.unitFormat(minUnits) }} Units</span>
                    </p>
                    <p class="va hvr-float label-required d-flex" v-if="validate.unitSW">
                      <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.switch.validate.unit') }}</span>
                    </p>
                    <p class="va hvr-float label-required d-flex" v-if="validate.unitSWMin">
                      <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.switch.minimumText') }} {{ TransactionServices.decimalFormat(minTHB) }} THB {{ $t('market.switch.or') }} {{ TransactionServices.unitFormat(minUnits) }} Units</span>
                    </p>

                    <p class="va hvr-float label-required d-flex" v-if="validate.unitSWOverAmt">
                      <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.switch.validate.amountOverAmt') }}</span>
                    </p>
                    <p class="va hvr-float label-required d-flex" v-if="validate.unitSWOverUnit">
                      <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.switch.validate.amountOverUnit') }}</span>
                    </p>

                    <div class="clearfix"></div>

                    <h5 class="subtitle-regular color-title m-l-t24">{{ $t('market.switch.transDate') }}</h5>
                    <div class="subbox">
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                          <div class="input-group mb-2">
                            <input :id="`datePick-${id}`" type="text" class="form-control text-center" :disabled="!curUnitHolder" placeholder="27/03/2565" v-model="item.dateSW"
                            :style="[(validate.dateSW||validateFund)?{border: '2px solid red'}:'']" autocomplete="off">
                            <button class="btn-modal" type="button" id="datepicker" @click="openDate('datePick-'+id)">
                              <img v-if="!curUnitHolder" src="@/assets/image/date-disabled.svg" class="mx-auto">
                              <img v-else src="@/assets/image/date.svg" class="mx-auto">
                            </button>
                          </div>
                          <p class="va hvr-float label-required d-flex" v-if="validate.dateSW">
                            <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                            <span>{{ $t('market.switch.validate.date') }}</span>
                          </p>
                          <p class="va hvr-float label-required d-flex" v-if="validateFund" style="color: red;">
                            <img src="@/assets/image/Information2.png" class="me-2 mt-1" width="16" height="16">
                            <span>{{ effDateValid }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <p class="body2-regular color-title m-ss-b8 mt-2 d-flex">
                      <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.buy.note1a') }} <span class="color-BE1E2D">{{ (lang=='TH')?cutOffTime+' น.':cutOffTime }}</span> {{ $t('market.buy.note1b') }}</span>
                    </p>
                    <p class="body2-regular color-title d-flex">
                      <img src="@/assets/image/Information.png" class="me-2 mt-1" width="16" height="16">
                      <span>{{ $t('market.switch.note2') }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-3 d-block d-sm-none">
                  <div class="col-12 col-sm-6 d-none d-md-block"></div>
                  <div class="col-12 col-sm-3"></div>
                  <div class="col-12 col-sm-3">
                    <button :id="`n1-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="next" :disabled="!ValidDisabled()">{{ $t('global.next') }}</button>
                  </div>
                </div>
          </div>
        </div>
        <div class="modal-footer d-none d-sm-block">
          <div class="container-md">
            <div class="row g-3">
              <div class="col-12 col-sm-6 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-sm-1 order-2 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-sm-2 order-1">
                <button :id="`n-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="next" :disabled="!ValidDisabled()">{{ $t('global.next') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal adds fade logout m-select-fund" :id="`selectSwitchFund-${id}`" ref="selectSWmodal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout p-0">
        <div class="modal-body p-0">

          <ul>
            <li v-for="(o, index) in swiFundList" :key="`_list_${index}`" :class="curSWI==o.fund_no?'active subtitle-medium':'subtitle-medium'" @click="selectSW(o.fund_no, o)" type="button" :id="`fsw-${index}`">
              <h5 class="body-medium mb-0">{{ o.fund_code }}</h5>
              <p class="body2-regular hint-text m-b8">{{ TransactionServices.getDetail(o) }}</p>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>

  <div class="modal fade search" :id="`information-${id}`" ref="remark" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout p-0">
        <div class="modal-body p-4">
          <div class="nano-content information"> 
            <div class="top-logo float-end">
              <a :id="`close3-${id}`" href="javascript:void(0)" class="closebtn" data-bs-dismiss="modal"><img src="@/assets/image/x.svg" width="40" class="mx-auto"></a>
            </div>

            <h5 class="subtitle-medium mb-5"><span class="line-left float-start"></span>หมายเหตุ</h5> 

            <ul>
              <li> กองทุน LTF เงินลงทุนก่อนวันที่ 1 มกราคม 2559 ต้องถือหน่วยลงทุนไม่น้อยกว่า 5 ปีปฏิทิน</li>
              <li> กองทุน LTF  เงินลงทุนตั้งแต่วันที่ 1 มกราคม 2559  ถึง วันที่ 31 ธันวาคม 2562  ต้องถือหน่วยลงทุนไม่น้อยกว่า 7 ปีปฏิทิน</li>
              <li> กองทุน SSF/SSFX ต้องถือหน่วยลงทุนไม่น้อยกว่า 10 ปีนับแต่วันที่ซื้อหน่วยลงทุน</li>
            </ul>

            <div class="clearfix"></div>

            <a href="javascript:void(0)" class="mt-5">
              <button :id="`close2-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="closeRemarkHandle">ปิด</button>
            </a>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal adds fade logout m-select-fund" :id="`selectSwitchMore-${id}`" ref="selectSWMoremodal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content modal-logout p-0">
        <div class="modal-body p-0">

          <ul>
            <li class="subtitle-medium" @click="openSwitchAnotherFund">
              <h5 class="body-medium mb-0 text-center">{{ $t('market.switch.switchAnother') }}</h5>
            </li>
            <li class="subtitle-medium" v-if="curSWIData.fund_factsheet_link != null && curSWIData.fund_factsheet_link != ''">
              <a class="a-c-focus" :href="curSWIData.fund_factsheet_link" target="_blank" @click="selectSWMoremodal.hide()">
                <h5 class="body-medium mb-0 text-center">{{ $t('market.switch.switchProspectus') }}</h5>
              </a>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>

  <SwitchFundDetailModalComponent ref="switchFundDetail" @close="switchfundDetailCloseHandle" @clear="clear"/>
  <AlertComponent ref="alertModal"/>
  <RiskConditionModalComponent ref="riskConditionModal" @confirm="confirm" @back="back"/>
  <AlertConfirmComponent ref="alertConfirmModal"/>
</Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import SwitchFundDetailModalComponent from "@/components/includes/page/market/switch/modal/SwitchFundDetailModalComponent";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import moment from 'moment';
import DateUtils from "@/utils/DateUtils";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import AutoNumbericComponent from "@/components/includes/AutoNumbericComponent";
import RiskConditionModalComponent from "@/components/includes/modals/RiskConditionModalComponent";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";
import TransactionServices from "@/services/TransactionServices";
import AlertConfirmComponent from "@/components/includes/alerts/AlertConfirmComponent";

export default {
  name: "SwitchFundModalComponent",
  components: {SwitchFundDetailModalComponent, AlertComponent, AutoNumbericComponent, RiskConditionModalComponent, LoadingOverlayComponet, AlertConfirmComponent},
  data() {
    return {
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      selectSWmodal: null,
      selectSWMoremodal: null,
      fundData: [],
      fund_no: '',
      curUnitHolder: '',
      swiFundList: {},
      curSWI: 0,
      curSWIData: {},
      fund_bal_unit: 0,
      fund_bal_pending_unit: 0,
      fund_tax_bal_unit: 0,
      fund_bal_amt: 0,
      item: {
        unitSW: 0,
        dateSW: '',
        switch: false
      },
      decimal: 2,
      unit: 'THB',
      validate: {},
      minTHB: 0,
      minUnits: 0,
      flagLoadingOverlay: false,
      cutOffTime: '',
      actionPage: 'market_switchform_page',
      TransactionServices: TransactionServices,
      validateFund: false,
      checkValidateFund: false,
      checkValidateFundErr: false,
      effDateValid: this.$t('market.switch.effDateValid')
    }
  },
  methods: {
    async open(fund_no, obj, curUnitHolder){
      this.flagLoadingOverlay = true;
      await this.clear();
      this.fund_no = fund_no;
      this.fundData = obj;

      if(this.fundData?.fund_class_type =='SSF' || this.fundData?.fund_class_type=='SSFX' || this.fundData?.fund_class_type=='LTF' || this.fundData?.fund_class_type=='RMF') {
        await this.changeUnit('Units');
      }

      this.curUnitHolder = curUnitHolder;
      this.item.unitSW = 0;
      this.minUnits = obj.minimum_unit_swi;
      this.minTHB = obj.minimum_amt_swi;
      this.cutOffTime = obj.cut_off_time_swi;

      // flagLoad
      const params = {key_type: 'unitholder_id', key_data: this.curUnitHolder, fund_no: fund_no, request_page: this.actionPage};
      const resp = await this.$HttpServices.postData("/trans/CheckCustTransRightsByFund", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        if(result[0].rights_to_swi == 'Y'){
          let countCallback = 0;
          let v_this = this;
          this.getSWIFundList(function() {
              if(++countCallback == 2) {
                v_this.modal.show();
                v_this.flagLoadingOverlay = false;
              }
            });
          this.getBalanceByUnitholder(function() {
            if(++countCallback == 2) {
              v_this.modal.show();
              v_this.flagLoadingOverlay = false;
            }
          });
        }
      }, ()=>{
        this.flagLoadingOverlay = false;
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{
          this.$emit('close');
        });
      });
      let date1 = new Date();
      window.$('#datePick-' + this.id).datepicker('setDate', date1);
    },

    closeConfirm(){
      this.$refs.alertConfirmModal.show([this.$t('market.switch.confirmCloseDesc')], 'warning', ()=>{
        this.close();
      }, this.$t('global.warning'), this.$t('market.switch.confirmCloseBTN'));
    },

    async close(){
      this.fundData = [];
      this.fund_no = '';
      this.curUnitHolder = '';
      await this.changeUnit('THB');
      this.item.unitSW = 0;
      this.curSWI = 0;
      this.curSWIData = {};
      this.fund_bal_unit = 0;
      this.fund_bal_pending_unit = 0;
      this.fund_tax_bal_unit = 0;
      this.fund_bal_amt = 0;
      this.minTHB = 0;
      this.minUnits = 0;
      window.$('#unitSW-'+this.id).val('');
      this.validate = {};
      this.$refs.riskConditionModal.clear();
      let date1 = new Date();
      window.$('#datePick-' + this.id).datepicker('setDate', date1);
      this.validateFund = false;
      this.checkValidateFund = false;
      this.effDateValid = this.$t('market.switch.effDateValid');
      this.modal.hide();
    },

    async clear(){
      this.fundData = [];
      this.fund_no = '';
      this.curUnitHolder = '';
      await this.changeUnit('THB');
      this.item.unitSW = 0;
      this.curSWI = 0;
      this.curSWIData = {};
      this.fund_bal_unit = 0;
      this.fund_bal_pending_unit = 0;
      this.fund_tax_bal_unit = 0;
      this.fund_bal_amt = 0;
      this.minTHB = 0;
      this.minUnits = 0;
      this.validate = {};
      window.$('#unitSW-'+this.id).val('');
      let date1 = new Date();
      window.$('#datePick-' + this.id).datepicker('setDate', date1);
      this.validateFund = false;
      this.checkValidateFund = false;
      this.effDateValid = this.$t('market.switch.effDateValid');
      this.$refs.riskConditionModal.clear();
    },

    async validateFundDatetime(){
      this.checkValidateFund = false;
      this.checkValidateFundErr = false;
      let eff_date = '';
      let tempDate = '';
      tempDate = this.item.dateSW.split('/');
      eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
      eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
      const params = {username: this.username, fund_no: this.fund_no, trans_type: "SWO", eff_date: eff_date, fund_no_sw: this.curSWI};
      const resp = await this.$HttpServices.postData("/fund/ValidateFundDatetime", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'N'){
            this.item.dateSW = v.eff_date;
            this.checkValidateFund = true;
            this.validateFund = true;
            this.modal.show();
          }
          else{
            this.checkValidateFund = false;
            this.checkValidateFundErr = false;
          }
        }
      }, ()=>{
        this.checkValidateFundErr = true;
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null, ()=>{
          this.modal.show();
        });
        const headers = resp.header;
        const result = resp.result;
        const focusMessages = MessageUtils.getFocusMessageError(headers);
        if(focusMessages.length>0){
          focusMessages.map(v=>{
            if(v.column == 'eff_date'){
              if(v.msg_desc) this.effDateValid = v.msg_desc;

              for(const v of result){
                if(v.is_valid == 'N'){
                  this.item.dateSW = v.eff_date;
                  this.checkValidateFund = true;
                  this.validateFund = true;
                  this.modal.show();
                }
              }

            }
          });
        }
      });
    },

    async next(){
      this.flagLoadingOverlay = true;
      let value = window.$('#unitSW-'+this.id).val();
      this.item.unitSW = value;

      if(!this.checkValidate()){
        this.flagLoadingOverlay = false;
        return;
      }

      await this.validateFundDatetime();
      if(this.checkValidateFund||this.checkValidateFundErr){
        this.flagLoadingOverlay = false;
        return;
      }

      this.modal.hide();

      let req_amt = 0;
      let req_unit = 0;
      let tempDate = this.item.dateSW.split('/');
      let eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
      let amt = this.item.unitSW;
      if(typeof amt == "string") {
        amt = amt.replaceAll(',', '');
      }
      eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
      if(this.unit == 'THB'){
        req_amt = parseFloat(amt);
      }
      else{
        req_unit = parseFloat(amt);
      }

      let reqAllUnit = 'N';
      if(this.item.switch){
        reqAllUnit = 'Y';
      }

      const params = {
        fund_no: this.fund_no, trans_type: "SWO", unitholder_id: this.curUnitHolder, req_amt: req_amt, req_unit: req_unit, eff_date: eff_date, payment: [], fund_no_sw: this.curSWI, req_all_unit: reqAllUnit
      };
      const resp = await this.$HttpServices.postData("/trans/ValidateTrans", params);
      ValidateUtils.validateResponse2(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'Y'){
            // get risk message
            await this.getRisk();
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.modal.show()});
      });
      this.flagLoadingOverlay = false;
    },

    back(){
      this.modal.show();
    },

    async confirm(){
      // this.modalCond.hide();
      const today = DateUtils.currentDateFormat(Constants.date.datetimeDisplay);
      const data = {
        fund_no: this.fund_no,
        fundData: this.fundData,
        unitHolders: this.unitHolders,
        curUnitHolder: this.curUnitHolder,
        item: this.item,
        unit: this.unit,
        today: today,
        curSWIData: this.curSWIData,
        curSWI: this.curSWI,
      }

      this.$refs.switchFundDetail.open(data);
    },

    async selectSW(fund_no, obj){
      this.selectSWmodal.hide();
      this.modal.show();
      this.curSWIData = obj;
      this.curSWI = fund_no;

      const params = {unitholder_id: this.curUnitHolder, fund_no_list: this.curSWI.toString()};
      const resp = await this.$HttpServices.postData("/fund/GetBalanceByUnitholder", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });

    },

    async toggleSwitch(){
      if(this.item.switch){
        await this.changeUnit('Units');
        this.item.unitSW = '';
        this.item.unitSW = parseFloat(this.fund_bal_unit);
      }
    },

    openDate(ele){
      window.$('#'+ele).datepicker().focus();
    },

    checkValidate(){
      this.validate = {};
      let flagRequired = true;

      if(!this.item.unitSW){
        this.validate.unitSW = true;
        flagRequired = false;
      }

      let amt = this.item.unitSW;
      if(typeof amt == "string") {
        amt = amt.replaceAll(',', '');
      }
      if(!this.item.switch && this.unit == 'THB'){
        if(this.item.unitSW < this.minTHB){
          this.validate.unitSWMin = true;
          flagRequired = false;
        }
        else if(parseFloat(amt) > parseFloat(this.fund_bal_amt)){
          this.validate.unitSWOverAmt = true;
          flagRequired = false;
        }
      }
      else if(!this.item.switch) {
        if(this.item.unitSW < this.minUnits){
          this.validate.unitSWMin = true;
          flagRequired = false;
        }
        else if(parseFloat(amt) > parseFloat(this.fund_bal_unit)){
          this.validate.unitSWOverUnit = true;
          flagRequired = false;
        }
      }

      if(!this.item.dateSW){
        this.validate.dateSW = true;
        flagRequired = false;
      }

      return flagRequired;
    },

    switchfundDetailCloseHandle(){
      this.modal.show();
    },

    checkDisabled(){
      if(this.checkboxs.length == 0){
        return true;
      }
      else{
        if(this.checkboxs.length != window.$('.styled-checkbox').length){
          return true;
        }
        else{
          return false;
        }
      }
    },

    changeUnit(unit){
      if(this.unit != unit){
        if(unit == 'THB'){
          this.decimal = 2;
        }
        else{
          this.decimal = 4;
        }
        this.unit = unit;
        this.item.unitSW = '';
      }
    },

    async getSWIFundList(callback = function() {}){
      const params = {fund_no_swo: this.fund_no, unitholder_id: this.curUnitHolder};
      const resp = await this.$HttpServices.postData("/fund/GetSWIFundList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.swiFundList = result;
        callback();
      }, ()=>{
        callback();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    async getBalanceByUnitholder(callback = function() {}){
      const params = {unitholder_id: this.curUnitHolder, fund_no_list: this.fund_no.toString()};
      const resp = await this.$HttpServices.postData("/fund/GetBalanceByUnitholder", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.fund_bal_unit = result[0].fund_bal_unit;
        this.fund_bal_pending_unit = result[0].fund_bal_pending_unit;
        if(result[0].fund_tax_bal_unit == null){
          this.fund_tax_bal_unit = 0;
        }
        else{
          this.fund_tax_bal_unit = result[0].fund_tax_bal_unit;
        }
        this.fund_bal_amt = result[0].fund_bal_amt;
        callback();
      }, ()=>{
        callback();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    async getRisk(){
      const params = {username: this.username, unitholder_id: this.curUnitHolder, risk_awn_type: 'SWO', fund_no: this.curSWI};
      const resp = await this.$HttpServices.postData("/riskawn/GetMessage", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          this.risk_header = v.header;
          this.risk_detail = v.details;
        }
        this.$refs.riskConditionModal.openModal(this.risk_header, this.risk_detail, 'SWO', this.curSWI, this.curUnitHolder);
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    openSwitchFund(){
      // this.modal.hide();
      if(this.curSWI == 0) {
        this.selectSWmodal.show();
      } else {
        this.selectSWMoremodal.show();
      }
    },

    openSwitchAnotherFund() {
      this.selectSWMoremodal.hide();
      this.selectSWmodal.show();
    },

    openRemarkHandle(){
      this.modalRemark.show();
    },

    closeRemarkHandle(){
      this.modalRemark.hide();
      this.modal.show();
    },
    ValidDisabled(){
      let flagRequired = true;

      if(this.curSWI==0) {
        flagRequired = false;
      }

      if(!this.item.unitSW){
        flagRequired = false;
      }

      if(!this.item.dateSW){
        flagRequired = false;
      }

      return flagRequired;
    }
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.modal);
    this.selectSWmodal = new Modal(this.$refs.selectSWmodal);
    this.selectSWMoremodal = new Modal(this.$refs.selectSWMoremodal)
    this.modalRemark = new Modal(this.$refs.remark);

    window.$('#datePick-' + this.id).datepicker({
      format: "dd/mm/yyyy",
      todayHighlight: true,
      autoclose: true,
      language: 'th',
      daysOfWeekDisabled: "0,6",
      startDate: new Date(),
      container: "#switch-fund" + this.id
    }).on("changeDate", (e) => {
      this.item.dateSW = e.format();
    });
  },
  beforeUnmount() {
    if(window.$('#datePick-' + this.id).data('datepicker')) {
      window.$('#datePick-' + this.id).datepicker("destroy");
    }
  }
}
</script>

<style scoped>

</style>