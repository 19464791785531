<template>
  <div class="modal fade" :id="`condition${id}`" ref="conditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="m-condition modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
      <div class="modal-content modal-terms">
        <div class="modal-body">
          <div class="container">
            <div class="row condition">
              <div class="col-12 col-sm-12">
                <div class="condition-box mb-5">
                  <h2 class="body-medium">
                    {{ terms.header }}
                  </h2>
                  <hr>
                  <ul class="body2-regular description-text">
                    <li v-for="(v,index) in terms.details" :key="`authTerm${index}`" :id="`authTerm${index}-${id}`" v-show="index>=0" v-html="convertMessageHandle(v)"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="container-md">
            <div class="row g-3">
              <div class="col-12 col-sm-6 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-lg-2 order-1">
                <button v-if="btn_allow" type="button" class="btn w-100 btn-main hvr-float" @click="submitHandle">{{ submitButton?submitButton:$t('global.allow') }}</button>
              </div>
              <div v-if="btn_allow" class="col-12 col-lg-3 col-sm-12 order-lg-1 order-2">
                <button type="button" data-bs-dismiss="modal" class="btn w-100 btn-sec hvr-float" @click="cancelHandle" v-show="cancel">{{ $t('global.notAllow') }}</button>
              </div>
              <div v-if="!btn_allow" class="col-12 col-lg-3 col-sm-12 order-lg-2 order-1">
                <button type="button" data-bs-dismiss="modal" class="btn w-100 btn-main hvr-float">{{ $t('global.close') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import StringUtils from "@/utils/StringUtils";

export default {
  name: "ConditionModalComponent",
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      terms: {},
      modal: null,
      btn_allow: true
    }
  },
  props: {cancel:{default: true}, submitButton: {default: ''}},
  methods: {
    /********* Action ***********/
    async openModal(username, termType, btn_allow = true, idcard = ''){
      this.btn_allow = btn_allow;
      const params = {idcard: idcard, username: username, terms_type: termType};
      let resp = await this.$HttpServices.postData("/terms/GetMessage", params);
      if(!resp){
        resp = await this.$HttpServices.postData("/terms/GetMessage", params);
      }
      if(resp.result&&resp.result.length>0){
        this.terms = resp.result[0];
      }
      this.modal.show();
    },
    closeModal(){
      this.modal.hide();
    },
    async submitHandle(){
      this.modal.hide();
      this.$emit('submit');
    },
    cancelHandle() {
      this.$emit('cancel');
    },
    convertMessageHandle(message){
      let re = new RegExp(`\\<a\\>(.+?)\\<\\/a\\>`, 'g');
      message = message.replaceAll(re, '<a href="$1" target="_blank">$1</a>');
      return message;
    }
    /********* Action ***********/
  },
  mounted() {
    this.modal = new Modal(this.$refs.conditionModal);
  }
}
</script>

<style scoped>

</style>