/****
 * common plugin
 */
import { createApp } from 'vue';
import 'bootstrap';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Datepicker from 'vue3-datepicker';

/*****
 * css
 */
import 'vue3-carousel/dist/carousel.css';
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/design-system.css';
import '@/assets/css/component.css';
import '@/assets/css/loading.css';
import '@/assets/css/dev-style.css';

/****
 * app vue
 */
import App from './App.vue'
import router from "@/routes/router";
import store from "@/utils/store";
import HttpServices from "@/services/HttpServices";
import {i18n} from "@/services/i18n";
import NumberOnlyDirective from "@/directives/number-only";
import EngNumberValidateOnlyDirective from "@/directives/eng-num-validate-only";

const app = createApp(App);
app.use(router).use(i18n).mount('#app');

app.component('VDatepicker', Datepicker);
app.component(VueCountdown.name, VueCountdown);
app.directive('numberOnly', NumberOnlyDirective);
app.directive('engNumberValidateOnly', EngNumberValidateOnlyDirective);

app.config.globalProperties.$store = store;
app.config.globalProperties.$HttpServices = HttpServices;

app.config.globalProperties.$filters = {
    decimalFormat(value) {
        if(value==undefined||value==""||value==null){
            return "0";
        }
        return Number(""+value).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
}