import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import store from "@/utils/store";
import DateUtils from "@/utils/DateUtils";
import HttpServices from "@/services/HttpServices";

const ShareData = {
    async getDistrict(){
        return await this.getShareData("/util/GetLookupData", Constants.global.district, {data_name: 'district'});
    },
    async getDistrictAmphur(){
        return await this.getShareData("/util/GetLookupData", Constants.global.districtAmphur, {data_name: 'district_amphur'});
    },
    async getAmphur(){
        return await this.getShareData("/util/GetLookupData", Constants.global.amphur, {data_name: 'amphur'});
    },
    async getAmphurPostcode(){
        return await this.getShareData("/util/GetLookupData", Constants.global.amphurPostcode, {data_name: 'amphur_postcode'});
    },
    async getProvinceAmphur(){
        return await this.getShareData("/util/GetLookupData", Constants.global.provinceAmphur, {data_name: 'province_amphur'});
    },
    async getProvince(){
        return await this.getShareData("/util/GetLookupData", Constants.global.province, {data_name: 'province'});
    },
    async getCountry(){
        return await this.getShareData("/util/GetLookupData", Constants.global.country, {data_name: 'country'});
    },
    async getShareData(url, key, params = {}){
        let data = store.getJsonData(key);
        if(!data || !data?.result || (data?.expired && DateUtils.checkBefore(data.expired, new Date()))) {
            let resp = await HttpServices.postData(url, params);
            data = {
                "result": resp?.result,
                "expired": DateUtils.currentStringFormat(DateUtils.addMinutesFromCurrent(5).toDate(), Constants.date.expireDateFormat)
            }
            store.setJsonData(key, data);
        }
        return StringUtils.copyJSON(data?.result);
    },

    getUidWithPage(context) {
        let page = context.$route.name;
        let data = store.getJsonData(page + '.uid');
        if(data) {
            return data;
        } else {
            return '';
        }
    },
    setUidWithPage(context, uid) {
        let page = context.$route.name;
        if(["DashboardPage", "PortfolioPage", "FormConsentPage"].includes(page)) {
            store.setJsonData(page + '.uid', uid);
        }
        return;
    }
};

export default ShareData;