<template>
  <Teleport to="body">
    <div class="modal fade" ref="modal" :id="`searchModal${id}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="m-search modal-dialog modal-dialog-centered modal-md modal-fullscreen-sm-down">
        <div class="modal-content modal-logout bg-main p-0">
          <div class="modal-body">
            <div class="search">
              <div class="row g-2">
                <div class="col-10 col-sm-10">
                  <input v-model="keyword" type="search" class="form-control search-input icon-search m-0 " :placeholder="$t('dashboard.search.placeholder')" @keyup.enter="search" :id="`search_keyword-${id}`">
                </div>
                <div class="col-2 col-sm-2">
                  <a :id="`b1${id}`" href="javascript:void(0)" class="closebtn float-end" @click="close"><img src="@/assets/image/x.svg" width="40" class="mx-auto pe-2"></a>
                </div>
              </div>

              <ul class="nav nav-tab-filter nav-pen p-0" id="myTab1">
                <li class="nav-item pb-0">
                  <a :id="`b2${id}`" href="#home1" class="nav-link head-tag active" data-bs-toggle="tab">{{ $t('dashboard.search.medium_label') }}</a>
                </li>
                <li class="nav-item pb-0">
                  <a :id="`b3${id}`" href="#profile1" class="nav-link head-tag" data-bs-toggle="tab">{{ $t('dashboard.search.fund') }}</a>
                </li>
                <li class="nav-item pb-0">
                  <a :id="`b4${id}`" href="#messages1" class="nav-link head-tag" data-bs-toggle="tab">{{ $t('dashboard.search.waiting1') }}</a>
                </li>
              </ul>

              <div class="tab-content search-body">
                <div class="tab-pane fade show active" id="home1">

                  <div class="content-box4 row g-2" v-show="flagLoading" style="min-height: 440px;">
                    <div class="col-12"><LoadingComponet :loading="flagLoading"/></div>
                  </div>
                  <div v-show="!flagLoading">
                    <div v-if="fundLength>0 || translist.length>0">
                      <p class="c-placeholder caption m-m-t8 m-ss-b8" v-if="translist.length>0"><small>{{ $t('dashboard.search.waiting2') }}</small></p>
                      <div class="card card-pending-list" v-for="(o, index) of translist" :key="`t_${index}`" :id="`t_${index}-${id}`" @click="viewOrder(o)" :type="(o.noti_category=='KKPAM Online')?'button':''">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 col-md-3">
                              <h1 class="text-center mb-0" :class="o.noti_category=='HOL'?'red':''">{{ displayDate(o.noti_date) }}</h1>
                              <p class=" text-center body2-regular description-text mb-0">{{ displayMonthYear(o.noti_date) }}</p>
                            </div>
                            <div class="col-9 col-md-9">
                              <h5 class="body-medium mb-0" :class="o.noti_category=='HOL'?'red':''">{{ o.noti_header }}</h5>
                              <p class="caption hint-text mb-0">{{ o.noti_detail }}</p>
                              <p class="caption description-text mb-0">{{ o.noti_sub_detail1 }} {{ o.noti_sub_detail2?'* '+o.noti_sub_detail2:'' }}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <template v-for="(v,k,index) in fundlist" :key="`fl_${index}`">
                        <p class="c-placeholder caption m-m-t8 m-ss-b8">{{ v[0].fund_class_name }}</p>
                        <div class="card card-fund" type="button" v-for="(a, index) in v" :key="`f_${index}`" :id="`f_${index}-${id}`">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-9 col-sm-9" @click="openPurchase(a.fund_no, a)">
                                <h5 class="body-medium  m-0 wrap">{{ a.fund_code }}</h5>
                                <p class="body2-regular hint-text mb-2">{{ (lang=='TH')?a.fund_name_TH:a.fund_name_EN }}</p>
                              </div>
                              <div class="col-3 col-sm-3" @click="openPurchase(a.fund_no, a)">
                                <h5 class="body-bold card-kkp-title float-end mb-0">{{ (parseFloat(a.nav_price) > 0)?TransactionServices.unitFormat(a.nav_price):TransactionServices.unitFormat(0) }}</h5>
                                <div class="clearfix"></div>

                                <div v-if="a.nav_change_price > 0">
                                  <h5 class="success body2-regular float-end m-0"><img src="@/assets/image/up.png" class="mx-auto me-1">{{ TransactionServices.unitFormat(a.nav_change_price) }}</h5>
                                </div>
                                <div v-else-if="a.nav_change_price == 0">
                                  <h5 class="body2-regular float-end m-0">{{ TransactionServices.unitFormat(a.nav_change_price) }}</h5>
                                </div>
                                <div v-else>
                                  <h5 class="color-BE1E2D body2-regular float-end m-0"><img src="@/assets/image/down.png" class="mx-auto me-1">{{ TransactionServices.unitFormat((a.nav_change_price*-1)) }}</h5>
                                </div>
                                <div class="clearfix"></div>

                                <h5 class="caption hint-text float-end"><small class="f-13">{{ a.nav_date }}</small></h5>
                                <div class="clearfix"></div>
                              </div>
                              <div class="col-12">
                                <p class="float-start head-tag mb-0 mx-1">Risk LV. <img :src="getRiskImage(a.fund_risk_level)" class="mx-auto"></p>
                                <p class="float-start head-tag mb-0 mx-1">{{ a.fund_class_type }}</p>
                                <a :href="a.fund_factsheet_link" target="_blank" v-if="a.fund_factsheet_link != null && a.fund_factsheet_link != ''">
                                  <p class="float-end head-tag-link">Prospectus <img src="@/assets/image/right.svg" class="mx-auto"></p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>

                    <div v-else>
                      <div class="content-box4 d-flex">
                        <div class="align-self-center text-center mx-auto">
                          <img src="@/assets/image/Not-Found.svg" class="mx-auto">
                          <p class="head-title ">ไม่มีรายการ</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="profile1">
                  <div class="content-box4 row g-2" v-show="flagLoading">
                    <div class="col-12"><LoadingComponet :loading="flagLoading"/></div>
                  </div>
                  <div v-show="!flagLoading">
                    <div v-if="fundLength>0">
                      <template v-for="(v,k,index) in fundlist" :key="`fl2_${index}`">
                        <p class="c-placeholder caption m-m-t8 m-ss-b8">{{ v[0].fund_class_name }}</p>
                        <div class="card card-fund" type="button" v-for="(a, index) in v" :key="`f2_${index}`" :id="`f2_${index}-${id}`">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-9 col-sm-9" @click="openPurchase(a.fund_no, a)">
                                <h5 class="body-medium  m-0 wrap">{{ a.fund_code }}</h5>
                                <p class="body2-regular hint-text mb-2">{{ (lang=='TH')?a.fund_name_TH:a.fund_name_EN }}</p>
                              </div>
                              <div class="col-3 col-sm-3" @click="openPurchase(a.fund_no, a)">
                                <h5 class="body-bold card-kkp-title float-end mb-0">{{ (parseFloat(a.nav_price) > 0)?TransactionServices.unitFormat(a.nav_price):TransactionServices.unitFormat(0) }}</h5>
                                <div class="clearfix"></div>

                                <div v-if="a.nav_change_price > 0">
                                  <h5 class="success body2-regular float-end m-0"><img src="@/assets/image/up.png" class="mx-auto me-1">{{ TransactionServices.unitFormat(a.nav_change_price) }} (+{{ TransactionServices.decimalFormat(a.nav_change_percent) }}%)</h5>
                                </div>
                                <div v-else-if="a.nav_change_price == 0">
                                  <h5 class="body2-regular float-end m-0">{{ TransactionServices.unitFormat(a.nav_change_price) }} ({{ TransactionServices.decimalFormat(a.nav_change_percent) }}%)</h5>
                                </div>
                                <div v-else>
                                  <h5 class="color-BE1E2D body2-regular float-end m-0"><img src="@/assets/image/down.png" class="mx-auto me-1">{{ TransactionServices.unitFormat((a.nav_change_price*-1)) }} ({{ TransactionServices.decimalFormat(a.nav_change_percent) }}%)</h5>
                                </div>
                                <div class="clearfix"></div>

                                <h5 class="caption hint-text float-end"><small class="f-13">{{ a.nav_date }}</small></h5>
                                <div class="clearfix"></div>
                              </div>
                              <div class="col-12">
                                <p class="float-start head-tag mb-0 mx-1">Risk LV. <img :src="getRiskImage(a.fund_risk_level)" class="mx-auto"></p>
                                <p class="float-start head-tag mb-0 mx-1">{{ a.fund_class_type }}</p>
                                <a :href="a.fund_factsheet_link" target="_blank" v-if="a.fund_factsheet_link != null && a.fund_factsheet_link != ''">
                                  <p class="float-end head-tag-link">Prospectus <img src="@/assets/image/right.svg" class="mx-auto"></p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="content-box4 d-flex" v-else>
                      <div class="align-self-center text-center mx-auto">
                        <img src="@/assets/image/Not-Found.svg" class="mx-auto">
                        <p class="head-title ">ไม่มีรายการ</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="tab-pane fade" id="messages1">

                  <div class="content-box4 row g-2" v-show="flagLoading">
                    <div class="col-12"><LoadingComponet :loading="flagLoading"/></div>
                  </div>
                  <div v-show="!flagLoading">
                    <div v-if="translist.length>0">

                      <div class="card card-pending-list" v-for="(o, index) of translist" :key="`t_${index}`" :id="`t2_${index}-${id}`" @click="viewOrder(o)" :type="(o.noti_category=='KKPAM Online')?'button':''">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 col-md-4">
                              <h1 class="text-center mb-0" :class="o.noti_category=='HOL'?'red':''">{{ displayDate(o.noti_date) }}</h1>
                              <p class=" text-center body2-regular description-text mb-0">{{ displayMonthYear(o.noti_date) }}</p>
                            </div>
                            <div class="col-9 col-md-8">
                              <h5 class="body-medium mb-0" :class="o.noti_category=='HOL'?'red':''">{{ o.noti_header }}</h5>
                              <p class="caption hint-text mb-0">{{ o.noti_detail }}</p>
                              <p class="caption description-text mb-0">{{ o.noti_sub_detail1 }} {{ o.noti_sub_detail2?'* '+o.noti_sub_detail2:'' }}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="content-box4 d-flex" v-else>
                      <div class="align-self-center text-center mx-auto">
                        <img src="@/assets/image/Not-Found.svg" class="mx-auto">
                        <p class="head-title ">ไม่มีรายการ</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BuyFundModalComponent ref="buyFund"/>
    <OrderDetailModalComponent ref="orderDetailModal" @close="cancleHandle"/>
    </Teleport>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import Constants from "@/utils/Constants";
import ValidateUtils from "@/utils/ValidateUtils";
// import MessageUtils from "@/utils/MessageUtils";
import DateUtils from "@/utils/DateUtils";
import LoadingComponet from "@/components/includes/LoadingComponet";
import BuyFundModalComponent from "@/components/includes/page/market/buy/modal/BuyFundModalComponent";
import TransactionServices from "@/services/TransactionServices";
import OrderDetailModalComponent from "@/components/includes/modals/OrderDetailModalComponent";

export default {
  name: "SearchComponent",
  components: {LoadingComponet, BuyFundModalComponent, OrderDetailModalComponent},
  data() {
    return {
      TransactionServices: TransactionServices,
      username: '',
      lang: 'TH',
      id: StringUtils.getUUIDV4(),
      fundlist: [],
      translist: [],
      flagLoading: false,
      keyword: '',
      fundLength: 0,
      types1: [],
      types2: []
    }
  },
  methods:{
    async open(){
      this.$emit('submit');
      this.modal.show();
      this.flagLoading = true;
      await this.getTransList('');
      this.flagLoading = false;
    },
    async close(){
      this.$emit('cancel');
      this.modal.hide();
      this.fundlist = [];
      // this.translist = [];
      this.keyword = '';
      await this.getTransList('');
    },

    /****** Common ******/
    opentFactsheet(fund_factsheet_link){
      this.$refs.factsheet.open(fund_factsheet_link, '');
    },

    openPurchase(fund_no, obj){
      this.$refs.buyFund.open(fund_no, obj);
    },

    displayDate(dateStr){
      if(!dateStr){
        return '';
      }
      const tmps = dateStr.split('/');
      if(tmps.length>0){
        return tmps[0];
      }
      return '';
    },
    displayMonthYear(dateStr){
      if(!dateStr){
        return '';
      }
      const tmps = dateStr.split('/');
      if(tmps.length>2){
        let m = parseInt(tmps[1]);
        m = m<10?"0"+m:m;
        return m+"/"+tmps[2];
      }
      return '';
    },
    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },
    /****** Common ******/

    async search(){
      let searchInput = this.keyword;

      this.flagLoading = true;
      await this.getInfo(searchInput);
      await this.getTransList(searchInput);
      this.flagLoading = false;
    },

    async getInfo(keyword){
      let params = {username: this.username, fund_no_list:"All", fund_filter: keyword};
      let resp = await this.$HttpServices.postData("/fund/GetInfo", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        let objData = null;
        let index = 0;
        for(const v of result){
          const key = v.fund_class_id;
          if(objData!=null&&objData[key]){
            const arr = objData[key];
            arr.push(StringUtils.copyJSON(v));
          }else{
            if(objData==null) objData = {};
            const arr = [];
            arr.push(StringUtils.copyJSON(v));
            objData[key] = arr;
            index++;
          }
        }
        this.fundlist = objData;
        this.fundLength = index;
      }, () =>{
        this.fundlist = [];
      });
    },

    async getTransList(keyword){
      const today = DateUtils.currentDateFormat(Constants.date.requestDateFormat);

      if(this.types1.length == 0){
        await this.getLookup1('trans_type_part1');
      }
      if(this.types2.length == 0){
        await this.getLookup2('trans_type_part2');
      }

      let params = {key_type: "username", key_data: this.username, fund_no_list:"All", fund_filter: keyword, start_eff_date: today, end_eff_date: "", trans_type_list: "All", trans_status_list: "P"};
      let resp = await this.$HttpServices.postData("/trans/GetTransList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const trans = resp.result;
        const _temp = [];
        for(const v of trans){
          let detail = v.fund_name_TH;
          if(this.lang=='EN'){
            detail = v.fund_name_EN;
          }

          let tranType1 = this.TransactionServices.getTransactionType(this.types1, v.trans_type);
          let tranType2 = this.TransactionServices.getTransactionType(this.types2, v.trans_type);

          let amount = 0;
          if(v.unit=='Units'){
            amount = v.trans_unit;
          }else if(v.unit=='THB'){
            amount = v.trans_amt;
          }
          amount = (''+amount).replaceAll('-', '');
          const obj = {
            noti_category: v.channel,
            noti_clickable: "Y",
            noti_date: v.eff_date,
            noti_detail: detail,
            noti_detail_status: "Active",
            noti_header: `${tranType1} ${v.fund_code} ${tranType2}`,
            noti_id: '',
            trans_no: v.trans_no,
            noti_order: 0,
            noti_picture_path: "",
            noti_sub_detail1: `จำนวน ${StringUtils.decimalFormat(amount)} ${v.unit}`,
            noti_sub_detail2: v.channel,
            unitholder_id: v.unitholder_id
          };
          _temp.push(obj);
        }

        // sort
        _temp.sort(function(a,b){
          const aDate = DateUtils.toDateFormat(a.noti_date, Constants.date.dateShortYearDisplay);
          const bDate = DateUtils.toDateFormat(b.noti_date, Constants.date.dateShortYearDisplay);
          return (aDate - bDate) || (parseInt(a.trans_no)-parseInt(b.trans_no))
        });
        this.translist = _temp;

      }, ()=>{
        this.translist = [];
      });
    },

    viewOrder(o){
      if(o.noti_category == 'KKPAM Online'){
        this.$refs.orderDetailModal.open(o.trans_no, 'trans_status');
      }
    },

    async cancleHandle(){
      await this.search();
    },

    async getLookup1(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types1.push(v);
        }
      }, ()=>{this.types1 = Constants.transaction.types});
    },

    async getLookup2(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types2.push(v);
        }
      }, ()=>{this.types2 = Constants.transaction.types});
    },

  },
  async mounted() {
    // get username
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.modal);
  }
}
</script>

<style scoped>

</style>