<template>
  <Teleport to="body">
    <div class="m-order modal fade" :id="`order-detail-${id}`" ref="orderDetail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content modal-condition form bg-pedding">

          <div class="modal-body p-2 b-16">
            <div class="row g-0" v-show="flagLoading">
              <LoadingComponet :loading="flagLoading"/>
            </div>
            <div class="row g-0" v-show="!flagLoading">
              <div class="col-12 col-sm-12">
                <div class="content-box6 bg-white pd-16">
                  <h4 class="body2-regular">{{ getTitle(item.trans_type) }}</h4>
                  <div class="subbox mb-1">
                    <h5 class="body-medium mb-0">{{ item.fund_code }}</h5>
                    <p class="caption hint-text  mb-2">{{ item.fund_name_TH }}</p>
                    <p class="float-start me-2 head-tag mb-0">Risk LV. <img :src="getRiskImage(item.fund_risk_level)" class="mx-auto"></p>
                    <p class="float-start mb-2  head-tag mb-0">{{ item.fund_class_type }}</p>
                    <div class="clearfix"></div>
                  </div>

                  <h4 class="body2-regular" v-if="item.fund_code_sw">{{ $t('slip.to') }}</h4>
                  <div class="subbox mb-1" v-if="item.fund_code_sw">
                    <h5 class="body-medium mb-06">{{ item.fund_code_sw }}</h5>
                    <p class="caption hint-text  mb-2">{{ item.fund_name_TH_sw }}</p>
                    <p class="float-start me-2 head-tag mb-0">Risk LV. <img :src="getRiskImage(item.fund_risk_level_sw)" class="mx-auto"></p>
                    <p class="float-start mb-2  head-tag mb-0">{{ item.fund_class_type_sw }}</p>
                    <div class="clearfix"></div>
                  </div>

                  <div class="row mt-4 mb-4">
                    <div class="col-6"><h5 class="body2-regular c-placeholder ">{{ $t('slip.amount') }}</h5></div>
                    <div class="col-6"><h5 class="subtitle-medium color-title float-end text-end">{{ TransactionServices.convertTransAmount(item) }} {{ item.unit }}</h5></div>
                  </div>

                  <h4 class="body2-regular mb-2">{{ $t('slip.detail') }}</h4>
                  <div class="subbox mb-1">
                    <div class="row">
                      <div class="col-6 col-sm-6">
                        <p class="body2-regular c-placeholder mb-1 float-start">{{ $t('slip.trans_date') }}</p>
                      </div>
                      <div class="col-6 col-sm-6">
                        <p class="body-regular color-title mb-1 float-end text-end">{{ item.trans_date }}</p>
                      </div>
                    </div>

                    <div class="row" v-if="transStatus=='transDCA_status'">
                      <div class="col-6 col-sm-6">
                        <p class="body2-regular c-placeholder mb-1 float-start">{{ $t('slip.transDateEnd') }}</p>
                      </div>
                      <div class="col-6 col-sm-6">
                        <p class="body-regular color-title mb-1 float-end text-end">{{ (item.end_dca_eff_date)?item.end_dca_eff_date:$t('slip.notSpecified') }}</p>
                      </div>
                    </div>

                    <div class="row" v-if="transStatus=='transDCA_status'">
                      <div class="col-6 col-sm-6">
                        <p class="body2-regular c-placeholder mb-1 float-start">{{ $t('slip.dcaOrderDay') }}</p>
                      </div>
                      <div class="col-6 col-sm-6">
                        <p class="body-regular color-title mb-1 float-end text-end">{{ item.dca_order_day }} {{ $t('slip.ofMonth') }}</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 col-sm-6">
                        <p class="body2-regular c-placeholder mb-1 float-start">{{ $t('slip.effDate') }}</p>
                      </div>
                      <div class="col-6 col-sm-6">
                        <p class="body-regular color-title mb-1 float-end text-end">{{ (transStatus=="transDCA_status")?item.start_dca_eff_date:item.eff_date }}</p>
                      </div>
                    </div>
                  </div>

                  <h4 v-if="getTitleDesc(item.trans_type)"  class="body2-regular mb-2 mt-3">{{ getTitleDesc(item.trans_type) }}</h4>
                  <div v-if="getTitleDesc(item.trans_type)" class="subbox">
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <div class="head-slider" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                          <img :src="bankImageObj(payment)" class="mx-auto float-start me-3">
                          <h5 class="subtitle-medium mb-0">{{ payment.acc_bank_name_TH }}</h5>
                          <p class="float-start me-2  head-tag"><img src="@/assets/image/book.png" class="mx-auto"> {{ payment.acc_no }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4 mb-4">
                  <div :class="(transStatus=='transDCA_status')?'col-4 col-sm-4':'col-6 col-sm-6'">
                    <center>
                      <a :id="`btn1${id}`" href="javascript:void(0)" @click="close"><img src="@/assets/image/pre.png" class="mx-auto mb-1"></a>
                      <p class="body2-regular">{{ $t('global.back') }}</p>
                    </center>
                  </div>
                  <div class="col-4 col-sm-4" v-if="transStatus=='transDCA_status'">
                    <center>
                      <a :id="`btn2${id}`" href="javascript:void(0)" v-if="item.frequency!='MTH'" :disabled="item.frequency!='MTH'">
                        <img src="@/assets/image/edit3.png" class="mx-auto mb-1">
                      </a>
                      <a :id="`btn3${id}`" href="javascript:void(0)" @click="editHandle(item)" v-else>
                        <img src="@/assets/image/edit3.png" class="mx-auto mb-1">
                      </a>
                      <p class="body2-regular">{{ $t('global.edit') }}</p>
                    </center>
                  </div>
                  <div :class="(transStatus=='transDCA_status')?'col-4 col-sm-4':'col-6 col-sm-6'">
                    <center>
                      <a :id="`btn4${id}`" href="javascript:void(0)" @click="cancleHandle"><img src="@/assets/image/trush.png" class="mx-auto mb-1"></a>
                      <p class="body2-regular color-BE1E2D">{{ $t('global.cancel') }}</p>
                    </center> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-confirm modal fade logout" :id="`popupCanleModal${id}`" ref="popupCanleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-logout">
          <div class="modal-body">
            <div class="text-center">
              <img src="@/assets/image/warning-status.png" class="mx-auto mb-4">
              <h2>{{ (transStatus=='transDCA_status')?$t('slip.cancleDCA'):$t('slip.cancle') }}</h2>
              <p>{{ $t('slip.contunueQuestion') }}</p>

              <div class="clearfix"></div>
              <div class="row mt-4">
                <div class="col-12 col-sm-12">
                  <button :id="`btn5${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="continueHandle">{{ $t('global.continue') }}</button>
                </div>
                <div class="col-12 col-sm-12">
                  <button :id="`btn6${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="hidePopup">{{ $t('global.back') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AlertComponent ref="alertModal"/>
    <EditFundModalComponent ref="editModal"/>
    <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
  </Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import LoadingComponet from "@/components/includes/LoadingComponet";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import TransactionServices from "@/services/TransactionServices";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import EditFundModalComponent from "@/components/includes/page/market/buy/modal/EditFundModalComponent";

export default {
  name: "OrderDetailModalComponent",
  components: {LoadingComponet, AlertComponent, EditFundModalComponent, LoadingOverlayComponet},
  data() {
    return {
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      popupCanleModal: null,
      item: {},
      payment: {},
      types: [],
      status: [],
      flagLoading: false,
      TransactionServices: TransactionServices,
      tranNo: '',
      transStatus: '',
      flagLoadingOverlay: false
    }
  },
  methods: {
    async open(tranNo, transStatus){
      this.tranNo = tranNo;
      this.transStatus = transStatus;
      this.flagLoading = true;
      this.modal.show();
      await this.getData(tranNo, transStatus);
      this.flagLoading = false;
    },

    close(){
      this.modal.hide();
      this.item = {};
      this.types = [];
      this.status = [];
      this.tranNo = '';
      this.transStatus = '';
      this.$emit('cancel');
    },

    saveImageHandle(){
      this.modal.hide();
      this.$emit('cancel');
    },

    shareHandle(){
      this.modal.hide();
      this.item = {};
      this.types = [];
      this.status = [];
      this.tranNo = '';
      this.transStatus = '';
      this.$emit('cancel');
    },

    getTitle(data){
      let text = '';
      if(data == "BUY"){
        text = this.$t('slip.buyTitle');
        if(this.transStatus == "transDCA_status"){
          text = this.$t('slip.buyDCATitle');
        }
      }
      else if(data == "BUY(DCA)"){
        text = this.$t('slip.buyDCATitle');
      }
      else if(data == "SELL"){
        text = this.$t('slip.sellTitle');
      }
      else{
        text = this.$t('slip.swTitle');
      }

      return text;
    },

    getTitleDesc(data){
      let text = '';
      if(data == "BUY"){
        text = this.$t('slip.paymentMethod');
      } else if(data == "BUY(DCA)"){
        text = this.$t('slip.paymentMethod');
      } else if(data == "SELL"){
        text = this.$t('market.sell.payment');
      }

      return text;
    },

    convertDate(date){
      let temp = '';
      let _date = date;
      if(date != undefined){
        if(this.item.trans_status=='C'){
          return _date;
        }

        const tempDate = _date.split('/');
        let date = tempDate[0];
        let mth = tempDate[1];
        let year = tempDate[2];

        if(this.lang == 'TH'){
          year = '25'+year;
          if(mth == '01') mth = 'ม.ค.';
          else if(mth == '02') mth = 'ก.พ.';
          else if(mth == '03') mth = 'มี.ค.';
          else if(mth == '04') mth = 'เม.ย.';
          else if(mth == '05') mth = 'พ.ค.';
          else if(mth == '06') mth = 'มิ.ย.';
          else if(mth == '07') mth = 'ก.ค.';
          else if(mth == '08') mth = 'ส.ค.';
          else if(mth == '09') mth = 'ก.ย.';
          else if(mth == '10') mth = 'ต.ค.';
          else if(mth == '11') mth = 'พ.ย.';
          else if(mth == '12') mth = 'ธ.ค.';
          else mth = '';
        }
        else{
          year = '25'+year;
          year = parseInt(year)-543;
          year = year.toString();
          if(mth == '01') mth = 'Jan.';
          else if(mth == '02') mth = 'Feb.';
          else if(mth == '03') mth = 'Mar.';
          else if(mth == '04') mth = 'Apr.';
          else if(mth == '05') mth = 'May';
          else if(mth == '06') mth = 'Jun.';
          else if(mth == '07') mth = 'Jul.';
          else if(mth == '08') mth = 'Aug.';
          else if(mth == '09') mth = 'Sep.';
          else if(mth == '10') mth = 'Oct.';
          else if(mth == '11') mth = 'Nov.';
          else if(mth == '12') mth = 'Dec.';
          else mth = '';
        }

        temp = date+' '+mth+' '+year;
      }

      return temp;
    },

    editHandle(obj){
      this.modal.hide();
      this.$refs.editModal.open(obj)
    },
    
    cancleHandle(){
      this.modal.hide();
      this.popupCanleModal.show();
    },

    hidePopup(){
      this.popupCanleModal.hide();
      this.modal.show();
    },

    async continueHandle(){
      const params = {username: this.username, trans_no: this.tranNo};
      let api = '';
      if(this.transStatus == 'transDCA_status'){
        api = '/trans/CancelDCATrans';
      }
      else{
        api = '/trans/CancelTrans';
      }
      const resp = await this.$HttpServices.postData(api, params);
      ValidateUtils.validateResponse(resp, null, async ()=>{
        const result = resp.result;
        this.popupCanleModal.hide();
        if(result[0].canceled_result == 'Y'){
          window?.NotificationComponent?.openTab();
          window?.DCAListComponent?.openTab();
          window?.PendingListComponent?.openTab();
          window?.AdvanceNoticeComponent?.reloadData();
          this.$refs.alertModal.show([''], 'success', async ()=>{

            if(this.transStatus == 'transDCA_status'){
              this.flagLoadingOverlay = true;
              const params2 = {username: this.username, trans_no: this.tranNo};
              const resp2 = await this.$HttpServices.postData('/trans/ValidatePendingTransFromDCA', params2);
              ValidateUtils.validateResponse(resp2, null, async ()=>{
                const result2 = resp2.result;
                if(result2[0].has_pending == 'Y'){
                  this.$refs.alertModal.show([this.$t('slip.dca_pending')], 'warning', ()=>{
                    this.open(result2[0].trans_no, 'trans_status')
                  }, this.$t('global.warning'), [], this.$t('global.check'));
                }
                else{
                  this.$emit('close');
                }
                this.flagLoadingOverlay = false;
              }, ()=>{
                this.flagLoadingOverlay = false;
                MessageUtils.displayMessage(resp2, this.$refs.alertModal, null);
              });
            }
            else{
              this.flagLoadingOverlay = true;
              this.flagLoadingOverlay = false;
              this.$emit('close');
            }
          }, this.$t('slip.complete'));
        }
      }, ()=>{
        this.popupCanleModal.hide();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null, ()=>{
          this.modal.show()
        });
      });
    },

    async getLookupTransactionType(){
      const params = {data_name:'trans_type_part1'};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.types = result;
      }, ()=>{this.types = Constants.transaction.types});
    },

    async getLookupTransactionStatus(transStatus){
      const params = {data_name:transStatus};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.status = result;
      }, ()=>{this.types = Constants.transaction.types});
    },

    async getData(transNo, transStatus){
      await this.getLookupTransactionStatus(transStatus);
      let api = "/trans/GetTransInfo";
      if(transStatus == 'transDCA_status'){
        api = "/trans/GetDCATransInfo";
      }
      const params = {username: this.username, trans_no: transNo};
      const resp = await this.$HttpServices.postData(api, params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.item = result[0];
        this.payment = result[0].payment[0];
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null);
      });
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    bankImageObj(p){
      let img = '';
      if(this.lang=='TH'){
        img = StringUtils.bankImage(p.acc_bank_name_TH, this.lang);
      }else{
        img = StringUtils.bankImage(p.acc_bank_name_EN, this.lang);
      }

      return img;
    },

  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.orderDetail);
    this.popupCanleModal = new Modal(this.$refs.popupCanleModal);
  }
}
</script>

<style scoped>

</style>