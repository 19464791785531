<template>
	<div :class="checkCookie()" rel="cookie-notification">
		<div class="cookie-notification">
			<p class="caption mb-2">บริษัทใช้งานคุกกี้ (Cookies) เพื่อมอบประสบการณ์ การใช้งานเว็บไซต์ของบริษัทที่ดีขึ้น รวมถึงใช้งานการทำการตลาดให้ตรงตามความสนใจของคุณมากที่สุด โดยคุณสามารถเลือก</p>

			<button type="button" class="btn btn-cookie-none" @click="rejectCookie">ปฎิเสธคุกกี้</button>
			<button type="button" class="btn btn-main btn-cookie" rel="dismiss-cookie-notification" @click="acceptCookie">ยอมรับคุกกี้</button>

			<p class="caption mt-2">หากคุณปฏิเสธคุกกี้ หรือยังคงใช้งานเว็บไซต์ต่อไป บริษัทจะยังคงเก็บคุกกี้ (Cookies) ที่มีความจำเป็นต่อการใช้งานเว็บไซต์ของท่านเท่านั้น ทั้งนี้ท่านสามารถตั้งค่าการจัดการคุกกี้ได้ตามวิธีตั้งค่าที่ระบุไว้ในแต่ละ เว็บเบราเซอร์ที่ใช้บริการ ท่านสามารถอ่านรายละเอียดเพิ่มเติมเกี่ยวกับการใช้งานคุกกี้ของเราได้ที่  <a target="_blank" href="https://www.kkpfg.com/th/cookies-policy">นโยบายการใช้งานคุกกี้ (Cookies Policy)</a>  และ  <a target="_blank" href="https://www.kkpfg.com/th/dataprotection">ประกาศความเป็นส่วนตัว (Privacy Notice)</a></p>

		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: "CookieComponent",
	methods: {
		rejectCookie(){
			// localStorage.removeItem("KKPAM-Cookie");
			// this.checkCookie();
			// window.$('.cookie-notification-container').addClass('dismissed');
			var currentDate = moment();
			var nextTime = moment(currentDate).add(24, 'h');
			const item = {value: true, expired: moment(nextTime).valueOf()}
			localStorage.setItem("KKPAM-Cookie", JSON.stringify(item));
			this.checkCookie();
			window.$('.cookie-notification-container').addClass('dismissed');
		},

		acceptCookie(){
			var currentDate = moment();
			var nextTime = moment(currentDate).add(24, 'h');
			const item = {value: true, expired: moment(nextTime).valueOf()}
			localStorage.setItem("KKPAM-Cookie", JSON.stringify(item));
			this.checkCookie();
			window.$('.cookie-notification-container').addClass('dismissed');
		},

		checkCookie(){
			let cookie = localStorage.getItem("KKPAM-Cookie");
			let classTxt = '';
			classTxt = 'cookie-notification-container shown';

			// check have a cookie
			if(!cookie){
				return classTxt;
			}

			const item = JSON.parse(cookie);
			const now = new Date();

			// check cookie time is expired
			if(now.getTime() > item.expired){
				localStorage.removeItem("KKPAM-Cookie");
				return classTxt;
			}

			classTxt += ' dismissed';
			return classTxt;
		}
	}
}
</script>

<style scoped>
</style>