<template>
  <router-view />
  <LoaderOverlayComponent :loading="flagLoading"/>
</template>

<script>
import Constants from "@/utils/Constants";
import store from "@/utils/store";
import LoaderOverlayComponent from "@/components/includes/LoaderOverlayComponent";
import axios from "axios";

export default {
  components: {LoaderOverlayComponent},
  data(){
    return {
      counter: 0,
      flagLoading: false
    }
  },
  methods:{
    startLoading(){
      if(this.counter<=0){
        // this.flagLoading = true;
      }
      this.counter++;
    },
    stopLoading(){
      this.counter--;
      if(this.counter<=0){
        this.flagLoading = false;
        this.counter = 0;
      }
    }
  },
  created() {
    axios.interceptors.request.use((config) => {
      this.startLoading();
      return config;
    }, (error) => {
      this.stopLoading();
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      this.stopLoading();
      return response;
    }, (error) => {
      this.stopLoading();
      return Promise.reject(error);
    });
  },
  mounted() {
    let lang = store.getData(Constants.session.lang);
    if(!lang){
      lang = "TH";
      store.setData(Constants.session.lang, lang);
    }
    this.lang = lang;
  }
}
</script>
