<template>
  <div class="loading" :style="styleContainer" v-if="loading">
    <center v-if="!button">
		<img width="80" src="@/assets/image/load2.gif">
		<p class="m-0" :style="[color?{'color':color}:'']">{{ $t('global.pleaseWait') }}</p>
	</center>
  <img  v-else width="40" src="@/assets/image/load.gif" />
  </div>
</template>

<script>
export default {
  name: "LoadingComponet",
  props: {loading: {default: false}, stroke: {default: null}, color: {default: null}, button: {default: false}, styleLoding: {default: {}}},
  data() {
    return {
      styleContainer: {}
    }
  },
  async mounted() {
    if(!this.button && this.styleLoding == {}) {
      this.styleContainer = {'min-height': '176px'};
    } else if(this.styleLoding != {}) {
      this.styleContainer = this.styleLoding;
    }
  }
}
</script>

<style scoped>

</style>