<template>
  <div id="dcaListSidebar" class="sidenav drawer-sidenav-6 actionsidebar">
    <div class="m-dca content">
    <div class="hd">
        <h4 class="mb-0 d-flex align-items-center subtitle-medium">
          <div class="line-left float-start"></div>
          {{ $t('dca.header') }}
        </h4>
        <div class="d-flex align-items-center ms-auto">
          <a @click="NavCtrl.closeAllNavHandle()" href="javascript:void(0)">
            <img src="@/assets/image/x-round.png">
          </a>
        </div>
    </div>

    <div class="bd">
    <!--
    <div class="top-logo">
      <a id="bx" href="javascript:void(0)" class="closebtn" @click="NavCtrl.closeAllNavHandle()"><img src="@/assets/image/x.svg" width="40" class="mx-auto pe-2"></a>
    </div>
    <div class="pe-4 px-4">
      <h5 class="head-title">{{ $t('dca.header') }}</h5>
    -->

      <ul class="nav nav-tab-filter p-0" id="myTab4">
        <li class="nav-item">
          <a id="b1" href="#tabDcaAll" class="nav-link head-tag active" data-bs-toggle="tab">{{ $t('dca.all') }}</a>
        </li>
        <li class="nav-item">
          <a id="b2" href="#tabDcaPending" class="nav-link head-tag" data-bs-toggle="tab">{{ $t('dca.issue') }}</a>
        </li>
        <li class="nav-item">
          <a id="b3" href="#tabDcaCancel" class="nav-link head-tag" data-bs-toggle="tab">{{ $t('dca.cancel') }}</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade show active" id="tabDcaAll">
          <div v-show="flagLoading">
            <div class="content-box4 d-flex">
              <div class="col-12">
                <LoadingComponet :loading="flagLoading"/>
              </div>
            </div>
          </div>
          <div v-show="!flagLoading">
            <div class="content-box4 d-flex" v-if="items.length==0">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/NotFound.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
            <div class="card card-pending-list" :type="(o.channel=='KKPAM Online')?'button':''" v-for="(o,index) of items" :key="`dcaListResult${index}`" :id="`dcaListResult${index}`"
                 @click="openDetail(o)" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']">
              <div class="card-body">
                <div class="row">
                  <div class="col-3 col-md-3">
                    <!--
                    <h5 class="text-center title">
                      {{ o.dca_card_header }}<br>
                      <small>{{ o.dca_card_details }}</small>
                    </h5>
                    -->

                    <h1 class="text-center mb-0">{{ o.dca_card_header }}</h1>
                    <p class=" text-center body2-regular description-text mb-0">{{ o.dca_card_details }}</p>

                  </div>
                  <div class="col-9 col-md-8">
                    <h5 class="body-medium mb-0">
                      {{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}
                    </h5>
                    <p class="caption hint-text mb-0 text-truncate">{{ TransactionServices.getDetail(o) }}</p>
                    <p class="caption description-text mb-0">
                      {{ $t('global.amount') }}: {{ TransactionServices.convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tabDcaPending">
          <div v-show="flagLoading">
            <div class="content-box4 d-flex">
              <div class="col-12">
                <LoadingComponet :loading="flagLoading"/>
              </div>
            </div>
          </div>
          <div v-show="!flagLoading">
            <div class="content-box4 d-flex" v-if="pendings.length==0">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/NotFound.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
            <div class="card card-pending-list" :type="(o.channel=='KKPAM Online')?'button':''" v-for="(o,index) of pendings" :key="`dcaPendingListResult${index}`" :id="`dcaPendingListResult${index}`"
                 @click="openDetail(o)" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']">
              <div class="card-body">
                <div class="row">
                  <div class="col-3 col-md-3">
                    <!--
                    <h5 class="text-center title">
                      {{ o.dca_card_header }}<br>
                      <small>{{ o.dca_card_details }}</small>
                    </h5>
                    -->

                    <h1 class="text-center mb-0">{{ o.dca_card_header }}</h1>
                    <p class=" text-center body2-regular description-text mb-0">{{ o.dca_card_details }}</p>

                  </div>
                  <div class="col-9 col-md-8">
                    <h5 class="body-medium mb-0">
                      {{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}
                    </h5>
                    <p class="caption hint-text mb-0 text-truncate">{{ TransactionServices.getDetail(o) }}</p>
                    <p class="caption description-text mb-0">
                      {{ $t('global.amount') }}: {{ TransactionServices.convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tabDcaCancel">
          <div v-show="flagLoading">
            <div class="content-box4 d-flex">
              <div class="col-12">
                <LoadingComponet :loading="flagLoading"/>
              </div>
            </div>
          </div>
          <div v-show="!flagLoading">
            <div class="content-box4 d-flex" v-if="cancels.length==0">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/NotFound.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
            <div class="card card-pending-list" :type="(o.channel=='KKPAM Online')?'button':''" v-for="(o,index) of cancels" :key="`dcaCancelListResult${index}`" :id="`dcaCancelListResult${index}`"
                 @click="openDetail(o)" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']">
              <div class="card-body">
                <div class="row">
                  <div class="col-3 col-md-3">
                    <!--
                    <h5 class="text-center title">
                      {{ o.dca_card_header }}<br>
                      <small>{{ o.dca_card_details }}</small>
                    </h5>
                    -->

                    <h1 class="text-center mb-0">{{ o.dca_card_header }}</h1>
                    <p class=" text-center body2-regular description-text mb-0">{{ o.dca_card_details }}</p>

                  </div>
                  <div class="col-9 col-md-8">
                    <h5 class="body-medium mb-0">
                      {{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}
                    </h5>
                    <p class="caption hint-text mb-0 text-truncate">{{ TransactionServices.getDetail(o) }}</p>
                    <p class="caption description-text mb-0">
                      {{ $t('global.amount') }}: {{ TransactionServices.convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--</div>-->
    </div>
    </div>
  </div>
  <SlipTransactionModalComponent ref="slipTransactionModal" @close="cancleHandle"/>
  <OrderDetailModalComponent ref="orderDetailModal" @close="cancleHandle"/>
</template>

<script>
import NavCtrl from "@/utils/NavCtrl";
import Constants from "@/utils/Constants";
import ValidateUtils from "@/utils/ValidateUtils";
import TransactionServices from "@/services/TransactionServices";
import SlipTransactionModalComponent from "@/components/includes/modals/SlipTransactionModalComponent";
import OrderDetailModalComponent from "@/components/includes/modals/OrderDetailModalComponent";
import LoadingComponet from "@/components/includes/LoadingComponet";

export default {
  name: "DCAListComponent",
  components: {OrderDetailModalComponent, LoadingComponet, SlipTransactionModalComponent},
  data() {
    return {
      TransactionServices: TransactionServices,
      NavCtrl: NavCtrl,
      username: '',
      userType: '',
      types1: [],
      types2: [],
      items: [],
      pendings: [],
      cancels: [],
      flagLoading: false
    }
  },
  methods: {
    async getData(){
      const params = {key_type: 'username', key_data: this.username, fund_no_list: "All", fund_filter: '',
        start_eff_date: '', end_eff_date: '', trans_type_list: 'All', trans_status_list: 'All'};
      let resp = await this.$HttpServices.postData("/trans/getDCATransList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.cancels = result.filter(v=>v.trans_status=='C');
        this.pendings = result.filter(v=>v.trans_status=='S');
        this.items = result;
        this.flagLoading = false;
      }, ()=>{
        this.flagLoading = false;
      });
    },
    async getLookup1(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types1.push(v);
        }
      }, ()=>{this.types1 = Constants.transaction.types});
    },

    async getLookup2(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types2.push(v);
        }
      }, ()=>{this.types2 = Constants.transaction.types});
    },

    openDetail(obj){
      if(obj.channel == 'KKPAM Online'){
        if(obj.trans_status=='S'){
          this.$refs.orderDetailModal.open(obj.trans_no, 'transDCA_status');
        }
        else{
          this.$refs.slipTransactionModal.open(obj.trans_no, 'transDCA_status');
        }
      }
    },

    async cancleHandle(){
      await this.getData();
    },

    async openTab(){
      this.flagLoading = true;
      if(this.userType==Constants.userType.user){
        if(this.types1.length == 0){
          await this.getLookup1('trans_type_part1');
        }
        if(this.types2.length == 0){
          await this.getLookup2('trans_type_part2');
        }
        await this.getData();
      }
    }
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;
    this.userType = user.type;
    this.types = [];
    window.DCAListComponent = this;
  }
}
</script>

<style scoped>

</style>