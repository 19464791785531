<template>
  <!-- @rewrite-html
  <div id="sideNavLeft" class="sidenav1 actionsidebar">
    <div class="top-logo">
      <a id="closeSideLeftNav" href="javascript:void(0)" class="closebtn" @click="NavCtrl.closeAllNavHandle()">
        <img src="@/assets/image/closenav.png">
      </a>
      <img src="@/assets/image/logo-white.png" width="154" class="mx-auto mt-4">
    </div>
    <div class="box-menu">
      <ul>
        <li id="sideL1" :class="page=='dashboard'?'active':'hvr-float'" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="routingMenuHandle('dashboard')"><img :src="getHomeImage()" width="32" class="mx-auto me-3">หน้าหลัก</a>
        </li>
        <li id="sideL2" :class="page=='portfolio'?'active':'hvr-float'" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="routingMenuHandle('portfolio')"><img src="@/assets/image/menu2.png" width="32" class="mx-auto me-3">Portfolio</a>
        </li>
        <li id="sideL3" :class="page=='buy-fund'?'active':'hvr-float'" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="routingMenuHandle('buy-fund')"><img src="@/assets/image/menu3.png" width="32" class="mx-auto me-3">ซื้อกองทุน</a>
        </li>
        <li id="sideL4" class="hvr-float" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="NavCtrl.openSellFund($refs.sellFundModal)"><img src="@/assets/image/menu4.png" width="32" class="mx-auto me-3">ขายกองทุน</a>
        </li>
        <li id="sideL5" class="hvr-float" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="NavCtrl.openSwitchFund($refs.switchFundModal)"><img src="@/assets/image/menu5.png" width="32" class="mx-auto me-3">สับเปลี่ยนกองทุน</a>
        </li>
        <li id="sideL6" class="hvr-float" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="NavCtrl.openPendingNavHandle()"><img src="@/assets/image/menu6.png" width="32" class="mx-auto me-3">รายการรอจัดสรร</a>
        </li>
        <li id="sideL7" :class="page=='enquiry'?'active':'hvr-float'" v-show="checkTypeHandle()">
          <a href="javascript:void(0)" @click="routingMenuHandle('enquiry')"><img src="@/assets/image/menu7.png" width="32" class="mx-auto me-3">รายการเคลื่อนไหว</a>
        </li>
        <li id="sideL8" :class="page=='download-doc'?'active':'hvr-float'">
          <a href="javascript:void(0)" @click="routingMenuHandle('download-doc')"><img src="@/assets/image/menu8.png" width="32" class="mx-auto me-3">ขอเอกสารออนไลน์</a>
        </li>
        <li id="sideL9" :class="page=='form-consent'?'active':'hvr-float'">
          <a href="javascript:void(0)" @click="routingMenuHandle('form-consent')"><img src="@/assets/image/menu9.png" width="32" class="mx-auto me-3">แบบฟอร์ม และหนังสือยินยอม</a>
        </li>
      </ul>
    </div>
  </div>
  -->

  <div id="sideNavLeft" class="main-nav drawer-sidenav-6 actionsidebar">
     <div class="content">
        <div class="hd">
           <div class="top-logo">
              <a id="closeSideLeftNav" href="javascript:void(0)" class="closebtn" @click="NavCtrl.closeAllNavHandle()"><img src="@/assets/image/closenav.png"></a>
              <img src="@/assets/image/logo-white.png" width="154" class="mx-auto mt-4">
           </div>
        </div>
        <div class="bd">
           <div class="box-menu m-l-t16">
              <ul>
                <li id="sideL1" :class="page=='dashboard'?'active':''" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="routingMenuHandle('dashboard')"><img :src="getHomeImage()" width="32" class="mx-auto me-3">หน้าหลัก</a>
                </li>
                <li id="sideL2" :class="page=='portfolio'?'active':''" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="routingMenuHandle('portfolio')"><img src="@/assets/image/menu2.png" width="32" class="mx-auto me-3">Portfolio</a>
                </li>
                <li id="sideL3" :class="page=='buy-fund'?'active':''" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="routingMenuHandle('buy-fund')"><img src="@/assets/image/menu3.png" width="32" class="mx-auto me-3">ซื้อกองทุน</a>
                </li>
                <li id="sideL4" class="" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="NavCtrl.openSellFund($refs.sellFundModal)"><img src="@/assets/image/menu4.png" width="32" class="mx-auto me-3">ขายกองทุน</a>
                </li>
                <li id="sideL5" class="" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="NavCtrl.openSwitchFund($refs.switchFundModal)"><img src="@/assets/image/menu5.png" width="32" class="mx-auto me-3">สับเปลี่ยนกองทุน</a>
                </li>
                <li id="sideL6" class="" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="NavCtrl.openPendingNavHandle()"><img src="@/assets/image/menu6.png" width="32" class="mx-auto me-3">รายการรอจัดสรร</a>
                </li>
                <li id="sideL7" :class="page=='enquiry'?'active':''" v-show="checkTypeHandle()">
                  <a href="javascript:void(0)" @click="routingMenuHandle('enquiry')"><img src="@/assets/image/menu7.png" width="32" class="mx-auto me-3">รายการเคลื่อนไหว</a>
                </li>
                <li id="sideL8" v-show="allowPages.DownloadDocPage && !guestType" :class="page=='download-doc'?'active':''">
                  <a href="javascript:void(0)" @click="routingMenuHandle('download-doc')"><img src="@/assets/image/menu8.png" width="32" class="mx-auto me-3">ขอเอกสารออนไลน์</a>
                </li>
                <li id="sideL9" :class="page=='form-consent'?'active':''">
                  <a href="javascript:void(0)" @click="routingMenuHandle('form-consent')"><img src="@/assets/image/menu9.png" width="32" class="mx-auto me-3">แบบฟอร์ม และหนังสือยินยอม</a>
                </li>
              </ul>
           </div> 
           
        </div>
        <div class="ft">
           <div class="footer-box">Version 1.0.0 Al apha</div>
        </div>
     </div>
  </div>

  <SellFundComponent ref="sellFundModal"/>
  <SwitchFundComponent ref="switchFundModal"/>
</template>

<script>
import NavCtrl from "@/utils/NavCtrl";
import Constants from "@/utils/Constants";
import SellFundComponent from "@/components/includes/page/market/sell/SellFundComponent";
import SwitchFundComponent from "@/components/includes/page/market/switch/SwitchFundComponent";

export default {
  name: "SideLeftComponent",
  components: {SellFundComponent, SwitchFundComponent},
  data() {
    return {
      allowPages: Constants.allowPages,
      NavCtrl: NavCtrl,
      userType: '',
      guestType: null,
      page: ''
    }
  },
  methods: {
    routingMenuHandle(page){
      this.NavCtrl.closeAllNavHandle();
      this.page = page;
      this.$router.push(Constants.basePath + page);
    },
    checkTypeHandle(){
      return this.userType==Constants.userType.user;
    },
    getHomeImage(){
      let img = '';
      try{
        if(this.page == 'dashboard'){
          img = require(`@/assets/image/menu1.png`);
        }
        else{
          img = require(`@/assets/image/menu1-1.png`);
        }
      }catch(e){
        img = '';
      }
      return img;
    },
  },
  mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.userType = user.type;
    this.guestType = user?.guestType;

    let path = this.$router.currentRoute.value.path;
    path = path.replace(Constants.basePath, '');
    this.page = path;
  }
}
</script>

<style scoped>

</style>