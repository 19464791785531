import Constants from "@/utils/Constants";
import store from "@/utils/store";
import ValidateUtils from "@/utils/ValidateUtils";

const Auth = {
    async authMiddleware({ next, router }) {
        const validUser = store.getJsonData(Constants.session.user);
        if (!validUser) {
            if(Constants.allowPages.AuthenPage) {
                return router.push(Constants.basePath + "auth");
            } else {
                return router.push(Constants.basePath + "guest");
            }
        }
        return next();
    },

    async authLoginMiddleware({ next, router }) {
        const validUser = store.getJsonData(Constants.session.user);
        if (validUser) {
            return router.push(Constants.basePath + "dashboard");
        }

        return next();
    },

    async authSignOutMiddleware(self){
        const user = self.$store.getJsonData(Constants.session.user);
        if(user){
            const loginType = user.type;
            if(loginType==Constants.userType.guest){
                let redirect = 'auth';
                if(loginType==Constants.userType.guest){
                    const query = user.query;
                    if(user?.guestType && user.guestType == Constants.userType.guestNoUHD) {
                        redirect = `guest-nouhd${query?`?p=${query}`:''}`;
                    } else {
                        redirect = `guest${query?`?p=${query}`:''}`;
                    }
                }
                self.$store.removeAllData();
                window.location.href = Constants.basePath + `${redirect}`;
            }
            else{
                const params = {username: user.username};
                const resp = await self.$HttpServices.postData("/customer/Logout", params);
                ValidateUtils.validateResponse(resp, null, async ()=>{
                    const res = resp.result;
                    if(res[0].logout_result){
                        let redirect = 'auth';
                        if(loginType==Constants.userType.guest){
                            const query = user.query;
                            if(user?.guestType && user.guestType == Constants.userType.guestNoUHD) {
                                redirect = `guest-nouhd${query?`?p=${query}`:''}`;
                            } else {
                                redirect = `guest${query?`?p=${query}`:''}`;
                            }
                        }
                        self.$store.removeAllData();
                        window.location.href = Constants.basePath + `${redirect}`;
                    }
                });
            }
        }
    },

    async redirectAuthPageMiddleware(self, user){
        if(user){
            const loginType = user.type;
            let redirect = 'auth';
            if(loginType==Constants.userType.guest){
                const query = user.query;
                if(user?.guestType && user.guestType == Constants.userType.guestNoUHD) {
                    redirect = `guest-nouhd${query?`?p=${query}`:''}`;
                } else {
                    redirect = `guest${query?`?p=${query}`:''}`;
                }
            }
            self.$store.removeAllData();
            window.location.href = Constants.basePath + `${redirect}`;
        }
    },

    async redirectAuthRouteMiddleware(){
        const user = store.getJsonData(Constants.session.user);
        let redirect = 'auth';
        if(user) {
            const loginType = user.type;
            if(loginType==Constants.userType.guest){
                const query = user.query;
                if(user?.guestType && user.guestType == Constants.userType.guestNoUHD) {
                    redirect = `guest-nouhd${query?`?p=${query}`:''}`;
                } else {
                    redirect = `guest${query?`?p=${query}`:''}`;
                }
            }
            store.removeAllData();
        }

        let error = store.getJsonData(Constants.global.error);
        if(!error) {
            store.setJsonData(Constants.global.error, "global.error");
            window.location.href = Constants.basePath + `${redirect}`;
        }
    }
}

export default Auth;