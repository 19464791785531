<template>
  <div>
    <router-view></router-view>
    <footer class="footer footer-kkp mt-auto py-3 fixed-bottom">
      <div class="container-fluid">
        <div class="float-start">
          <ul>
            <li><a class="body2-regular" :href="manual_link" target="_blank">คู่มือการใช้งาน</a></li>
            <li><a class="body2-regular" :href="privacy_policy_link" target="_blank">นโยบายความเป็นส่วนตัว</a></li>
            <li><a class="body2-regular" href="#" @click.prevent="openTerm">ข้อมูลบริการ KKPAM Online</a></li>
          </ul>
        </div>
        <div class="body2-regular float-end d-none d-lg-block">
          © สงวนลิขสิทธิ์ 2563 บริษัทหลักทรัพย์จัดการกองทุน เกียรตินาคินภัทร จำกัด
        </div>
      </div>
    </footer>
  </div>

  <AlertComponent ref="alert"/>
  <ConditionModalComponent ref="conditionModal"/>
  <CookieComponent/>
</template>

<script>
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import ConditionModalComponent from "@/components/includes/modals/ConditionModalComponent";
import CookieComponent from "@/components/includes/CookieComponent";
import Constants from "@/utils/Constants";
import {nextTick} from "vue";
import StringUtils from "@/utils/StringUtils";

export default {
  name: "AuthLayout",
  components: {AlertComponent, ConditionModalComponent, CookieComponent},
  data() {
    return {
      imgBg: require('@/assets/image/bg-login.jpg'),
      username: 'guestlogin_page',
      manual_link: Constants.global.manualLink,
      privacy_policy_link: Constants.global.privacyPolicyLink
    }
  },
  methods: {
    async openTerm(){
      await this.$refs.conditionModal.openModal(this.username, 'privacy_policy', false);
    }
  },
  async mounted(){
    await nextTick();
    var v_this = this;
    window.$('body').off("show.bs.modal.auth").on("show.bs.modal.auth", ".modal", function () {
      // eslint-disable-next-line no-unused-vars
      let $this = window.$(this);
      let stack = window.$('.modal:visible').length;
      var z_index = 1070;
      let id = "";
      if($this.attr("id")) {
        id = $this.attr("id");
      } else if($this.attr("gen-id")) {
        id = $this.attr("gen-id");
      } else {
        id = StringUtils.getUUIDV4();
        $this.attr("gen-id", id);
      }

      var backdrop = window.$('.modal-backdrop[for="'+ id +'"]');

      if(stack > 0) {
        let other_backdrop = window.$('.modal-backdrop:not([for="'+ id +'"])');
        if(other_backdrop.length > 0) {
          other_backdrop.each(function() {
            if(window.$(this).css("z-index") && window.$(this).css("z-index") >= (z_index - 1)) {
              z_index = parseInt(window.$(this).css("z-index")) + 11;
            }
          });
        }
      }

      if(window.$($this.find(".modal-content")).length > 0) {
        window.$($this.find(".modal-content")).animate({scrollTop: 0},400);
      }

      $this[0].style.setProperty('z-index', z_index, "important");

      setTimeout(function () {
        if(backdrop.length > 0) {
          backdrop[0].style.setProperty('z-index', (z_index - 1),  "important");
        } else {
          window.$('.modal-backdrop').not('.modal-stack')
            .addClass('modal-stack')
            .attr("for", id);
          let backdrop = window.$('.modal-backdrop[for="'+ id +'"]');
          if(backdrop.length > 0) {
            backdrop[0].style.setProperty('z-index', (z_index - 1), "important")
          }
        }
      }, 0);
    });

    window.$('body').off("hidden.bs.modal.auth").on('hidden.bs.modal.auth', function () {
      if(window.$('.modal.show:visible').length > 0)
      {
        window.$('body').addClass('modal-open');
      }
    });

    window.addEventListener('popstate', function() {
      if(window.$('.modal.show:visible').length == 0)
      {
        let backdrop = window.$('.modal-backdrop');
        if(backdrop?.length > 0) {
          window.$('.modal-backdrop').each(function() {
            window.$(this).remove();
          });
        }
      }
    });

    let error = this.$store.getJsonData(Constants.global.error);
    if(error) {
      setTimeout(function () {
        if(v_this.$t('global.warning') != 'global.warning') {
          v_this.$refs.alert?.show([v_this.$t(error)], "warning", async ()=>{}, v_this.$t('global.warning'));
          v_this.$store.removeData(Constants.global.error);
        }
      }, 500);
    }

    document.body.style.backgroundImage = `url(${require('@/assets/image/bg-login.jpg')})`;
    const user = this.$store.getJsonData(Constants.session.user);
    if(user){
      const userType = user.type;
      if(userType==Constants.userType.guest){
        this.username = 'guestlogin_page';
      }
      else{
        this.username = user.username;
      }
    }
  },
  beforeUnmount() {
    window.$('body').off("show.bs.modal.auth");
    window.$('body').off("hidden.bs.modal.auth");

    document.body.className = "bg";
    document.body.style.backgroundImage = ``;
  },
}
</script>

<style scoped>
.bg{background-image: url('../../assets/image/bg-login.jpg')}
#app > div {
  width: 100%;
}
</style>