<template>
  <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
  <div class="modal fade" :id="`riskCondition-${id}`" ref="modalRiskCond" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="m-risk modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
      <div class="modal-content modal-terms">
        <div class="modal-body">
          <div class="container">
            <div class="row condition">
              <div class="col-12 col-sm-12 p-0">
                <div class="condition-box mb-5">
                  <h2 class="body-medium"><div class="line-left float-start"></div>{{ risk_header }}</h2>
                  <hr>
                  <div class=" m-l-b56">
                    <div class="row" v-for="(r, index) in risk_detail" :key="`r_${index}`">
                      <ul class="unstyled centered" v-if="r.detail.includes('[_]')">
                        <li>
                          <input :class="`styled-checkbox checkbox-${id}`" :id="`styled-checkbox-${index}-${id}`" type="checkbox" v-model="checkboxs" :value="r.risk_id">
                          <label :for="`styled-checkbox-${index}-${id}`"></label>
                        </li>
                      </ul>
                      <div class="col-10 col-sm-10">
                        <label class="body2-regular description-text" v-if="r.detail.includes('[_]')">{{ r.detail.replace('[_] ', '') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-x-16-y-8-sm-x-6-y-24">
          <div class="container-md">
            <div class="row g-3">
              <div class="col-12 col-sm-6 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-lg-1 order-2">
                <button :id="`b1${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="back">{{ $t('global.back') }}</button>
              </div>
              <div class="col-12 col-lg-3 col-sm-12 order-lg-2 order-1">
                <button :id="`b2${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="submitHandle" :disabled="checkDisabled() || flagLoadingOverlay">{{ $t('global.confirm') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AlertComponent ref="alertModal"/>
</template>

<script>
import {Modal} from "bootstrap";
import StringUtils from "@/utils/StringUtils";
import Constants from "@/utils/Constants";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";

export default {
  name: "RiskConditionModalComponent",
  components: {AlertComponent, LoadingOverlayComponet},
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      flagLoadingOverlay: false,
      modal: null,
      risk_header: '',
      risk_detail: {},
      type: '',
      fund_no: '',
      checkboxs: [],
      answer: [],
      unitholder_id: ''
    }
  },
  props: {cancel:{default: true}, submitButton: {default: ''}},
  methods: {
    /********* Action ***********/
    async openModal(risk_header, risk_detail, type, fund_no, unitholder_id){
      this.clear();
      this.risk_header = risk_header;
      this.risk_detail = risk_detail;
      this.type = type;
      this.fund_no = fund_no;
      this.unitholder_id = unitholder_id;
      this.modal.show();
    },
    back(){
      this.modal.hide();
      this.clear();
      this.$emit('back');
    },
    async submitHandle(){
      this.flagLoadingOverlay = true;
      let risk = [];
      for(const v of this.risk_detail){
        const obj = this.checkboxs.find(c=>v.risk_id==c);
        if(obj){
          let data = {risk_id: v.risk_id, answer: 'Y'};
          risk.push(data);
        }
        else{
          let data = {risk_id: v.risk_id, answer: 'N'};
          risk.push(data);
        }
      }
      this.answer = risk;

      const params = {username: this.username, unitholder_id: this.unitholder_id, risk_awn_type: this.type, fund_no: this.fund_no, answers: this.answer};
      const resp = await this.$HttpServices.postData("/riskawn/SaveAnswerList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.save_result == 'Y'){
            this.modal.hide();
            this.clear();
            this.$emit('confirm');
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
      this.flagLoadingOverlay = false;
    },

    checkDisabled(){
      if(this.checkboxs.length == 0){
        return true;
      }
      else{
        if(this.checkboxs.length != window.$('.checkbox-'+this.id).length){
          return true;
        }
        else{
          return false;
        }
      }
    },
    clear(){
      this.risk_header = '';
      this.risk_detail = '';
      this.checkboxs = [];
      this.answer = [];
    }
    /********* Action ***********/
  },
  mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    this.modal = new Modal(this.$refs.modalRiskCond);
  }
}
</script>

<style scoped>

</style>