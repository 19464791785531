import moment from "moment";

const DateUtils = {
    currentDateFormat(format){
        return moment().format(format);
    },
    currentStringFormat(date, format){
        return moment(date).format(format);
    },
    toDateFormat(date, format){
        return moment(date, format).toDate();
    },
    toDateStrFromDateStr(date, fromFormat, toFormat){
        return moment(date, fromFormat).format(toFormat);
    },
    addDateFromCurrent(duration){
        return moment().add(duration, 'days');
    },
    subtractDateFromCurrent(duration){
        return moment().subtract(duration, 'days');
    },
    addYearFromCurrent(duration){
        return moment().add(duration, 'years');
    },
    subtractYearFromCurrent(duration){
        return moment().subtract(duration, 'years');
    },
    addMinutesFromCurrent(duration){
        return moment().add(duration, 'minutes');
    },
    subtractMinutesFromCurrent(duration){
        return moment().subtract(duration, 'minutes');
    },
    addSecondsFromCurrent(duration, from = null){
        if(from) {
            return moment(from).add(duration, 'seconds');
        } else {
            return moment().add(duration, 'seconds');
        }
    },
    subtractSecondsFromCurrent(duration){
        return moment().subtract(duration, 'seconds');
    },
    checkBefore(from, to){
        return moment(from).isBefore(to);
    },
    checkAfter(from, to){
        return moment(from).isAfter(to);
    },
    checkCurrentBefore(src, format){
        return moment().isBefore(moment(src, format));
    },
    checkCurrentAfter(src, format){
        return moment().isAfter(moment(src, format));
    }
};

export default DateUtils;