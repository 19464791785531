<template>
  <Teleport to="body">
    <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
    <div class="m-fund-buy modal fade" :id="`edit-fund${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
        <div class="modal-content modal-condition form bg-main">
          <div class="modal-header">
            <h2 class="subtitle-medium d-flex align-items-center">
              <div class="line-left"></div> <span class="head-hidden-m flex-grow-1">{{ $t('market.buy.head') }}</span>

              <a href="javascript:void(0)" class="kkp-toggle dropdown-toggle btn-toggle me-2" :id="`dropdownMenuButton1-${id}`">
                <p class="body2-medium float-end mb-0"><img src="@/assets/image/portfolio3.png" width="30" class="mx-auto  me-3"><span>{{ curUnitHolder }}</span> <img src="@/assets/image/more.png" class="mx-2"></p>
              </a>
              <a :id="`cc${id}`" href="javascript:void(0)" @click="closeConfirm"><img src="@/assets/image/close.png" class="mx-auto mx-2" width="35"></a>

            </h2>
          </div>
          <div class="modal-body p-0">
            <div class="m-ms">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                  <div class="test-q">
                    <div class="content-box6 pb-0 bg-none">
                      <h5 class="subtitle-regular color-title d-none d-sm-block">{{ $t('market.buy.fund') }}</h5>
                      <div class="subbox m-s-b24">
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <h5 class="body-medium mb-0">{{ data.fund_code }}</h5>
                            <p class="body2-regular hint-text m-b8">{{ (lang=='TH')?data.fund_name_TH:data.fund_name_EN }}</p>
                            <div class="tag-desktop col-12">
                              <p class="float-start head-tag m-0">Risk LV. <img :src="getRiskImage(data.fund_risk_level)" class="mx-auto"></p>
                              <div class="wrap-free">
                                <p class="float-start head-tag m-0"> {{ data.fund_class_type }}</p>
                              </div>
                              <a :href="fundData.fund_factsheet_link" target="_blank" v-if="fundData.fund_factsheet_link != null && fundData.fund_factsheet_link != ''">
                                <p class="float-end tag-link btntitle text-white m-0">Prospectus <img src="@/assets/image/right.svg" class="mx-auto"></p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 class="head-title mt-2 d-none d-lg-block">{{ $t('market.buy.payment') }}</h5>
                      <div class="subbox d-none d-lg-block">
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <div class="head-slider" :id="`dropdownMenuButton3-${id}`">
                              <img :src="bankImage(curBankName)" class="mx-auto float-start me-3">
                              <div class="row m-w-txt-bank">
                                <h5 class="body-medium m-b4 col-12">{{ curBankName }}</h5>
                                <p class="float-start head-tag m-0 col-12"><img src="@/assets/image/book.png" class="mx-auto"> {{ curPayment }}</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7">
                  <div class="test-q">
                    <div class="content-box6 bg-none">
                      <h5 class="subtitle-regular color-title">{{ $t('market.buy.unitBuy') }}</h5>
                      <div class="subbox">
                        <div class="input-group">
                          <AutoNumericVue :id="`unitBuy-${id}`" class="form-control text-center" v-model="item.unitBuy" aria-label="Text input with dropdown button" placeholder="0.00" :value="item.unitBuy" disabled></AutoNumericVue>

                          <button class="btn-modal dropdown-toggle body-regular color-title btn-i-disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">THB</button>
                        </div>
                        <p class="body2-regular color-title mb-4 m-l-t8 mb-0" v-if="!validate.unitBuyMin">
                          <img src="@/assets/image/Information.png" class="mx-auto me-2"> {{ $t('market.buy.buyMin') }} {{ TransactionServices.decimalFormat(min) }} THB
                        </p>
                      </div>

                      <h5 class="subtitle-regular m-m-t24 color-title">{{ $t('market.buy.transDate') }}</h5>
                      <div class="subbox">
                        <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                          <li :class="(dcaTab=='no')?'nav-item active':'nav-item'" role="presentation">
                            <button :class="(dcaTab=='no')?'nav-link active':'nav-link'" id="pills-home-tab22" data-bs-toggle="pill" data-bs-target="#pills-home22" type="button" role="tab" aria-controls="pills-home" aria-selected="true" disabled="">
                              <h5 class="body-medium" :style="[(dcaTab=='yes')?{color: '#bdbdd0'}:'']">{{ $t('market.buy.purchaseOneTime') }}</h5>
                            </button>
                          </li>
                          <li :class="(dcaTab=='yes')?'nav-item active':'nav-item'" role="presentation">
                            <button :class="(dcaTab=='yes')?'nav-link active':'nav-link'" id="pills-profile-tab22" data-bs-toggle="pill" data-bs-target="#pills-profile22" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" >
                              <h5 class="body-medium" :style="[(dcaTab=='no')?{color: '#bdbdd0'}:'']">{{ $t('market.buy.purchaseCon') }}</h5>
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent22">
                          <div :class="(dcaTab=='yes')?'tab-pane fade active show':'tab-pane fade'" id="pills-profile22" role="tabpanel" aria-labelledby="pills-profile-tab22">
                            <form class="row g-3">
                              <div class="col-12 col-xl-6 col-lg-12 col-sm-12">
                                <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.makeEveryDay') }} </p>
                                <div class="select2-wrap">
                                  <Select2Component type="select2" :setting="{
                                      'placeholder': $t('market.buy.selectDay'),
                                      'style': [(validate.day)?{border: '2px solid red'}:''],
                                      'class': 'text-center',
                                      'searchable': false,
                                      'choice': Array.from({length: 25}, (_, i) => i + 1),
                                      'disabled': true,
                                  }" :value="day" @selected="day = $event"></Select2Component>

                                </div>
                              </div>
                              <div class="col-12 col-xl-6 col-lg-12 col-sm-12">
                                <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.startDate') }}</p>
                                <div class="input-group mb-2">
                                  <input type="text" class="form-control text-center" placeholder="27/03/2565" v-model="item.dateBuy2" :id="`datePick2-${id}`"
                                  disabled autocomplete="off">
                                  <button class="btn-modal btn-i-disabled" type="button" id="button-addon2" disabled><img src="@/assets/image/date-disabled.svg" class="mx-auto"></button>
                                </div>
                              </div>
                              <div class="col-8">
                                <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.setEndDate') }}</p>
                              </div>
                              <div class="col-4">
                                <div class="switch float-end">
                                  <input type="checkbox" :id="`switch-${id}`" v-model="item.switch">
                                  <label :for="`switch-${id}`">Toggle</label>
                                </div>
                              </div>
                              <div class="col-12" v-show="item.switch">
                                <p class="body2-medium color-title m-ss-b8">{{ $t('market.buy.endDate') }}</p>
                                <div class="input-group mb-2">
                                  <input type="text" class="form-control text-center" :disabled="!curUnitHolder" :placeholder="$t('global.pleaseInput')" :id="`datePick3-${id}`" v-model="item.dateBuy3"
                                  :style="[(validate.dateBuy3)?{border: '2px solid red'}:'']" autocomplete="off">
                                  <button class="btn-modal" type="button" id="button-addon3" @click="openDate('datePick3-'+id)">
                                    <img v-if="!curUnitHolder" src="@/assets/image/date-disabled.svg" class="mx-auto">
                                    <img v-else src="@/assets/image/date.svg" class="mx-auto">
                                  </button>
                                </div>
                                <p class="va hvr-float label-required" v-if="validate.dateBuy3">
                                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('market.buy.validate.dateBuy') }}
                                </p>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <p class="body2-regular color-title m-ss-b8 mt-2"><img src="@/assets/image/Information.png" class="mx-auto me-2"> {{ $t('market.buy.note1a') }} <span class="color-BE1E2D">{{ (lang=='TH')?cutOffTime+' น.':cutOffTime }}</span> {{ $t('market.buy.note1b') }}</p>
                      <p class="body2-regular color-title"><img src="@/assets/image/Information.png" class="mx-auto me-2"> {{ $t('market.buy.note2') }}</p>

                      <h5 class="subtitle-regular mt-4 d-block d-lg-none">{{ $t('market.buy.payment') }}</h5>
                      <div class="subbox d-block d-lg-none">
                        <div class="row">
                          <div class="col-12 col-sm-12">
                            <div class="head-slider" :id="`dropdownMenuButton4-${id}`">
                              <img :src="bankImage(curBankName)" class="mx-auto float-start me-3">
                              <div class="row m-w-txt-bank">
                                <h5 class="subtitle-regular m-b4 col-12">{{ curBankName }}</h5>
                                <p class="float-start me-2 mb-0 head-tag col-12"><img src="@/assets/image/book.png" class="mx-auto"> {{ curPayment }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 m-t-sm-24 d-block d-sm-none">
                        <div class="col-12 col-sm-6 d-none d-md-block"></div>
                        <div class="col-12 col-sm-3"></div>
                        <div class="col-12 col-sm-3">
                          <button type="button" class="btn w-100 btn-main hvr-float" @click="saveHandle" :disabled="!ValidDisabled()">{{ $t('global.save') }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-none d-sm-block">
            <div class="row g-3">
              <div class="col-12 col-sm-6 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-sm-1 order-1 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-sm-2 order-2 m-0">
                <button type="button" class="btn w-100 btn-main hvr-float" @click="saveHandle" :disabled="!ValidDisabled()">{{ $t('global.save') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade logout" :id="`password${id}`" ref="oldPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-logout modal-medium">
          <div class="modal-body p-0">
            <div class="text-center">
              <center><img src="@/assets/image/password1.png" class="mx-auto mt-56 mb-48"></center>
              <h4 class="title-medium m-s-b16">{{ $t('passwordSetting.inputPassword') }}</h4>
              <p class="body2-regular description-text m-m-b32">{{ $t('passwordSetting.inputPasswordDetail') }}</p>
              <div class="m-l-b56">
                <div class="position-relative form-toggle-password">
                  <input :type="passwordType" class="form-control body-regular icon icon-password" placeholder="*********" v-model="item.password" v-on:keyup.enter="verifyPasswordHandle">
                  <div class="toggle-password" style="cursor: pointer;margin-left: -3px;" @click="togglePassword">
                    <img data-status-icon="close" :src="imgEye">
                  </div>
                </div>
                <div class="va hvr-float label-required" v-if="validates.password">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.passwordRequired') }}
                </div>
                <div class="va hvr-float px-2 label-required" v-if="apiError.password">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  <span v-html="apiError.password?apiError.password:''"></span>
                </div>
              </div>

              <div class="row g-3">
                <div class="col-12 col-sm-12 col-lg-6 order-lg-1 order-md-2  order-sm-2 order-2">
                  <button type="button" data-bs-dismiss="modal" class="btn w-100 btn-sec hvr-float" @click="closePassword">
                    {{ $t('global.cancel') }}
                  </button>
                </div>
                <div class="col-12 col-sm-12 col-lg-6 order-lg-2  order-md-1  order-sm-1  order-1">
                  <button type="button" class="btn w-100 btn-main hvr-float" @click="verifyPasswordHandle">
                    {{ $t('global.confirm') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AlertComponent ref="alertModal"/>
    <AlertConfirmComponent ref="alertConfirmModal"/>
  </Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import moment from 'moment';
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";
import AlertConfirmComponent from "@/components/includes/alerts/AlertConfirmComponent";
import TransactionServices from "@/services/TransactionServices";
import Select2Component from "@/components/includes/Select2Component";

export default {
  name: "EditFundModalComponent",
  components: {AlertComponent, AutoNumericVue, LoadingOverlayComponet, AlertConfirmComponent, Select2Component},
  data() {
    return {
      TransactionServices: TransactionServices,
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      // modalSelectUID: null,
      // modalSelectPayment: null,
      PasswordModal: null,
      actionPage: 'market_purchaseform_page',
      unitHolders: [],
      curUnitHolder: '',
      curRisk: '',
      fullname: '',
      fundData: [],
      fund_no: '',
      paymentList: [],
      curBankName: '',
      curPayment: '',
      dcaTab: 'yes',
      item: {
        unitBuy: '',
        dateBuy2: '',
        dateBuy3: '',
        switch: false
      },
      validate: {},
      min: 5000,
      payment: [],
      day: '',
      dayShow: '',
      flagLoading: false,
      flagLoadingOverlay: false,
      cutOffTime: '',
      data: {},
      passwordType: 'password',
      imgEye: require('@/assets/image/close.svg'),
      trans_no: '',
      items: {},
      validates: {},
      apiError: {},
    }
  },
  methods: {
    async open(obj){
      this.flagLoadingOverlay = true;
      this.data = obj;
      this.fund_no = obj.fund_no;
      this.trans_no = obj.trans_no;

      let countCallback = 0;
      let v_this = this;
      await this.getUnitholderList(obj.unitholder_id);
      this.getFundInfo(function() {
        if(++countCallback == 3) {
          v_this.presetData(obj);
        }
      });
      this.getFullname(function() {
        if(++countCallback == 3) {
          v_this.presetData(obj);
        }
      });

      this.getPaymentInfo(obj.payment, function() {
        if(++countCallback == 3) {
          v_this.presetData(obj);
        }
      });
    },

    presetData(obj) {
      this.item.unitBuy = obj.trans_amt;
      let tempDate = obj.start_dca_eff_date.split('/');
      let _date = tempDate[0] +'/'+ tempDate[1] +'/'+ '25'+tempDate[2];
      this.item.dateBuy2 = _date;

      this.item.switch = false;
      if(obj.end_dca_eff_date!=''&&obj.end_dca_eff_date!=null){
        tempDate = obj.end_dca_eff_date.split('/');
        _date = tempDate[0] +'/'+ tempDate[1] +'/'+ '25'+tempDate[2];
        this.item.dateBuy3 = _date;
        this.item.switch = true;
      }
      else{
        this.item.dateBuy3 = '';
      }

      if(obj.dca_order_day){
        this.selectDay(obj.dca_order_day);
      }

      this.modal.show();
      this.flagLoadingOverlay = false;
    },

    async getFundInfo(callback = function() {}){
      const params = {username: this.username, fund_no_list: this.fund_no.toString(), fund_class_id: 0};
      const resp = await this.$HttpServices.postData("/fund/GetInfo", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.cutOffTime = result[0].cut_off_time_buy;
        callback();
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
        callback();
      });
    },

    closeConfirm(){
      this.$refs.alertConfirmModal.show([this.$t('market.buy.confirmCloseDesc')], 'warning', ()=>{
        this.close();
      }, this.$t('global.warning'), this.$t('market.buy.confirmCloseBTN'));
    },

    close(){
      this.curUnitHolder = '';
      this.unitHolders = [];
      this.paymentList = [];
      this.curPayment = '';
      this.curBankName = '';
      this.item.unitBuy = '';
      this.item.dateBuy2 = '';
      this.item.dateBuy3 = '';
      this.item.switch = false;
      this.selectDay('');
      this.validate = {};
      this.item = {};
      this.modal.hide();
    },

    async saveHandle(){
      this.flagLoadingOverlay = true;
      let value = window.$('#unitBuy-'+this.id).val();
      let dateBuy3 = window.$('#datePick3-'+this.id).val();
      this.item.unitBuy = value;
      this.item.dateBuy3 = dateBuy3;

      if(!this.checkValidate()){
        this.flagLoadingOverlay = false;
        return;
      }

      this.modal.hide();
      let params = {};
      let api = '';
      let amt = this.data.trans_amt;
      let tempDate = '';
      let eff_date = '';

      tempDate = this.data.start_dca_eff_date.split('/');
      eff_date = (parseInt('25'+tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
      eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);

      let end_dca_eff_date = null;
      if(this.item.switch){
        if(this.item.dateBuy3 != "" && this.item.dateBuy3 != undefined){
          tempDate = this.item.dateBuy3.split('/');
          end_dca_eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
          end_dca_eff_date = moment(String(end_dca_eff_date)).format(Constants.date.requestDateFormat);
        }
      }

      params = {
        fund_no: this.fund_no, trans_type: "BUY", unitholder_id: this.curUnitHolder, req_amt: parseFloat(amt), req_unit: 0, start_dca_eff_date: eff_date, end_dca_eff_date: end_dca_eff_date, frequency: "MTH", dca_order_day: parseInt(this.day), payment: this.payment, fund_no_sw: 0, old_trans_no: this.trans_no, req_all_unit: 'N'
      };

      api = "/trans/ValidateDCATrans";

      const resp = await this.$HttpServices.postData(api, params);
      ValidateUtils.validateResponse2(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'Y'){
            this.PasswordModal = new Modal(this.$refs.oldPasswordModal);
            this.PasswordModal.show();
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.modal.show()});
      });
      this.flagLoadingOverlay = false;
    },

    back(){
      this.modal.show();
    },

    checkValidate(){
      this.validate = {};
      let flagRequired = true;

      if(this.item.switch){
        if(!this.item.dateBuy3){
          this.validate.dateBuy3 = true;
          flagRequired = false;
        }
      }

      return flagRequired;
    },

    selectDay(day){
      this.day = day;
      if(day != ''){
        this.dayShow = day;
      }
      else{
        this.dayShow = 'เลือกวันที่';
      }
    },

    edit(){
      this.modal.show();
    },

    openDate(ele){
      window.$('#'+ele).datepicker().focus();
    },

    checkDisabled(){
      if(this.checkboxs.length == 0){
        return true;
      }
      else{
        if(this.checkboxs.length != window.$('.styled-checkbox').length){
          return true;
        }
        else{
          return false;
        }
      }
    },

    async getFullname(callback = function() {}){
      const params = {username: this.username, data_name: 'client_fullname', request_page: 'dashboard'};
      const resp = await this.$HttpServices.postData("/customer/getSingleDataByUsername", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        if(result&&result.length>0){
          this.fullname = result[0].data_value;
        }
        callback();
      }, () => {
        callback();
      });
    },

    async getUnitholderList(unitH){
      this.unitHolders = [];
      let params = {username: this.username};
      let resp = await this.$HttpServices.postData("/customer/GetUnitholderList", params);
      ValidateUtils.validateResponse(resp, null, async ()=>{
        const result = resp.result;
        const obj = result.find(v=>(v.unitholder_id==unitH));
        if(obj){
          this.curUnitHolder = obj.unitholder_id;
          this.curRisk = obj.risk_level;
          this.fullname = obj.unitholder_name;
          this.unitHolders.push(obj);
        }
      }, null);
    },

    async getPaymentInfo(paymentObj=[], callback = function() {}){
      let i = 0;
      this.paymentList = [];
      this.payment = [];
      let params = {unitholder_id: this.curUnitHolder, acc_for: "B", payment_type_list: "T"};
      let resp = await this.$HttpServices.postData("/payment/GetInfo", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        if(paymentObj[0].acc_no!=''){
          const obj = result.find(v=>(v.acc_no==paymentObj[0].acc_no));
          if(obj){
            this.curPayment = obj.acc_no;
            if(this.lang == 'TH'){
              this.curBankName = obj.acc_bank_name_TH;
            }
            else{
              this.curBankName = obj.acc_bank_name_EN;
            }
            this.payment.push(obj);
            this.paymentList.push(obj);
          }
        }
        else{
          for(const v of result){
            if(i == 0){
              this.curPayment = v.acc_no;
              this.payment = [];
              this.payment.push(v);

              if(this.lang == 'TH'){
                this.curBankName = v.acc_bank_name_TH;
              }
              else{
                this.curBankName = v.acc_bank_name_EN;
              }
            }
            this.paymentList.push(v);
            i++;
          }
        }
        callback();
      }, () => {
        callback();
      });
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    bankImage(data){
      return StringUtils.bankImage(data, this.lang);
    },

    bankImageObj(p){
      let img = '';
      if(this.lang=='TH'){
        img = StringUtils.bankImage(p.acc_bank_name_TH, this.lang);
      }else{
        img = StringUtils.bankImage(p.acc_bank_name_EN, this.lang);
      }

      return img;
    },

    closePassword(){
      this.PasswordModal.hide();
      this.modal.show();
    },

    checkValidateVerifyPassword(){
      let flagRequired = true;
      this.validates = {};
      if(!this.item.password){
        this.validates.password = true;
        flagRequired = false;
      }

      return flagRequired;
    },

    async verifyPasswordHandle(){
      if(!this.checkValidateVerifyPassword()){
        return;
      }
      this.flagLoading = true;
      const params = {username: this.username, validated_field: 'password', validated_value: this.item.password};
      const resp = await this.$HttpServices.postData("/customer/ValidateDataByUsername", params);
      this.item.password = '';
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        this.validates = {};
        this.apiError = {};
        this.PasswordModal.hide();
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'Y'){
            await this.saveEditTrans();
          }
        }

      }, ()=>{
        this.PasswordModal.hide();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.PasswordModal.show()});
      });

      this.flagLoading = false;
    },

    async saveEditTrans(){
      let end_dca_eff_date = null;
      if(this.item.switch){
        if(this.item.dateBuy3 != "" && this.item.dateBuy3 != undefined){
          let tempDate = this.item.dateBuy3.split('/');
          end_dca_eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
          end_dca_eff_date = moment(String(end_dca_eff_date)).format(Constants.date.requestDateFormat);
        }
      }

      const params = {username: this.username, trans_no: this.trans_no, new_end_dca_eff_date: end_dca_eff_date};
      const resp = await this.$HttpServices.postData("/trans/EditDCATrans", params);
      this.item.password = '';
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        this.$refs.alertModal.show([], 'success', null, this.$t('global.continue'));
        window.$("body").trigger("refreshEnquiry");
      }, ()=>{
        this.PasswordModal.hide();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.PasswordModal.show()});
      });
    },

    togglePassword(){
      if(this.passwordType == 'password'){
        this.passwordType = 'text';
        this.imgEye = require('@/assets/image/open.svg');
      }
      else{
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
      }
    },
    ValidDisabled(){
      let flagRequired = true;

      if(this.item.switch){
        if(!this.item.dateBuy3){
          flagRequired = false;
        }
      }

      return flagRequired;
    },
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    this.modal = new Modal(this.$refs.modal);
    // this.modalSelectUID = new Modal(this.$refs.selectUID);
    this.PasswordModal = new Modal(this.$refs.oldPasswordModal);
    // this.modalSelectPayment = new Modal(this.$refs.selectPayment);

    this.item = {};

    window.$('#datePick2-' + this.id).datepicker({
      format: "dd/mm/yyyy",
      todayHighlight: true,
      autoclose: true,
      language: 'th',
      daysOfWeekDisabled: "0,6",
      startDate: new Date(),
       container: "#edit-fund" + this.id
    }).on("changeDate", (e) => {
      this.item.dateBuy2 = e.format();
    });

    window.$('#datePick3-' + this.id).datepicker({
      format: "dd/mm/yyyy",
      todayHighlight: true,
      autoclose: true,
      language: 'th',
      daysOfWeekDisabled: "0,6",
      startDate: new Date(),
       container: "#edit-fund" + this.id
    }).on("changeDate", (e) => {
      this.item.dateBuy3 = e.format();
    });
  },
  beforeUnmount() {
    if(window.$('#datePick2-' + this.id).data('datepicker')) {
      window.$('#datePick2-' + this.id).datepicker("destroy");
    }
    if(window.$('#datePick3-' + this.id).data('datepicker')) {
      window.$('#datePick3-' + this.id).datepicker("destroy");
    }
  }
}
</script>

<style scoped>

</style>