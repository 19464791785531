<template>
  <div class="m-alert modal fade logout" :id="`logout${id}`" ref="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="text-center">
            <center><img src="@/assets/image/warning-status.png" class="mx-auto mb-4"></center>
            <h4 class="title-medium">{{ $t('global.warning') }}</h4>
            <p class="caption description-text m-b56">{{ $t('logout.questionLogout') }}</p>
            <div class="clearfix"></div>
            <div class="row g-3">
              <div class="col-12 col-sm-12 col-lg-6 order-lg-1 order-2">
                <button :id="`close${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="hide">{{ $t('global.back') }}</button>
              </div>
              <div class="col-12 col-sm-12 col-lg-6 order-lg-2 order-1">
                <button :id="`cf${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="logoutClickHandler" data-bs-dismiss="modal">{{ $t('logout.logout') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <LoadingOverlayComponet :loading="flagLoading"/>
</template>

<script>
import { Modal } from 'bootstrap';
import StringUtils from "@/utils/StringUtils";
import Auth from "@/middleware/auth";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";

export default {
  name: "LogoutComponent",
  components: {
    LoadingOverlayComponet
  },
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      alert: null,
      flagLoading: false
    }
  },
  methods: {
    async logoutClickHandler(){
      this.flagLoading = true;
      this.hide();
      await Auth.authSignOutMiddleware(this);
      this.flagLoading = false;
    },
    show(){
      this.alert.show();
    },
    hide(){this.alert.hide();}
  },
  mounted() {
    this.alert = new Modal(this.$refs.logoutModal);
  }
}
</script>

<style scoped>

</style>