<template>
    <input type="text" ref="autoNumericRef" @autoNumeric:rawValueModified="onChange" @blur="onChange('blur')" />
</template>

<script>

import AutoNumeric from 'autonumeric/dist/autoNumeric.min'

export default {
    name: 'AutoNumbericComponent',

    props: {
        value: { required: true, default: null },
        options: { type: Object, default: null }
    },

    data: () => ({
        anElement: null,
    }),

    watch: {
        options() {
            this.updateOptions()
        },

        value() {
            this.updateValue()
        }
    },

    methods: {
        onChange(e) {
            if(!this.anElement) return;

            if(e == 'blur') {
                const value = this.anElement.rawValue !== '' ? this.anElement.getNumber() : ''
                this.$emit('numberic', value)
            }
        },

        updateOptions() {
            if(!this.anElement) return;

            this.anElement.update(this.options)
        },

        updateValue() {
            if(!this.anElement) return;

            const value = this.value !== null ? this.value : ''
            this.anElement.set(value)
        }
    },

    mounted() {
        this.anElement = new AutoNumeric(this.$refs.autoNumericRef, this.options)
        this.updateValue()
    }
}
</script>
