<template>
  <Teleport to="body">
    <div class="m-alert modal fade logout" :id="`alertModal${id}`" ref="alertModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-logout">
          <div class="modal-body">
            <div class="text-center">
              <img src="@/assets/image/completed.png" class="mx-auto mb-4" v-if="alertType=='success'">
              <img src="@/assets/image/x1.png" class="mx-auto mb-4" v-if="alertType=='error'">
              <img src="@/assets/image/warning-status.png" class="mx-auto mb-4" v-if="alertType=='warning'">
              <h2 v-show="headerMessage">{{ headerMessage }}</h2>
              <p v-for="(error, index) in messages" :key="`listAlertMessage${index}`" v-html="displayMessageHandle(error)"></p>

              <div class="clearfix"></div>
              <div class="row mt-4 g-2">
                <div class="col-12 col-sm-6 order-lg-2 order-md-2 order-sm-2 order-1">
                  <button :id="`alertModalConfirm${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="confirm">{{ buttonText }}</button>
                </div>
                <div class="col-12 col-sm-6 order-lg-1 order-md-1 order-sm-1 order-2">
                  <button :id="`alertModalClose${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="hide">{{ $t('global.close') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';
import StringUtils from "@/utils/StringUtils";
export default {
  name: "AlertConfirmComponent",
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      alertType: 'error',
      headerMessage: '',
      messages: [],
      alert: null,
      cb: null
    }
  },
  methods: {
    show(messages, alertType, cb, headerMessage, buttonText){
      this.alertType = alertType;
      this.messages = messages;
      this.cb = cb;
      this.headerMessage = headerMessage;
      this.buttonText = buttonText;
      this.alert.show();
    },
    showCallback(messages, alertType, cb, headerMessage){
      this.alertType = alertType;
      this.messages = messages;
      this.headerMessage = headerMessage;
      if(cb){
        cb();
      }
      this.alert.show();
    },
    confirm(){
      if(this.cb){
        this.cb();
      }
      this.alert.hide();
    },
    hide(){
      this.alert.hide();
    },
    displayMessageHandle(message){
      const brExp = /<br\s*\/?>/i;
      const tmps = message.split(brExp);
      let resp = '';
      if(tmps.length>1){
        let msg = '';
        for(let i=1;i<tmps.length;i++){
          if(msg){
            msg += '<br/>';
          }
          msg += `${tmps[i]}`;
        }
        resp = `<h2>${tmps[0]}</h2><br/>${msg}`;
      }else{
        resp = tmps&&tmps.length>0?tmps[0]:tmps;
      }

      return resp;
    }
  },
  mounted() {
    this.alert = new Modal(this.$refs.alertModal);
  }
}
</script>

<style scoped>

</style>