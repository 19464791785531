<template>
  <div>
    <TopBarComponent/>

    <!-- Router View -->
    <router-view></router-view>

    <!--
    <FooterComponent/>
    -->
  </div>
  <AlertComponent ref="alert"/>
  <CookieComponent/>
</template>

<script>
import TopBarComponent from "@/components/includes/menubar/TopBarComponent";
/*
import FooterComponent from "@/components/includes/menubar/FooterComponent";
*/
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import Constants from "@/utils/Constants";
import Auth from "@/middleware/auth";
import DateUtils from "@/utils/DateUtils";
import CookieComponent from "@/components/includes/CookieComponent";
import {nextTick} from "vue";
import StringUtils from "@/utils/StringUtils";

export default {
  name: "MainLayout",
  setup(){
    const imgBind = require('@/assets/image/bg-login.jpg');
    return {imgBind};
  },
  data() {
    return {
      user: {},
      timeout: null,
      sessionInterval: null,
      sessionExpire: 10,
      expired: false,
      activityEvents: [
        'mousedown', 'scroll', 'keypress'
      ]
    }
  },
  components: {AlertComponent, /*FooterComponent,*/ TopBarComponent, CookieComponent},
  methods: {
    refresh() {
      if(this.timeout){
        clearTimeout(this.timeout);
      }
      const expired = DateUtils.toDateFormat(this.user.expired, Constants.date.expireDateFormat);
      let new_expired = DateUtils.currentStringFormat(DateUtils.addSecondsFromCurrent(15).toDate(), Constants.date.expireDateFormat);
      if(DateUtils.checkBefore(new Date(), expired)){
        new_expired = DateUtils.currentStringFormat(DateUtils.addSecondsFromCurrent(15, expired).toDate(), Constants.date.expireDateFormat);
      }

      this.user.expired = new_expired;
      this.$store.setJsonData(Constants.session.user, this.user);
      const self = this;
      this.timeout = setTimeout(() => {
        self.clearSessionHandle();
      }, this.sessionExpire * 60000);
    },
    removeEventListener(){
      const self = this;
      this.activityEvents.forEach(function(eventName) {
        window.removeEventListener(eventName, self.refresh);
      });
    },
    clearSessionHandle(){
      if(this.sessionInterval){
        clearInterval(this.sessionInterval);
      }
      if(this.timeout){
        clearTimeout(this.timeout);
      }
      this.removeEventListener();
      this.$store.removeAllData();

      this.$refs.alert?.show([this.$t('global.sessionExpire')], "warning", async ()=>{
        await Auth.redirectAuthPageMiddleware(this, this.user);
      }, this.$t('global.warning'));
    }
  },
  unmounted() {
    window.$('body').off("show.bs.modal.main");
    window.$('body').off("hidden.bs.modal.main");
    Auth.authSignOutMiddleware(this);
    this.removeEventListener();
  },
  async created() {
    this.user = this.$store.getJsonData(Constants.session.user);
    const expired = DateUtils.toDateFormat(this.user.expired, Constants.date.expireDateFormat);
    if(DateUtils.checkBefore(expired, new Date())){
      this.expired = true;
    }
  },
  async mounted() {
    await nextTick();
    window.$('body').off("show.bs.modal.main").on("show.bs.modal.main", ".modal", function () {
      // eslint-disable-next-line no-unused-vars
      let $this = window.$(this);
      let stack = window.$('.modal:visible').length;
      var z_index = 1070;
      let id = "";
      if($this.attr("id")) {
        id = $this.attr("id");
      } else if($this.attr("gen-id")) {
        id = $this.attr("gen-id");
      } else {
        id = StringUtils.getUUIDV4();
        $this.attr("gen-id", id);
      }

      var backdrop = window.$('.modal-backdrop[for="'+ id +'"]');

      if(stack > 0) {
        let other_backdrop = window.$('.modal-backdrop:not([for="'+ id +'"])');
        if(other_backdrop.length > 0) {
          other_backdrop.each(function() {
            if(window.$(this).css("z-index") && window.$(this).css("z-index") >= (z_index - 1)) {
              z_index = parseInt(window.$(this).css("z-index")) + 11;
            }
          });
        }
      }

      if(window.$($this.find(".modal-content")).length > 0) {
        window.$($this.find(".modal-content")).animate({scrollTop: 0},400);
      }

      $this[0].style.setProperty('z-index', z_index, "important");

      setTimeout(function () {
        if(backdrop.length > 0) {
          backdrop[0].style.setProperty('z-index', (z_index - 1),  "important");
        } else {
          window.$('.modal-backdrop').not('.modal-stack')
            .addClass('modal-stack')
            .attr("for", id);
          let backdrop = window.$('.modal-backdrop[for="'+ id +'"]');
          if(backdrop.length > 0) {
            backdrop[0].style.setProperty('z-index', (z_index - 1), "important")
          }
        }
      }, 0);
    });

    window.$('body').off("hidden.bs.modal.main").on('hidden.bs.modal.main', function () {
      if(window.$('.modal.show:visible').length > 0)
      {
        window.$('body').addClass('modal-open');
      }
    });

    let error = this.$store.getJsonData(Constants.global.error);
    if(error) {
      this.$refs.alert?.show([this.$t(error)], "warning", async ()=>{}, this.$t('global.warning'));
      this.$store.removeData(Constants.global.error);
    }

    this.$store.removeData(Constants.session.guestUser);
    if(this.expired){
      this.removeEventListener();
      await Auth.authSignOutMiddleware(this);
    }else{
      let resp = await this.$HttpServices.getRestData(`/utils/sessionExpire`);
      this.sessionExpire = resp;

      const self = this;
      this.activityEvents.forEach(function(eventName) {
        window.addEventListener(eventName, self.refresh);
      });
      await nextTick();
      this.refresh();
    }

    window.addEventListener('popstate', function() {
      if(window.$('.modal.show:visible').length == 0)
      {
        let backdrop = window.$('.modal-backdrop');
        if(backdrop?.length > 0) {
          window.$('.modal-backdrop').each(function() {
            window.$(this).remove();
          });
        }
      }
    });

    if(this.sessionInterval==null){
      const self = this;
      await nextTick();
      this.sessionInterval = setInterval(() => {
        const cookie = self.$store.getCookie(Constants.cookies.user);
        if(cookie!=self.user.userId){
          self.clearSessionHandle();
        }
      }, 10000);
    }
  }
}
</script>

<style scoped>
</style>