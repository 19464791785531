const Constants = {
    basePath: "/",
    allowPages: {
        DashboardPage: true,
        FormConsentPage: true,
        PortfolioPage: true,
        MarketBuyFundPage: true,
        DownloadDocPage: true,
        EnquiryPage: true,
        AuthenPage: true,
        RegisterPage: true,
        ForgetPasswordPage: true,
        GuestPage: true,
        guestNoUHD: true,
        "404": true
    },
    mutiLang: false,
    reSendApi: 4,
    session: {
        guestUser: "guestUser",
        user: "user",
        token: "token",
        lang: "lang",
        clientIp: "clientIp",
        unitholder: 'unitholder',
        popupLogin: 'popupLogin',
        address: 'address',
        otherAddress: 'otherAddress',
        termDoc: 'termDoc'
    },
    cookies: {
        user: 'user'
    },

    date: {
        datetime: "YYYY-MM-DDTHH:mm:ss",
        dateTimezone: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        expiryDatetime: "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ",
        datetimeDisplay: "DD/MM/YYYY HH:mm",
        datetimeShortYearDisplay: "DD/MM/YY HH:mm",
        dateShortYearDisplay: "DD/MM/YY",
        requestDateFormat: 'YYYYMMDD',
        expireDateFormat: "YYYYMMDDHHmmss"
    },

    response: {
        header: {
            action: {
                continueMessage: 'continue',
                focus: 'focus',
                stop: 'stop',
                invalidate: 'invalidate',
                disable: 'disable',
                popup: 'popup',
                hide: 'hide'
            },
            success: 'success'
        }
    },

    transaction: {
        types: [
            {
                data_map: "BUY",
                data_value: ""
            },
            {
                data_map: "BUY(DCA)",
                data_value: ""
            },
            {
                data_map: "DIV",
                data_value: ""
            },
            {
                data_map: "LOCK",
                data_value: ""
            },
            {
                data_map: "PLED",
                data_value: ""
            },
            {
                data_map: "ROL",
                data_value: ""
            },
            {
                data_map: "SELL",
                data_value: ""
            },
            {
                data_map: "SELL(DCA)",
                data_value: ""
            },
            {
                data_map: "SEQ",
                data_value: ""
            },
            {
                data_map: "ARD",
                data_value: ""
            },
            {
                data_map: "UNLCK",
                data_value: ""
            },
            {
                data_map: "UNPLD",
                data_value: ""
            },
            {
                data_map: "UNSEQ",
                data_value: ""
            },
            {
                data_map: "TFI",
                data_value: "เข้า"
            },
            {
                data_map: "SWI",
                data_value: "เข้า"
            },
            {
                data_map: "SWO",
                data_value: "ออก"
            },
            {
                data_map: "SWO(DCA)",
                data_value: "ออก"
            },
            {
                data_map: "TFO",
                data_value: "ออก"
            },
            {
                data_map: "ARD",
                data_value: "ออกเมื่อครบกำหนด"
            }
        ],
        typeTranfers: [
            {
                data_map: "ROL",
                data_value: "Roll Over"
            },
            {
                data_map: "SELL",
                data_value: "ขาย"
            },
            {
                data_map: "SELL(DCA)",
                data_value: "ขาย(DCA)"
            },
            {
                data_map: "PLED",
                data_value: "จำนำ"
            },
            {
                data_map: "BUY",
                data_value: "ซื้อ"
            },
            {
                data_map: "BUY(DCA)",
                data_value: "ซื้อ(DCA)"
            },
            {
                data_map: "DIV",
                data_value: "ปันผล"
            },
            {
                data_map: "UNPLD",
                data_value: "เพิกถอนจำนำ"
            },
            {
                data_map: "UNSEQ",
                data_value: "เพิกถอนอายัด"
            },
            {
                data_map: "ARD",
                data_value: "รับซื้อคืนอัตโนมัติ"
            },
            {
                data_map: "ARD",
                data_value: "สับเปลี่ยน"
            },
            {
                data_map: "SWI",
                data_value: "สับเปลี่ยน"
            },
            {
                data_map: "SWO",
                data_value: "สับเปลี่ยน"
            },
            {
                data_map: "SWO(DCA)",
                data_value: "สับเปลี่ยน(DCA)"
            },
            {
                data_map: "SEQ",
                data_value: "อายัด"
            },
            {
                data_map: "LOCK",
                data_value: "อื่นๆ"
            },
            {
                data_map: "UNLCK",
                data_value: "อื่นๆ"
            },
            {
                data_map: "TFI",
                data_value: "โอนหน่วย"
            },
            {
                data_map: "TFO",
                data_value: "โอนหน่วย"
            }
        ],
        status: [
            {
                data_map: "N/A",
                data_value: "N/A"
            },
            {
                data_map: "S",
                data_value: "จัดสรรแล้ว"
            },
            {
                data_map: "C",
                data_value: "ยกเลิก"
            },
            {
                data_map: "P",
                data_value: "รอจัดสรร"
            }
        ]
    },

    userType: {
        user: 'user',
        guest: 'guest',
        guestNoUHD: 'guestlogin_nouhd_page'
    },

    fundColor: {
        all: "#594F74",
        money: "#64598A",
        fixed: "#2B5F81",
        mixed: "#4F9BC1",
        equity: "#82B4AE",
        prop: "#A1CF98",
        fif: "#C1EAB8",
        rmf: "#C2E9E5",
        ltf: "#D2D2E0",
        ssf: "#A193C9",
        ssfx: "#4F4F4F"
    },

    colorOrder: {
        0: '#64598A',
        1: '#2B5F81',
        2: '#4F9BC1',
        3: '#82B4AE',
        4: '#A1CF98',
        5: '#C1EAB8',
        6: '#C2E9E5',
        7: '#D2D2E0',
        8: '#A193C9',
        9: '#4F4F4F',
        10: '#2B2B2B',
        11: '#281B58'
    },

    global: {
        apiMemberLoginPage: 'memberlogin_page',
        apiGuestLoginPage: 'guestlogin_page',
        apiUrl: '',
        error: 'error',
        district: 'district',
        districtAmphur: 'district_amphur',
        amphur: 'amphur',
        amphurPostcode: 'amphur_postcode',
        provinceAmphur: 'province_amphur',
        province: 'province',
        country: 'country',
        manualLink: 'https://media.kkpfg.com/document/2023/Feb/%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%81%E0%B8%B2%E0%B8%A3%20KKPAM%20Online.pdf',
        privacyPolicyLink: "https://kkpfg.com/th/dataprotection",
    }
};

Constants.global.apiUrl = process.env.NODE_ENV=='production'? (typeof window !== "undefined"? window.location.origin + Constants.basePath + 'api': 'https://kkpam-new.dnacms.com/api'): 'https://kkpam-new.dnacms.com/api';

module.exports = Constants;