<template>
  <div class="overlay" v-if="loading">
    <div class="popup">
      <center>
        <img class="mt-5 pt-4" width="80" src="@/assets/image/load2.gif">
        <p class="text-white">{{ $t('global.pleaseWait') }}</p>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderOverlayComponent",
  props: {loading: {default: false}}
}
</script>

<style scoped>

</style>