<template>
  <div id="notificationNav" ref="notificationNav" class="sidenav drawer-sidenav-6 actionsidebar">
    <div class="m-noti content">
      <div class="hd">
        <h4 class="mb-0 d-flex align-items-center subtitle-medium">
          <div class="line-left float-start"></div>{{ $t('notification.header') }}
        </h4>
        <div class="d-flex align-items-center ms-auto">
          <a @click="NavCtrl.closeAllNavHandle()" href="javascript:void(0)">
            <img src="@/assets/image/x-round.png">
          </a>
        </div>
      </div>
      <div class="bd">
        <div class="tab-content">
          <div class="tab-pane fade show active" id="tabNotiAll">
            <div v-show="flagLoading">
              <div class="content-box4 d-flex">
                <div class="col-12">
                  <LoadingComponet :loading="flagLoading"/>
                </div>
              </div>
            </div>
            <div v-show="!flagLoading">
              <template v-if="noti.length==0&&items.length==0&&history==0">
                <div class="content-box4 d-flex">
                  <div class="align-self-center text-center mx-auto">
                    <img src="@/assets/image/notification.svg" class="mx-auto">
                    <p class="head-title ">{{ $t('global.noListData') }}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="card-suitability" v-for="(o, index) of noti" :key="`noti_list_${index}`"
                    :style="[getImageUrl(o.noti_picture_path)?{background: `url('${getImageUrl(o.noti_picture_path)}') no-repeat center`}:'']" @click="openPopup(o.noti_category)" 
                    style="background-size: cover;" type="button">
                  <div class="subtext">
                    <h5>{{ o.noti_header }}</h5>
                    <p>{{ o.noti_detail }}</p>
                  </div>
                </div>

                <p class="c-placeholder caption m-m-t16 m-ss-b8" v-show="items.length>0">ที่กำลังจะถึง</p>
                <div class="card card-pending-list" v-for="(o,index) of items" :key="`noti_next_list_${index}`" :id="`noti_next_list_${index}`" @click="gotoDetailHandle(o)" :type="(o.channel=='KKPAM Online')?'button':''">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3 col-md-3">
                        <h1 class="text-center mb-0">{{ displayDate(o.eff_date) }}</h1>
                        <p class=" text-center body2-regular description-text mb-0">{{ displayMonthYear(o.eff_date) }}</p>
                      </div>
                      <div class="col-9 col-md-8">
                        <h5 class="body-medium mb-0">{{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}</h5>
                        <p class="caption hint-text mb-0 text-truncate">{{ getDetail(o) }}</p>
                        <p class="caption description-text mb-0">{{ $t('global.amount') }}: {{ convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-for="(v,k,index) in history" :key="`noti_prev_list_${index}`">
                  <p class="c-placeholder caption m-m-t16 m-ss-b8">{{ v[0].eff_date }}</p>
                  <div class="card card-pending-list" :class="{'c-disabled': o.trans_status=='C'}" v-for="(o,i) of v" :key="`noti_prev_data_${index}${i}`" :id="`noti_prev_data_${index}${i}`" @click="gotoDetailHistoryHandle(o)" :style="[(o.trans_status=='C')?{opacity: '0.5'}:'']" :type="(o.channel=='KKPAM Online')?'button':''">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3 col-md-3">
                          <center><img :src="getImage(o.trans_status)" width="48" class="mx-auto mt-2"></center>
                        </div>
                        <div class="col-9 col-md-8">
                          <h5 class="body-medium mb-0">{{ TransactionServices.getTransactionType(types1, o.trans_type) }} {{ o.fund_code }} {{ TransactionServices.getTransactionType(types2, o.trans_type) }}</h5>
                          <p class="caption hint-text mb-0 text-truncate">{{ getDetail(o) }}</p>
                          <p class="caption description-text mb-0">{{ $t('global.amount') }}: {{ convertTransAmount(o) }} {{ o.unit }} {{ o.channel?`&middot; ${o.channel}`:''}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div class="tab-pane fade" id="tabNotiHistory">
            <div class="content-box4 d-flex">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/notification.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="tabNotiCalendar">
            <div class="content-box4 d-flex">
              <div class="align-self-center text-center mx-auto">
                <img src="@/assets/image/notification.svg" class="mx-auto">
                <p class="head-title ">{{ $t('global.noListData') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <SlipTransactionModalComponent ref="slipTransactionModal" @close="cancleHandle"/>
  <OrderDetailModalComponent ref="orderDetailModal" @close="cancleHandle"/>
  <KycAlertComponent ref="kycAlertModal"/>
  <SuitAlertComponent ref="suitAlertModal"/>
</template>

<script>
import ValidateUtils from "@/utils/ValidateUtils";
import Constants from "@/utils/Constants";
import DateUtils from "@/utils/DateUtils";
import StringUtils from "@/utils/StringUtils";
import NavCtrl from "@/utils/NavCtrl";
import SlipTransactionModalComponent from "@/components/includes/modals/SlipTransactionModalComponent";
import OrderDetailModalComponent from "@/components/includes/modals/OrderDetailModalComponent";
import TransactionServices from "@/services/TransactionServices";
import SuitAlertComponent from "@/components/includes/alerts/SuitAlertComponent";
import KycAlertComponent from "@/components/includes/alerts/KycAlertComponent";
import LoadingComponet from "@/components/includes/LoadingComponet";

export default {
  name: "NotificationComponent",
  components: {SlipTransactionModalComponent, OrderDetailModalComponent, SuitAlertComponent, KycAlertComponent, LoadingComponet},
  data() {
    return {
      TransactionServices: TransactionServices,
      NavCtrl: NavCtrl,
      lang: 'TH',
      username: '',
      userType: '',
      status: [],
      noti: [],
      items: [],
      history: [],
      types1: [],
      types2: []
    }
  },
  methods: {
    async getNoti(){
      const params = {username: this.username, noti_category_list: 'SUIT,KYC'};
      const resp = await this.$HttpServices.postData("/noti/GetNotiList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        this.noti = resp.result;
        // noti_category: "SUIT"
      }, ()=>{
        this.noti = [];
      });
    },
    async getItems(){
      const today = DateUtils.currentDateFormat(Constants.date.requestDateFormat);
      const nextDate = DateUtils.toDateStrFromDateStr(DateUtils.addDateFromCurrent(7).toDate(), Constants.date.dateTimezone, Constants.date.requestDateFormat);
      const params = {key_type: 'username', key_data: this.username, fund_no_list: "All", fund_filter: '',
        start_eff_date: today, end_eff_date: nextDate, trans_type_list: 'All', trans_status_list: 'P'};
      let resp = await this.$HttpServices.postData("/trans/getTransList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const items = resp.result;
        let data = [];
        for(const v of items){
          data.push(v);
        }
        this.items = data;
      }, ()=>{
        this.items = [];
      });
    },
    async getHistory(){
      const today = DateUtils.currentDateFormat(Constants.date.requestDateFormat);
      const prevDate = DateUtils.toDateStrFromDateStr(DateUtils.subtractDateFromCurrent(7).toDate(), Constants.date.dateTimezone, Constants.date.requestDateFormat);
      const params = {key_type: 'username', key_data: this.username, fund_no_list: "All", fund_filter: '',
        start_eff_date: prevDate, end_eff_date: today, trans_type_list: 'All', trans_status_list: 'C,S'};
      let resp = await this.$HttpServices.postData("/trans/getTransList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const history = resp.result;
        const obj = {};
        for(const v of history){
          // const tmps = v.eff_date.split('/');
          // const key = tmps[0]+tmps[1]+tmps[2];
          const key = v.eff_date;
          if(obj[key]){
            const arr = obj[key];
            arr.push(StringUtils.copyJSON(v));
          }else{
            const arr = [];
            arr.push(StringUtils.copyJSON(v));
            obj[key] = arr;
          }
        }
        this.history = obj;
        this.flagLoading = false;
      }, ()=>{
        this.history = [];
        this.flagLoading = false;
      });
    },
    async getLookupStatus(){
      const params = {data_name: 'trans_status'};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        this.status = result;
      }, null);
    },

    gotoDetailHandle(o){
      if(o.channel == 'KKPAM Online'){
        this.$refs.orderDetailModal.open(o.trans_no, 'trans_status');
      }
    },

    gotoDetailHistoryHandle(o){
      if(o.channel == 'KKPAM Online'){
        this.$refs.slipTransactionModal.open(o.trans_no, 'trans_status');
      }
    },

    async initialData(){
      if(this.types1.length == 0){
        await this.getLookup1('trans_type_part1');
      }
      if(this.types2.length == 0){
        await this.getLookup2('trans_type_part2');
      }
      await this.getNoti();
      await this.getItems();
      await this.getHistory();
    },

    /****** common ******/
    getTransactionType(type){
      let resp = '';
      const obj = Constants.transaction.types.find(v=>v.key==type);
      if(obj){
        resp = obj.text;
      }
      return resp;
    },
    getDetail(o){
      let resp = o.fund_name_TH;
      if(this.lang=='EN'){
        resp = o.fund_name_EN;
      }

      return resp;
    },
    convertTransAmount(o){
      let amount = 0;
      if(o.unit=='Units'){
        amount = o.trans_unit;
      }else if(o.unit=='THB'){
        amount = o.trans_amt;
      }
      amount = (''+amount).replaceAll('-', '');
      return StringUtils.decimalFormat(amount);
    },
    getImageUrl(image){
      let src = '';
      try{
        src = require(`@/assets/image/${image}`);
      }catch(e){
        src = '';
      }
      return src;
    },
    displayDate(dateStr){
      if(!dateStr){
        return '';
      }
      const tmps = dateStr.split('/');
      if(tmps.length>0){
        return tmps[0];
      }
      return '';
    },
    displayMonthYear(dateStr){
      if(!dateStr){
        return '';
      }
      const tmps = dateStr.split('/');
      if(tmps.length>2){
        let m = parseInt(tmps[1]);
        m = m<10?"0"+m:m;
        return m+"/"+tmps[2];
      }
      return '';
    },

    getImage(status){
      let img = '';
      try{
        if(status=='S'){
          img = require(`@/assets/image/completed2.png`);
        }
        else{
          img = require(`@/assets/image/x.png`);
        }
      }catch(e){
        img = '';
      }
      return img;
    },
    /****** common ******/
    async cancleHandle(){
      this.items = [];
      await this.initialData();
    },

    async openTab(){
      this.flagLoading = true;
      this.noti = [];
      this.items = [];
      this.history = [];
      if(this.userType==Constants.userType.user){
        if(this.status.length == 0){
          await this.getLookupStatus();
        }
        await this.initialData();
      }
    },

    async getLookup1(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types1.push(v);
        }
      }, ()=>{this.types1 = Constants.transaction.types});
    },

    async getLookup2(trans_type){
      const params = {data_name:trans_type};
      let resp = await this.$HttpServices.postData("/util/GetLookupData", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        const result = resp.result;
        for(const v of result){
          this.types2.push(v);
        }
      }, ()=>{this.types2 = Constants.transaction.types});
    },

    openPopup(category){
      if(category.toUpperCase()=='KYC'){
        this.$refs.kycAlertModal.initialData('');
      }
      else{
        this.$refs.suitAlertModal.initialData('');
      }
    }
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.lang = this.$store.getData(Constants.session.lang);
    this.username = user.username;
    this.userType = user.type;
    this.status = [];
    window.NotificationComponent = this;
    this.types = [];
  }
}
</script>

<style scoped>

</style>