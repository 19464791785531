import Constants from "@/utils/Constants";

const MessageUtils = {
    getAllMessageError(headers){
        const messages = [];
        if(!headers){
            return messages;
        }

        for(const v of headers){
            for(const s of v.messages){
                messages.push(s.msg_desc);
            }
        }

        return messages;
    },
    getFocusMessageError(headers){
        let messages = [];
        if(!headers){
            return messages;
        }

        for(const v of headers){
            messages = v.messages.filter(s=>s.action.toLowerCase()==Constants.response.header.action.focus);
        }

        return messages;
    },
    getPopupMessageError(headers){
        let messages = [];
        if(!headers){
            return messages;
        }

        for(const v of headers){
            messages = v.messages.filter(s=>s.action.toLowerCase()==Constants.response.header.action.popup);
        }

        return messages;
    },
    displayMessage(resp, alertModal, objValidate, cb){
        if(objValidate){
            for(const i in objValidate){
                objValidate[i] = '';
            }
        }
        const headers = resp.header;
        const focusMessages = this.getFocusMessageError(headers);
        const alertMessages = this.getPopupMessageError(headers);
        if(focusMessages.length>0&&objValidate){
            focusMessages.map(v=>{
                if(v.column){
                    objValidate[v.column.toLowerCase()] = v.msg_desc;
                }
            });
        }
        if(alertMessages.length>0&&alertModal){
            const messages = [];
            const messageType = [];
            const alertType = alertMessages[0].msg_type?alertMessages[0].msg_type.toLowerCase():'';
            alertMessages.forEach(v=>{messages.push(v.msg_desc)});
            alertMessages.forEach(v=>{messageType.push(v.msg_type.toLowerCase())});
            alertModal.show(messages, alertType, cb, '', messageType);
        }
    },
    resetValidate(error){
        for(const i in error){
            error[i] = '';
        }
    }
};

export default MessageUtils;