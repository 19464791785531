const EngNumberValidateOnlyDirective = {
    beforeMount(el) {
        el.addEventListener('keypress', (e) => {
            const charCode = (e.which) ? e.which : e.keyCode;
            const ch = String.fromCharCode(charCode);
            const regex = /^[A-Za-z0-9]+$/g;
            const flagActive = regex.test(ch);
            if (!flagActive) {
                e.preventDefault();
            } else {
                return true;
            }
        });
    }
};

export default EngNumberValidateOnlyDirective;