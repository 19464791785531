<template>
  <Teleport to="body">
  <div class="m-fund-first modal fade" :id="`sellFundFirst${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
      <div class="modal-content modal-condition form bg-main">

        <div class="modal-header">
          <h2 class="subtitle-medium d-flex align-items-center">

            <div class="line-left"></div><span class="head-hidden-m flex-grow-1">{{ $t('market.sell.head') }}</span>

            <a href="javascript:void(0)" class="kkp-toggle dropdown-toggle btn-toggle" type="button" :id="`dropdownMenuButton1-${id}`" @click="openUnitHolder">
              <p class="body2-medium float-end mb-0"><img src="@/assets/image/portfolio3.png" width="30" class="mx-auto">{{ curUnitHolder }} <img src="@/assets/image/more.png" class="mx-auto px-1"></p>
            </a>
            <a :id="`close-${id}`" href="javascript:void(0)" @click="close"><img src="@/assets/image/close.png" class="mx-auto px-2"></a>

          </h2>
        </div>

        <div class="modal-body p-0">
          <div class="acc-m-modal">
            <div class="row">
              <div class="col-12 col-sm-12">

                <div class="card-body" v-show="flagLoading">
                  <div class="row">
                    <div class="col-12">
                      <LoadingComponet :loading="flagLoading"/>
                    </div>
                  </div>
                </div>

                <div class="accordion accordion-flush kkp-accordion" :id="`accordionFlushExample-${id}`" v-show="!flagLoading">
                  
                  <div v-if="fundType.length>0">
                    <div class="accordion-item" v-for="(f, index) in fundType" :key="`f_${index}`" :id="`fst_${index}`">
                      <h2 class="accordion-header" :id="`flush-heading-sell-${index}`">
                        <button :id="`b-${id}`" :class="(index==0)?'accordion-button head-title':'accordion-button head-title collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="`#flush-collapse-sell-${index}`" :aria-expanded="(index==0)?'true':'false'" :aria-controls="`flush-collapse-sell-${index}`">
                          {{ (lang=='TH')?f.fund_class_name_TH:f.fund_class_name_en }}
                        </button>
                      </h2>
                      <div :id="`flush-collapse-sell-${index}`" :class="(index==0)?'accordion-collapse collapse show':'accordion-collapse collapse'" :aria-labelledby="`flush-heading-sell-${index}`" :data-bs-parent="`#accordionFlushExample-${id}`" style="">
                        <div class="accordion-body">
                          <div class="row g-3">

                            <div class="col-12 col-lg-6" v-for="(a, index2) in f.fund_list" :key="`f_${index}_${index2}`" :id="`fs_${index}_${index2}`">
                              <div class="card-kkp" type="button">
                                <div class="card-mutual m-0">
                                  <div class="row">
                                    <div class="col-8" @click="openPurchase(a.fund_no, a)">
                                      <h5 class="body-medium color-title m-0 wrap">{{ a.fund_code }}</h5>
                                      <p class="body2-regular hint-text mb-0">{{ (lang=='TH')?a.fund_name_TH:a.fund_name_EN }}</p>
                                    </div>
                                    <div class="col-4" @click="openPurchase(a.fund_no, a)">
                                      <h5 class="body-bold color-title mb-0 float-end">{{ (parseFloat(a.nav_price) > 0)?TransactionServices.unitFormat(a.nav_price):TransactionServices.unitFormat(0) }}</h5>
                                      <div class="clearfix"></div>

                                      <div v-if="a.nav_change_price > 0">
                                        <h5 class="success body2-regular float-end m-0"><img src="@/assets/image/up.png" class="mx-auto me-1">{{ TransactionServices.unitFormat(a.nav_change_price) }}</h5>
                                      </div>
                                      <div v-else-if="a.nav_change_price == 0">
                                        <h5 class="body2-regular float-end m-0">{{ TransactionServices.unitFormat(a.nav_change_price) }}</h5>
                                      </div>
                                      <div v-else>
                                        <h5 class="color-BE1E2D body2-regular float-end m-0"><img src="@/assets/image/down.png" class="mx-auto me-1">{{ TransactionServices.unitFormat((a.nav_change_price*-1)) }}</h5>
                                      </div>
                                      <div class="clearfix"></div>

                                      <h5 class="caption hint-text float-end ">{{ a.nav_date }}</h5>
                                      <div class="clearfix"></div>
                                    </div>
                                    <div class="tag-desktop col-12">
                                      <p class="float-start tag-link mb-0 mt-0 mx-1">Risk LV. <img :src="getRiskImage(a.fund_risk_level)" class="mx-auto"></p>
                                      <div class="wrap-free">
                                        <p class="float-start tag-link mb-0 mt-0 mx-1">{{ a.fund_class_type }}</p>
                                      </div>
                                      <a :href="a.fund_factsheet_link" target="_blank" v-if="a.fund_factsheet_link != null && a.fund_factsheet_link != ''">
                                        <p class="float-end tag-link btntitle text-white m-0">Prospectus <img src="@/assets/image/right.svg" class="mx-auto"></p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="content-box4 d-flex">
                      <div class="align-self-center text-center mx-auto">
                        <img src="@/assets/image/Not-Found.svg" class="mx-auto">
                        <p class="head-title ">ไม่มีรายการ</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer p-0" style=" border-radius: 0 0 16px 16px;">
          <footer class="footer footer-port mt-auto py-3 w-100">
          <div class="container-fluid">
            <div class="text-center">
               <p class="caption"><img src="@/assets/image/refresh1.png" class="mx-auto pe-1">{{ $t("market.sell.informationOf") }} {{ displayDate }}</p>
            </div>
          </div>
        </footer>
        </div>

      </div>
    </div>
  </div>

  <div class="modal fade logout" :id="`selectUID-${id}`" ref="selectUID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout p-0 bg-white" >
        <div class="modal-body p-0">

         
          <template v-for="(v,k,index) in unitHolders" :key="`uh_${index}`">
            <div class="mx-3 m-l-t8">
              <p class="fund-group extra-font13 hint-text">{{ getTitle(v[0].unitholder_type) }}</p>
              <div class="clearfix"></div>
            </div>

            <div v-for="(o,index) of v" :key="`select_uid_list_${index}`" type="button" @click="selectUnitHolder(o.unitholder_id)">
              <div :class="['fund-select', (o.unitholder_id==curUnitHolder)?'active':'']">
                <div class="head-slider d-flex">
                  <div>
                    <img :src="getImg(o.unitholder_type)" class="mx-auto float-start me-3"/>
                  </div>
                  <div>
                    <h5 class="subtitle-medium m-0">{{o.unitholder_name}} {{ o.agent_shortname?`(${o.agent_shortname})`:'' }}</h5>
                    <p class="float-start me-2 head-tag body2-regular purple"><img src="@/assets/image/book.png" class="mx-auto"> {{ o.unitholder_id }}</p>
                    <p class="float-start  head-tag body2-regular purple">Risk LV. <img :src="getRiskIPQImage(o.risk_level)" class="mx-auto"></p>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </template>

        </div>
      </div>
    </div>
  </div>

  <SellFundModalComponent ref="sellFund" @close="closeFormHandle"/>
  <AlertComponent ref="alertModal"/>
  </Teleport>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import Constants from "@/utils/Constants";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import LoadingComponet from "@/components/includes/LoadingComponet";
import SellFundModalComponent from "@/components/includes/page/market/sell/modal/SellFundModalComponent";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import TransactionServices from "@/services/TransactionServices";
import DateUtils from "@/utils/DateUtils";
import ShareData from "@/utils/ShareData";

export default {
  name: "SellFundComponent",
  components: {LoadingComponet, SellFundModalComponent, AlertComponent},
  data() {
    return {
      displayDate: '',
      TransactionServices: TransactionServices,
      id: StringUtils.getUUIDV4(),
      modal: null,
      modalSelectUID: null,
      item: {},
      lang: 'TH',
      username: '',
      fullname: '',
      unitHolders: [],
      curUnitHolder: '',
      flagLoading: false,
      fundType: {},
      tempFundType: {}
    }
  },
  methods: {
    async open(){
      this.curUnitHolder = ShareData.getUidWithPage(this);

      this.displayDate = DateUtils.currentStringFormat(DateUtils.addSecondsFromCurrent().toDate(), Constants.date.datetimeDisplay);
      this.modal.show();
      this.flagLoading = true;
      try{
        await this.getUnitHolderList();
        if(this.curUnitHolder!=''){
          await this.getFullname();
          await this.getFundType();
          await this.getCustValidFund();
        }
      } finally {
        this.flagLoading = false;
      }
    },

    close(){
      this.modal.hide();
    },

    async submit(){
      this.$emit('submit', this.item);
    },

    async getFullname(){
      const params = {username: this.username, data_name: 'client_fullname', request_page: 'dashboard'};
      const resp = await this.$HttpServices.postData("/customer/getSingleDataByUsername", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        if(result&&result.length>0){
          this.fullname = result[0].data_value;
        }
      }, null);
    },

    async getCustValidFund(){
      this.fundType = this.tempFundType;
      const params = {unitholder_id: this.curUnitHolder, trans_type_list: 'SELL,SELL(DCA)'};
      const resp = await this.$HttpServices.postData("/fund/GetCustValidFundListByTransType", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(let i=0; i<this.fundType.length; i++){
          this.fundType[i].fund_list = [];
          for(const v of result){
            if(v.fund_class_type){
              if(v.fund_class_type == this.fundType[i].fund_class_type){
                this.fundType[i].fund_list.push(v);
              }
            }
          }
        }

        let fundType = [];
        for(const vv of this.fundType){
          if(vv.fund_list.length > 0){
            fundType.push(vv);
          }
        }
        this.fundType = fundType;
      }, ()=>{
        this.fundType = [];
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    async getFundType(){
      const params = {username: this.username};
      const resp = await this.$HttpServices.postData("/fund/GetFundTypeList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        this.fundType = result;
        this.tempFundType = StringUtils.copyJSON(result);
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
    },

    async selectUnitHolder(unitHolders){
      if(this.curUnitHolder == unitHolders){
        this.modalSelectUID.hide();
      }
      else{
        ShareData.setUidWithPage(this, unitHolders);
        window.$(document).trigger("refresh_uid." + this.$route.name);
        this.curUnitHolder = unitHolders;
        this.flagLoading = true;
        try{
          this.modalSelectUID.hide();
          await this.getFundType();
          await this.getCustValidFund();
        } finally {
          this.flagLoading = false;
        }
      }
    },

    openPurchase(fund_no, obj){
      this.modal.hide();
      this.$refs.sellFund.open(fund_no, obj, this.curUnitHolder);
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    getRiskIPQImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/ipq${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    getImg(type){
      let img = '';
      try{
        if(type=='COA'){
          img = require(`@/assets/image/portfolio4.png`);
        }
        else{
          img = require(`@/assets/image/portfolio3.png`);
        }
      }catch(e){
        img = require(`@/assets/image/portfolio3.png`);
      }
      return img;
    },

    getTitle(type){
      const lang = this.$store.getData(Constants.session.lang);
      let title = '';
      if(type=='COA'){
        if(lang=='TH') title = this.$t('global.accountType2');
        else title = this.$t('global.accountType2');
      }
      else{
        if(lang=='TH') title = this.$t('global.accountType1');
        else title = this.$t('global.accountType1');
      }

      return title;
    },

    closeFormHandle(){
      this.modal.show();
    },

    openUnitHolder(){
      this.modalSelectUID.show();
    },

    async getUnitHolderList(){
      const params = {username: this.username};
      const resp = await this.$HttpServices.postData("/customer/GetUnitholderList", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        let obj = null;
        let i = 0;
        for(const v of result){
          if(i==0 && !this.curUnitHolder){
            this.curUnitHolder = v.unitholder_id;
          }
          i++;

          const key = v.unitholder_type;
          if(obj!=null&&obj[key]){
            const arr = obj[key];
            arr.push(StringUtils.copyJSON(v));
          }else{
            if(obj==null) obj = {};
            const arr = [];
            arr.push(StringUtils.copyJSON(v));
            obj[key] = arr;
          }
        }
        this.unitHolders = obj;
      }, null, null);
    }

  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);
    this.modal = new Modal(this.$refs.modal);
    this.modalSelectUID = new Modal(this.$refs.selectUID);
  }
}
</script>

<style scoped>

</style>