import Crypto from 'crypto-js';
import {useStorage} from "vue3-storage";
import Constants from "@/utils/Constants";
const storage = useStorage("kkpam_");

const encryptionToken = "KkP@M5dca1ed0-f9b5-4ffa-af1a-ce1526ac0875";

const store = {
    setData(key, value){
        const encryptData = Crypto.AES.encrypt(value, encryptionToken).toString();
        storage.setStorageSync(key, encryptData);
    },
    getData(key){
        const data = storage.getStorageSync(key);
        if(data){
            const bytes = Crypto.AES.decrypt(data, encryptionToken);
            return bytes.toString(Crypto.enc.Utf8);
        }
        return '';
    },
    setJsonData(key, value){
        try{
            value = JSON.stringify(value);
            const encryptData = Crypto.AES.encrypt(value, encryptionToken).toString();
            storage.setStorageSync(key, encryptData);
        }catch (err){
            console.error(err);
        }
    },
    getJsonData(key){
        try{
            const data = storage.getStorageSync(key);
            if(data){
                const bytes = Crypto.AES.decrypt(data, encryptionToken);
                return JSON.parse(bytes.toString(Crypto.enc.Utf8));
            }
            return null;
        }catch (err){
            console.error(err);
            return null;
        }
    },
    removeData(key){
        storage.removeStorageSync(key);
    },
    removeAllData(){
        this.removeData(Constants.session.token);
        this.removeData(Constants.session.popupLogin);
        this.removeData(Constants.session.termDoc);
        this.removeData(Constants.session.otherAddress);
        this.removeData(Constants.session.user);
        this.deleteAllCookies();
    },

    setCookie(key, value){
        // cookies.set(key, value);
        document.cookie = `${key}=${value}`;
    },
    getCookie(key){
        let obj = {};
        const cookie = document.cookie;
        const tmps = cookie.split(";");
        for(const o of tmps){
            const temps = o.split("=");
            if(temps.length>1){
                const key = temps[0].trim();
                const value = temps[1].trim();
                Object.assign(obj, {[key]: value});
            }
        }

        return obj[key];
    },
    deleteAllCookies() {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
}

export default store;