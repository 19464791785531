<template>

  <!-- Modal -->
  <div class="m-pwd modal fade logout" :id="`password${id}`" ref="oldPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout modal-medium">
        <div class="modal-body p-0">
          <div class="text-center">
            <center><img src="@/assets/image/password1.png" class="mx-auto mt-56 mb-48"></center>
            <h4 class="title-medium m-s-b16">{{ $t('passwordSetting.inputPassword') }}</h4>
            <p class="body2-regular description-text m-m-b32">{{ $t('passwordSetting.inputPasswordDetail') }}</p>
            <form class="m-b56" onsubmit="return false;">
              <div class="position-relative form-toggle-password">
                <input :id="`passwordInput${id}`" :type="passwordType" class="form-control body-regular icon icon-password" placeholder="*********" v-model="item.password" v-on:keyup.enter="verifyPasswordHandle" :style="[(validate.password||apiError.password)?{border: '2px solid red'}:'']">
                <div class="toggle-password" @click="togglePassword">
                    <img data-status-icon="close" :src="imgEye">
                </div>
              </div>
              <div class="va hvr-float label-required" v-if="validate.password">
                <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.passwordRequired') }}
              </div>
              <div class="va hvr-float px-2 label-required" v-if="apiError.password">
                <img src="@/assets/image/Information2.png" class="mx-auto me-2">  <span v-html="apiError.password?apiError.password:''"></span>
              </div>
            </form>

            <div class="row g-3">
              <div class="col-12 col-sm-6 order-lg-1 order-md-1 order-sm-1 order-2">
                <button :id="`b1${id}`" type="button" data-bs-dismiss="modal" class="btn w-100 btn-sec hvr-float">
                  {{ $t('global.cancel') }}
                </button>
              </div>
              <div class="col-12 col-sm-6 order-lg-2 order-md-2 order-sm-2 order-1">
                <button :id="`b2${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="verifyPasswordHandle">
                  {{ $t('global.confirm') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade logout" :id="`setpassword${id}`" ref="newPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout">
        <div class="modal-body p-0">
          <div class="text-center">
            <center><img src="@/assets/image/password1.png" class="mx-auto mb-4"></center>
            <h4 class="title-medium m-s-b16">{{ $t('passwordSetting.setYourPassword') }}</h4>
            <p class="body2-regular description-text m-m-b32">{{ $t('passwordSetting.setYourPasswordDetail') }}</p>

            <form>
              <div class="text-start mb-3">
                <label class="m-ss-b8 body2-regular">{{ $t('passwordSetting.newPassword') }}</label>
                <div class="position-relative form-toggle-password">
                  <input :type="newPasswordType" class="form-control body-regular icon icon-password" placeholder="*********" v-model="item.newPassword" v-on:keyup.enter="changePasswordHandle" :style="[(validate.newPasswordRequired||validate.newPasswordWrongFormat)?{border: '2px solid red'}:'']">

                  <div class="toggle-password" @click="toggleNewPassword">
                      <img data-status-icon="close" :src="imgEyeNP">
                  </div>

                  <p class="float-start kkp-color-01 m-0"><img src="@/assets/image/Information.png" class="mx-auto me-1"> <small>{{ $t('global.validate.passwordFormat') }}</small></p>
                </div>
                <div class="clearfix"></div>
                <div class="va hvr-float label-required" v-if="validate.newPasswordRequired">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.newPasswordRequired') }}
                </div>
                <div class="va hvr-float label-required" v-if="validate.newPasswordWrongFormat">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.passwordWrongFormat') }}
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="text-start mb-3">
                <label class=" m-ss-b8 body2-regular ">{{ $t('passwordSetting.confirmPassword') }}</label>
                <div class="position-relative form-toggle-password">
                  <input :type="confirmPasswordType" class="form-control body-regular icon icon-password" placeholder="*********" v-model="item.confirmPassword" v-on:keyup.enter="changePasswordHandle" :style="[(validate.confirmPasswordRequired||validate.passwordNotMatch||validate.confirmPasswordWrongFormat||apiError.password)?{border: '2px solid red'}:'']">
                  <div class="toggle-password" @click="toggleConfirmPassword">
                      <img data-status-icon="close" :src="imgEyeCP">
                  </div>
                </div>
                <div class="va hvr-float label-required" v-if="validate.confirmPasswordRequired">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.confirmPasswordRequired') }}
                </div>
                <div class="va hvr-float label-required" v-if="validate.passwordNotMatch">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.passwordNotMatch') }}
                </div>
                <div class="va hvr-float label-required" v-if="validate.confirmPasswordWrongFormat">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.confirmPasswordWrongFormat') }}
                </div>
                <div class="va hvr-float label-required" v-if="apiError.password">
                  <img src="@/assets/image/Information2.png" class="mx-auto me-2">  <span v-html="apiError.password?apiError.password:''"></span>
                </div>
              </div>
            </form>

            <div class="row mt-4 g-3">
              <div class="col-12 col-sm-6 order-lg-1 order-md-1 order-sm-1 order-2">
                <button type="button" data-bs-dismiss="modal" class="btn w-100 btn-sec hvr-float">
                  {{ $t('global.cancel') }}
                </button>
              </div>
              <div class="col-12 col-sm-6 order-lg-2 order-md-2 order-sm-2 order-1">
                <button type="button" class="btn w-100 btn-main hvr-float" @click="changePasswordHandle">
                  {{ $t('global.confirm') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO @translate -->
  <!-- Modal -->
  <div class="modal fade logout" :id="`savepassword${id}`" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout">
        <div class="modal-body p-0">
          <div class="text-center">
            <center><img src="@/assets/image/completed1.png" class="mx-auto mb-4"></center>
            <h4 class="title-medium m-s-b16">ดำเนินการเรียบร้อย</h4>
            <p class="body2-regular description-text m-m-b32">คุณเปลี่ยนรหัสผ่านสำหรับ เข้าใช้งาน KKPAM Online เรียบร้อยแล้ว</p>
          </div>

          <div class="row mt-4 g-3">
            <div class="col-12 col-sm-12">
              <button type="button" data-bs-dismiss="modal" class="btn w-100 btn-main hvr-float mt-2">ปิด</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AlertComponent ref="alertModal"/>
</template>

<script>
import { Modal } from 'bootstrap';
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";

export default {
  name: "PasswordSettingComponent",
  components: {AlertComponent},
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      username: '',
      modal: {},
      item: {},
      validate: {},
      apiError: {},
      flagLoading: false,
      passwordType: 'password',
      newPasswordType: 'password',
      confirmPasswordType: 'password',
      imgEye: require('@/assets/image/close.svg'),
      imgEyeNP: require('@/assets/image/close.svg'),
      imgEyeCP: require('@/assets/image/close.svg'),
    }
  },
  methods: {
    openModal(){
      this.initData();
      this.modal.oldPasswordModal.show();
    },
    async verifyPasswordHandle(){
      if(!this.checkValidateVerifyPassword()){
        return;
      }
      this.flagLoading = true;
      const params = {username: this.username, validated_field: 'password', validated_value: this.item.password};
      const resp = await this.$HttpServices.postData("/customer/ValidateDataByUsername", params);
      this.item.password = '';
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        this.validate = {};
        this.apiError = {};
        this.item.newPassword = '';
        this.item.confirmPassword = '';
        this.modal.oldPasswordModal.hide();
        this.modal.newPasswordModal.show();
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
      this.flagLoading = false;
    },
    async changePasswordHandle(){
      if(!this.checkValidateConfirmPassword()){
        return;
      }
      this.flagLoading = true;
      const params = {username: this.username, password: this.item.confirmPassword};
      const resp = await this.$HttpServices.postData("/customer/CreateNewPassword", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        this.modal.newPasswordModal.hide();
        this.$refs.alertModal.show([this.$t('passwordSetting.alert.successDetail')], 'success', null, this.$t('passwordSetting.alert.successHeader'));
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
      this.flagLoading = false;
    },

    /****** common ******/
    checkValidateVerifyPassword(){
      let flagRequired = true;
      this.validate = {};
      if(!this.item.password){
        this.validate.password = true;
        flagRequired = false;
      }

      return flagRequired;
    },
    checkValidateConfirmPassword(){
      let flagRequired = true;
      this.validate = {};
      if(!this.item.newPassword){
        this.validate.newPasswordRequired = true;
        flagRequired = false;
      }
      if(!this.item.confirmPassword){
        this.validate.confirmPasswordRequired = true;
        flagRequired = false;
      }
      if(this.item.newPassword!=this.item.confirmPassword){
        this.validate.passwordNotMatch = true;
        flagRequired = false;
      }
      if(!ValidateUtils.validatePassword(this.item.newPassword)){
        this.validate.newPasswordWrongFormat = true;
        flagRequired = false;
      }
      if(!ValidateUtils.validatePassword(this.item.confirmPassword)){
        this.validate.confirmPasswordWrongFormat = true;
        flagRequired = false;
      }

      return flagRequired;
    },
    initData(){
      this.item = {
        password: '',
        newPassword: '',
        confirmPassword: ''
      };
      this.validate = {};
      this.apiError = {};
    },
    togglePassword(){
      if(this.passwordType == 'password'){
        this.passwordType = 'text';
        this.imgEye = require('@/assets/image/open.svg');
      }
      else{
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
      }
    },
    toggleNewPassword(){
      if(this.newPasswordType == 'password'){
        this.newPasswordType = 'text';
        this.imgEyeNP = require('@/assets/image/open.svg');
      }
      else{
        this.newPasswordType = 'password';
        this.imgEyeNP = require('@/assets/image/close.svg');
      }
    },
    toggleConfirmPassword(){
      if(this.confirmPasswordType == 'password'){
        this.confirmPasswordType = 'text';
        this.imgEyeCP = require('@/assets/image/open.svg');
      }
      else{
        this.confirmPasswordType = 'password';
        this.imgEyeCP = require('@/assets/image/close.svg');
      }
    },
    /****** common ******/
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    this.modal.oldPasswordModal = new Modal(this.$refs.oldPasswordModal);
    this.modal.newPasswordModal = new Modal(this.$refs.newPasswordModal);
  }
}
</script>

<style scoped>

</style>