import axios from "axios";
import Constants from "@/utils/Constants";
import store from "@/utils/store";
import DateUtils from "@/utils/DateUtils";
import SignatureServices from "@/services/SignatureServices";
import StringUtils from "@/utils/StringUtils";
import Auth from "@/middleware/auth";

const HttpServices = {
    async getRestData(url){
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            let apiUrl = Constants.global.apiUrl.replaceAll("/api", "/rest");
            axios.get(apiUrl + url, { headers: { "Content-Type": "application/json" } }).then(async res => {
                const data = res.data;
                resolve(data);
            }).catch(res => {
                console.error(res);
                reject(false);
            });
        });
    },
    async postRestData(url, params){
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            let apiUrl = Constants.global.apiUrl.replaceAll("/api", "/rest");
            await axios.post(apiUrl + url, params, { headers: { "Content-Type": "application/json" } }).then(async res => {
                const data = res.data;
                resolve(data);
            }).catch(res => {
                console.error(res);
                reject(false);
            });
        });
    },
    async getData(url){
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            await this.assignParams([]);
            const headers = await this.getHeader();
            axios.get(Constants.global.apiUrl + url, headers).then(async res => {
                const data = res.data;
                resolve(data);
            }).catch(res => {
                console.error(res);
                reject(false);
            });
        });
    },
    async postData(url, params, recursive = false) {
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            if(recursive === false) {
                params = await this.assignParams(params);
            }
            let headers = await this.getHeader();
            await axios.post(Constants.global.apiUrl + url, params, headers).then(async res => {
                const data = res.data;
                if (data && data.errorCode == "401" || this.validateExpired(data)) {
                    if(typeof recursive == "number") {
                        recursive = recursive - 1;
                    } else {
                        recursive = Constants.reSendApi;
                    }
                    if(recursive > 0) {
                        params = await this.assignParams(params, true);
                        const data = await this.postData(url, params, recursive);
                        resolve(data);
                    } else {
                        await Auth.redirectAuthRouteMiddleware();
                        reject(false);
                    }
                    return;
                }else{
                    resolve(data);
                }
            }).catch(res => {
                console.error(res);
                // AlertUtils.alert("error", `${res}`);
                reject(false);
            });
        });
    },
    async postDataForDownload(url, params, recursive = false) {
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            if(recursive === false) {
                params = await this.assignParams(params);
            }
            let headers = await this.getHeaderWithResponseType();
            await axios.post(Constants.global.apiUrl + url, params, headers).then(async res => {
                const data = res.data;
                if (data && data.errorCode == "401" || this.validateExpired(data)) {
                    if(typeof recursive == "number") {
                        recursive = recursive - 1;
                    } else {
                        recursive = Constants.reSendApi;
                    }
                    if(recursive > 0) {
                        params = await this.assignParams(params, true);
                        const data = await this.postDataForDownload(url, params, recursive);
                        resolve(data);
                    } else {
                        await Auth.redirectAuthRouteMiddleware();
                        reject(false);
                    }
                    return;
                }else{
                    resolve(data);
                }
            }).catch(res => {
                console.error(res);
                // AlertUtils.alert("error", `${res}`);
                reject(false);
            });
        });
    },
    async putData(url, params) {
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            params = await this.assignParams(params);
            const headers = await this.getHeader();
            // SpinnerService.showLoader();
            await axios.put(Constants.global.apiUrl + url, params, headers).then(async res => {
                const data = res.data;
                resolve(data);
            }).catch(res => {
                console.error(res);
                // AlertUtils.alert("error", `${res}`);
                reject(false);
            });
        });
    },
    async deleteData(url) {
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            await this.assignParams([]);
            const headers = await this.getHeader();
            await axios.delete(Constants.global.apiUrl + url, headers).then(async res => {
                const data = res.data;
                resolve(data);
            }).catch(res => {
                console.error(res);
                // AlertUtils.alert("error", `${res}`);
                reject(false);
            });
        });
    },
    async postGetTokenData(url, params) {
        // eslint-disable-next-line no-async-promise-executor
        return await new Promise(async (resolve, reject) => {
            const headers = await this.getHeader();
            await axios.post(Constants.global.apiUrl + url, params, headers).then(async res => {
                const data = res.data;
                resolve(data);
            }).catch(res => {
                console.error(res);
                // AlertUtils.alert("error", `${res}`);
                reject(false);
            });
        });
    },

    async getHeader(url = "") {
        let tokenObj = store.getJsonData(Constants.session.token);
        let token = (tokenObj && tokenObj?.token)? tokenObj.token: '';

        const headers = {
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": token,
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
                "X-Frame-Options": "deny",
                "X-Content-Type-Options": "nosniff",
                "Content-Security-Policy": "n/a",
                "Referrer-Policy": "no-referrer",
                "Cross-Origin-Embedder-Policy": "require-corp",
                "Cross-Origin-Opener-Policy": "same-origin",
                "Cross-Origin-Resource-Policy": "same-origin",
                "Permissions-Policy": "n/a",
                "Cache-Control": "no-store, max-age=0",
           }
        };

        if(url == "/customer/Logout") {
            headers["headers"]["Clear-Site-Data"] = "cache, cookies, storage";
        }

        return headers;
    },

    async getHeaderWithResponseType() {
        let tokenObj = store.getJsonData(Constants.session.token);
        // if(tokenObj){
        //     const expiryDate = tokenObj.expiry_datetime;
        //     if(DateUtils.checkCurrentAfter(expiryDate, Constants.date.expiryDatetime)){
        //         await SignatureServices.generateToken();
        //         tokenObj = store.getJsonData(Constants.session.token);
        //     }
        // }else{
        //     await SignatureServices.generateToken();
        //     tokenObj = store.getJsonData(Constants.session.token);
        // }
        let token = tokenObj?tokenObj.token:'';

        const headers = { responseType: 'arraybuffer', headers: { "Content-Type": "application/json", "X-Auth-Token": token } };
        return headers;
    },

    validateExpired(data){
        if(data&&data.header&&data.header.length>0){
            const header = data.header[0];
            if(header.messages&&header.messages.length>0){
                // header.messages[0].msg_code=='30025'||
                if(header.messages[0].msg_code=='30038'){
                    return true;
                }
            }
        }
        return false;
    },

    // async assignParams(params){
    //     params = Object.assign(params, {lang: store.getData(Constants.session.lang)});
    //     params = Object.assign(params, {client_ip_addr: store.getData(Constants.session.clientIp)});
    //     const user = store.getJsonData(Constants.session.user);
    //     if(user&&user.type==Constants.userType.user){
    //         params = Object.assign(params, {username: user.username});
    //     }else if(user&&user.type==Constants.userType.guest){
    //         params = Object.assign(params, {unitholder_id: user.unitholder});
    //     }
    //     return params;
    // }


    async assignParams(params, refresh_token = false){
        const obj = {};
        if(params.username){
            obj.username = params.username;
        }
        if(params.idcard){
            obj.idcard = params.idcard;
        }
        if(params.unitholder_id){
            obj.unitholder_id = params.unitholder_id;
        }

        let loginPage = Constants.global.apiGuestLoginPage;
        let sessionGuestCurrent = false;
        const user = store.getJsonData(Constants.session.user);
        let guestUser = store.getJsonData(Constants.session.guestUser);
        if(user&&user.type==Constants.userType.user){
            params = Object.assign(params, {username: user.username});
            loginPage = Constants.global.apiMemberLoginPage;
            store.removeData(Constants.session.guestUser);
        } else if(user&&user.type==Constants.userType.guest){
            params = Object.assign(params, {unitholder_id: user.unitholder});
            if(user) {
                if(
                    (
                        !user?.username || user?.username  == loginPage
                        || !obj?.username || obj?.username == loginPage
                        || user?.username == obj?.username
                    )
                    && (
                        !user?.idCard || user?.idCard == loginPage
                        || !obj?.idcard || obj?.idcard == loginPage
                        || user?.idCard == obj?.idcard
                    )
                    && (
                        !user?.unitholder || user?.unitholder == loginPage
                        || !obj?.unitholder_id || obj?.unitholder_id == loginPage
                        || user?.unitholder == obj?.unitholder_id
                    )
                ) {
                    sessionGuestCurrent = true;
                }
            }
            store.removeData(Constants.session.guestUser);
        } else {
            if(guestUser) {
                if(guestUser?.idCard == obj?.idcard) {
                    sessionGuestCurrent = true;
                }

                if(guestUser?.idCard != obj?.idcard) {
                    guestUser = {
                        idCard: obj.idcard,
                        username: obj.username
                    }

                    store.setJsonData(Constants.session.guestUser, guestUser);
                } else if(obj?.username && obj?.username != loginPage) {
                    guestUser.username = obj.username
                    store.setJsonData(Constants.session.guestUser, guestUser);
                }
            } else {
                store.setJsonData(Constants.session.guestUser,  {
                    idCard: obj.idcard,
                    username: obj.username
                });
            }
        }

        if(loginPage == Constants.global.apiGuestLoginPage){
            if(sessionGuestCurrent) {
                let tokenObj = store.getJsonData(Constants.session.token);
                if(tokenObj){
                    const expiryDate = tokenObj.expiry_datetime;
                    if(refresh_token || DateUtils.checkCurrentAfter(expiryDate, Constants.date.expiryDatetime)){
                        if(guestUser) {
                            obj.idcard = guestUser.idCard;
                            obj.username = guestUser.username;
                        }
                        await SignatureServices.generateToken(obj.username||loginPage, obj.unitholder_id||loginPage, obj.idcard||loginPage, true);
                    }
                }else{
                    if(guestUser) {
                        obj.idcard = guestUser.idcard;
                    }
                    await SignatureServices.generateToken(obj.username||loginPage, obj.unitholder_id||loginPage, obj.idcard||loginPage, true);
                }
            } else {
                if(
                    (!obj.username || obj.username == loginPage)
                    && (!obj.unitholder_id || obj.unitholder_id == loginPage)
                    && (!obj.idcard || obj.idcard == loginPage)

                ) {
                    obj.unitholder_id = StringUtils.randomString(20);
                }

                await SignatureServices.generateToken(obj.username||loginPage, obj.unitholder_id||loginPage, obj.idcard||loginPage, true);
            }
        } else {
            let tokenObj = store.getJsonData(Constants.session.token);
            if(tokenObj){
                const expiryDate = tokenObj.expiry_datetime;
                if(refresh_token || DateUtils.checkCurrentAfter(expiryDate, Constants.date.expiryDatetime)){
                    await SignatureServices.generateToken();
                }
            }else{
                await SignatureServices.generateToken();
            }
        }

        params = Object.assign(params, {lang: store.getData(Constants.session.lang)});
        params = Object.assign(params, {client_ip_addr: store.getData(Constants.session.clientIp)});

        return params;
    }
};

export default HttpServices;