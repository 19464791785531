<template>
  <Teleport to="body">
    <div class="m-alert modal fade logout" :id="`alertModal${id}`" ref="alertModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="z-index='9999'">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-logout">
          <div class="modal-body">
            <div class="text-center">
              <img src="@/assets/image/completed.png" class="mx-auto mb-4" v-if="alertType=='success'">
              <img src="@/assets/image/x1.png" class="mx-auto mb-4" v-if="alertType=='error'">
              <img src="@/assets/image/warning-status.png" class="mx-auto mb-4" v-if="alertType=='warning'">
              <h2 v-show="headerMessage">{{ headerMessage }}</h2>
              <!-- <p v-for="(error, index) in messages" :key="`listAlertMessage${index}`" v-html="displayMessageHandle(error)"></p> -->
              <p v-html="displayMessageHandle(messages[num])"></p>

              <div class="clearfix"></div>
              <div class="row mt-4 g-2">
                <div class="col-12 col-sm-12">
                  <button :id="`alertModalClose${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="hide">{{ buttonText }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';
import StringUtils from "@/utils/StringUtils";
export default {
  name: "AlertComponent",
  data() {
    return {
      id: StringUtils.getUUIDV4(),
      alertType: 'error',
      headerMessage: '',
      messages: [],
      alert: null,
      cb: null,
      num: 0,
      messageType: [],
      buttonText: 'ปิด'
    }
  },
  methods: {
    show(messages, alertType, cb, headerMessage, messageType=[], buttonText=""){
      this.messages = messages;
      this.cb = cb;
      this.headerMessage = headerMessage;
      if(headerMessage == "คำเตือน" || headerMessage == "Warning") {
        this.alertType = 'warning';
      } else {
        this.alertType = alertType;
      }
      this.num = 0;
      this.messageType = messageType;
      if(buttonText!="") this.buttonText = buttonText;
      this.alert.show();

      const vm = this;
      document.getElementById(`alertModal${this.id}`).addEventListener("hidden.bs.modal", ()=>{
        vm.checkHide();
      });
    },
    showCallback(messages, alertType, cb, headerMessage){
      this.messages = messages;
      this.headerMessage = headerMessage;
      if(headerMessage == "คำเตือน" || headerMessage == "Warning") {
        this.alertType = 'warning';
      } else {
        this.alertType = alertType;
      }
      this.num = 0;
      if(cb){
        cb();
      }
      this.alert.show();
    },
    hide(){
      if(this.num == this.messages.length - 1){
        if(this.cb){
          this.cb();
        }
      }
      this.alert.hide();
    },
    checkHide(){
      if(this.num < this.messages.length - 1){
        this.num++;
        this.alertType = this.messageType[this.num];
        this.alert.show();
      }
    },
    displayMessageHandle(message){
      if(!message) message = '';
      const brExp = /<br\s*\/?>/i;
      const tmps = message.split(brExp);
      let resp = '';
      if(tmps.length>1){
        let msg = '';
        for(let i=1;i<tmps.length;i++){
          if(msg){
            msg += '<br/>';
          }
          msg += `${tmps[i]}`;
        }
        resp = `<h2>${tmps[0]}</h2>${msg}`;
      }else{
        resp = tmps&&tmps.length>0?tmps[0]:tmps;
      }

      return resp;
    }
  },
  mounted() {
    this.alert = new Modal(this.$refs.alertModal);
  }
}
</script>

<style scoped>
.modal {
  z-index: 9999 !important;
}
</style>