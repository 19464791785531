const NavCtrl = {
    closeAllNavHandle(){
        const ele = document.getElementsByClassName("actionsidebar")
        for(const v of ele){
            v.style.width = "0";
        }
        document.getElementById("nosrcoll").style.overflow = "inherit";
    },
    openLeftNavHandle() {
        this.closeAllNavHandle();
        document.getElementById("sideNavLeft").style.width = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
    },
    openRightNavHandle() {
        this.closeAllNavHandle();
        document.getElementById("sideNavRight").style["width"] = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
    },
    openNotiNavHandle() {
        this.closeAllNavHandle();
        document.getElementById("notificationNav").style.width = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
        window.NotificationComponent.openTab();
    },
    openSearchHandle(searchModal){
        this.closeAllNavHandle();
        if(searchModal){
            searchModal.open();
        }
    },
    openDcaNavHandle() {
        this.closeAllNavHandle();
        document.getElementById("dcaListSidebar").style.width = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
        window.DCAListComponent.openTab();
    },
    openPendingNavHandle() {
        this.closeAllNavHandle();
        document.getElementById("pendingListSidebar").style.width = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
        window.PendingListComponent.openTab();
    },
    openUserAccountNavHandle(ref) {
        ref.initialData();
        this.closeAllNavHandle();
        document.getElementById("userAccountSidebar").style.width = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
    },
    openSellFund(sellFundModal){
        this.closeAllNavHandle();
        if(sellFundModal){
            sellFundModal.open();
        }
    },
    openSwitchFund(switchFundModal){
        this.closeAllNavHandle();
        if(switchFundModal){
            switchFundModal.open();
        }
    },

    openFilterEnquiryNavHandle(){
        this.closeAllNavHandle();
        document.getElementById("sideNavFilter").style.width = "100%";
        document.getElementById("nosrcoll").style.overflow = "hidden";
    }
};

export default NavCtrl;