import Auth from '../middleware/auth';
import MainLayout from "@/components/layouts/MainLayout";
import AuthLayout from "@/components/layouts/AuthLayout";
import NotFoundPage from "@/components/pages/NotFoundPage";
import Constants from "@/utils/Constants";

let childrenAuth = [];
let children = [];
const ChildAuthKey = ["DashboardPage", "FormConsentPage", "PortfolioPage", "MarketBuyFundPage", "DownloadDocPage", "EnquiryPage"];
const childKey = ["AuthenPage", "RegisterPage", "ForgetPasswordPage", "GuestPage", "guestNoUHD"]

const componentInfo = {
    "DashboardPage": {path: Constants.basePath + 'dashboard',name: 'DashboardPage',component: () => import('../components/pages/DashboardPage.vue'), meta: {middleware: Auth.authMiddleware}},
    "FormConsentPage": {path: Constants.basePath + 'form-consent',name: 'FormConsentPage',component: () => import('../components/pages/formConsent/FormConsentPage.vue'), meta: {middleware: Auth.authMiddleware}},
    "PortfolioPage": {path: Constants.basePath + 'portfolio',name: 'PortfolioPage',component: () => import('../components/pages/portfolio/PortfolioPage.vue'), meta: {middleware: Auth.authMiddleware}},
    "MarketBuyFundPage": {path: Constants.basePath + 'buy-fund',name: 'MarketBuyFundPage',component: () => import('../components/pages/market/MarketBuyFundPage.vue'), meta: {middleware: Auth.authMiddleware}},
    "DownloadDocPage": {path: Constants.basePath + 'download-doc',name: 'DownloadDocPage',component: () => import('../components/pages/downloadDoc/DownloadDocPage.vue'), meta: {middleware: Auth.authMiddleware}},
    "EnquiryPage": {path: Constants.basePath + 'enquiry',name: 'EnquiryPage',component: () => import('../components/pages/enquiry/EnquiryPage.vue'), meta: {middleware: Auth.authMiddleware}},
    "404": {path: Constants.basePath + '*', component: import('../components/pages/NotFoundPage.vue')},
    "AuthenPage": {path: Constants.basePath + 'auth',name: 'AuthenPage',component: () => import('../components/pages/auth/AuthenPage.vue')},
    "RegisterPage": {path: Constants.basePath + 'register',name: 'RegisterPage',component: () => import('../components/pages/auth/RegisterPage.vue'), meta: {middleware: Auth.authLoginMiddleware}},
    "ForgetPasswordPage": {path: Constants.basePath + 'forget-password',name: 'ForgetPasswordPage',component: () => import('../components/pages/auth/ForgetPasswordPage.vue'), meta: {middleware: Auth.authLoginMiddleware}},
    "GuestPage": {path: Constants.basePath + 'guest',name: 'GuestPage',component: () => import('../components/pages/auth/GuestPage.vue')},
    "guestNoUHD": {path: Constants.basePath + 'guest-nouhd',name: 'guestNoUHD',component: () => import('../components/pages/auth/guestNoUHD.vue')}
}

ChildAuthKey.forEach(element => {
    if(Constants.allowPages[element]) {
        childrenAuth.push(componentInfo[element]);
    }
});

childKey.forEach(element => {
    if(Constants.allowPages[element]) {
        children.push(componentInfo[element]);
    }
});

let redirectAuth = Constants.basePath + 'dashboard';
let redirect = Constants.basePath + 'auth';
if(!Constants.allowPages.AuthenPage) {
    redirectAuth = Constants.basePath + 'form-consent';
    redirect = Constants.basePath + 'guest';
}

const routes = [
    {
        path: Constants.basePath,
        redirect: redirectAuth,
        component: MainLayout,
        meta: {
            middleware: Auth.authMiddleware
        },
        children: childrenAuth
    },
    {
        path: Constants.basePath,
        redirect: redirect,
        component: AuthLayout,
        children: children
    },
    {path: Constants.basePath + 'landing',name: 'LandingPage',component: () => import('../components/pages/LandingPage.vue'), meta: {middleware: Auth.authMiddleware}},
    {path: Constants.basePath + ':pathMatch(.*)*', component: NotFoundPage }
];

export default routes;
