<template>
  <div v-if="multiLang" class="modal modal-language fade logout" :id="`switchLangModal${id}`" ref="switchLangModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout p-0">
        <div class="modal-body p-0">
          <ul class="p-0">
            <li><center>{{ $t('lang.pleaseSelectLang') }}</center></li>
            <li :id="`lang1${id}`" :class="[ ( $i18n.locale === 'th' )? 'active' : '' ]" @click="changeLangHandle('TH')" :disabled="flagLoading">
              <center>
                  {{ $t('lang.th') }}
              </center>
            </li>
            <li :id="`lang2${id}`" :class="[ ( $i18n.locale === 'en' )? 'active' : '' ]" @click="changeLangHandle('EN')" :disabled="flagLoading">
              <center>
                  {{ $t('lang.en') }}
              </center>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import StringUtils from "@/utils/StringUtils";
import Constants from "@/utils/Constants";
export default {
  name: "SwitchLangComponent",
  data() {
    return {
      multiLang: Constants.mutiLang,
      id: StringUtils.getUUIDV4(),
      modal: null,
      flagLoading: false
    }
  },
  methods: {
    show(){
      if(!this.multiLang) {
        return false;
      }
      this.modal.show();
    },
    hide(){
      if(!this.multiLang) {
        return false;
      }
      this.modal.hide();
    },
    async changeLangHandle(lang){
      this.flagLoading = true;
      setTimeout(async ()=>{
        this.$store.setData(Constants.session.lang, lang);
        this.$root.$i18n.locale = lang.toLowerCase();
        const resp = await this.$HttpServices.getRestData(`/utils/lang/${lang.toLowerCase()}`);
        this.$root.$i18n.setLocaleMessage(lang.toLowerCase(), resp);
        this.$emit('lang', lang);
        this.flagLoading = false;
        this.hide();
      }, 150);
    }
  },
  mounted() {
    if(!this.multiLang) {
      return false;
    }
    this.modal = new Modal(this.$refs.switchLangModal);
  }
}
</script>

<style scoped>

</style>