import StringUtils from "@/utils/StringUtils";
import Constants from "@/utils/Constants";
import store from "@/utils/store";

const TransactionServices = {
    getStatus(statuss, status){
        let resp = '';
        const obj = statuss.find(v=>v.data_map==status);
        if(obj){
            resp = obj.data_value;
        }
        return resp;
    },
    getTransactionType(types, type){
        let resp = '';
        const obj = types.find(v=>v.data_map==type);
        if(obj){
            resp = obj.data_value;
        }
        return resp;
    },
    getDetail(o){
        const lang = store.getData(Constants.session.lang);
        let resp = o.fund_name_TH;
        if(lang=='EN'){
            resp = o.fund_name_EN;
        }

        return resp;
    },
    convertTransAmount(o){
        let amount = 0;
        if(o.unit=='Units'){
            amount = o.trans_unit;
            amount = (''+amount).replaceAll('-', '');
            return this.unitFormat(amount);
        }else if(o.unit=='THB'){
            amount = o.trans_amt;
            amount = (''+amount).replaceAll('-', '');
            return StringUtils.decimalFormat(amount);
        }
    },
    getImageUrl(image){
        let src = '';
        try{
            src = require(`@/assets/image/${image}`);
        }catch(e){
            src = '';
        }
        return src;
    },
    displayDate(dateStr){
        if(!dateStr){
            return '';
        }
        const tmps = dateStr.split('/');
        if(tmps.length>0){
            return tmps[0];
        }
        return '';
    },
    displayMonthYear(dateStr){
        if(!dateStr){
            return '';
        }
        const tmps = dateStr.split('/');
        if(tmps.length>2){
            let m = parseInt(tmps[1]);
            m = m<10?"0"+m:m;
            return m+"/"+tmps[2];
        }
        return '';
    },
    validateTypeTransfer(type){
        const obj = Constants.transaction.typeTranfers.find(v=>v.data_map==type);
        return !obj;
    },
    unitFormat(value){
        let str = '';
        str = parseFloat(value).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return str;
    },
    decimalFormat(value){
        return StringUtils.decimalFormat(value);
    }
};

export default TransactionServices;