<template>
  <!-- @rewrite-html
  <nav class="navbar navbar-expand-lg navbar-light bg-light" v-if="$route.name!='EnquiryPage'">
    <div class="container">
      <a id="aTag1" class="navbar-brand" href="javascript:void(0)" @click="NavCtrl.openLeftNavHandle()">
        <img src="@/assets/image/menu.png" class="mx-auto">
      </a>
      <a id="aTag2" class="navbar-brand" href="javascript:void(0)">
        <img src="@/assets/image/logo-p.png" class="mx-auto" width="120">
      </a>
      <div class="navbar-brand collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <div class="d-flex p-1 float-start" v-if="validateUserType()">
          <a href="javascript:void(0)" @click="NavCtrl.openSearchHandle($refs.searchModal)"><img src="@/assets/image/search.png" class="mx-auto"></a>
        </div>
        <div class="n-norti d-flex p-1 float-start" v-if="validateUserType()">
          <a href="javascript:void(0)" @click="NavCtrl.openNotiNavHandle();">
            <span class="n-number" :style="[(notiCount==0)?{display: 'none'}:'']">{{ notiCount }}</span>
            <img src="@/assets/image/bell.png" class="mx-auto">
          </a>
        </div>
        <div class="d-flex p-1 float-start">
          <a href="javascript:void(0)" @click="NavCtrl.openRightNavHandle()"><img :src="profilePic" class="mx-auto top-avatar"></a>
        </div>
      </div>
    </div>
  </nav>
  -->

  <nav class="navbar navbar-expand-lg navbar-light bg-light p-ss" v-if="$route.name!='EnquiryPage'">
    <div class="container">
      <a id="aTag1" class="navbar-brand" href="javascript:void(0)" @click="NavCtrl.openLeftNavHandle()">
        <img src="@/assets/image/menu.png" class="mx-auto">
      </a>
      <a id="aTag2" class="navbar-brand" href="javascript:void(0)">
        <img src="@/assets/image/logo-p.png" class="mx-auto" width="120">
      </a>
      <div class="navbar-brand collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <div class="d-flex p-1 float-start" v-if="validateUserType()">
          <a href="javascript:void(0)" @click="NavCtrl.openSearchHandle($refs.searchModal)"><img src="@/assets/image/search.png" class="mx-auto"></a>
        </div>
        <div class="d-flex p-1 float-start" v-if="validateUserType()">
          <a href="javascript:void(0)" @click="NavCtrl.openNotiNavHandle();">

           <img src="@/assets/image/bell.png" class="mx-auto">
          </a>
        </div>
        <div class="d-flex p-1 float-start">
          <a href="javascript:void(0)" @click="NavCtrl.openRightNavHandle()"><img :src="profilePic" class="mx-auto top-avatar profile-img"></a>
        </div>
      </div>
    </div>
  </nav>

  <SideRightComponent @reload="getProfilePic"/>
  <SideLeftComponent/>
  <NotificationComponent/>
  <SearchComponent ref="searchModal"/>
  <DCAListComponent/>
  <PendingListComponent/>
</template>

<script>
import SideRightComponent from "@/components/includes/menubar/SideRightComponent";
import SideLeftComponent from "@/components/includes/menubar/SideLeftComponent";
import NotificationComponent from "@/components/includes/menubar/NotificationComponent";
import SearchComponent from "@/components/includes/menubar/SearchComponent";
import NavCtrl from "@/utils/NavCtrl";
import DCAListComponent from "@/components/includes/menubar/DCAListComponent";
import PendingListComponent from "@/components/includes/menubar/PendingListComponent";
import ValidateUtils from "@/utils/ValidateUtils";
import Constants from "@/utils/Constants";

export default {
  name: "TopBarComponent",
  components: {
    NotificationComponent, SideRightComponent ,SideLeftComponent ,DCAListComponent ,PendingListComponent ,SearchComponent},
  data() {
    return {
      loginType: '',
      username: '',
      avatarImg: require('@/assets/image/profile.png'),
      NavCtrl: NavCtrl,
      profilePic: require('@/assets/image/profile.png'),
      notiCount: 0
    }
  },
  methods: {
    async getProfilePic(){
      let params = {username: this.username};
      let resp = await this.$HttpServices.postData("/customer/GetProfilePic", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, ()=>{
        const result = resp.result;
        let avatar = this.avatarImg;
        if(result[0].picture){
          avatar = 'data:image/png;base64, '+result[0].picture;
        }
        this.profilePic = avatar;
      }, ()=>{
        this.profilePic = this.avatarImg;
      });
    },
    validateUserType(){
      return this.loginType==Constants.userType.user;
    },
    async getNoti(){
      const params = {username: this.username, noti_category_list: 'SUIT,KYC'};
      const resp = await this.$HttpServices.postData("/noti/GetNotiList", params);
      ValidateUtils.validateResponse(resp, null, ()=>{
        let noti = resp.result;
        this.notiCount = noti.length;
      }, null);
    }
  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.loginType = user.type;
    if(this.loginType==Constants.userType.user){
      this.username = user.username;
      await this.getProfilePic();
    }else if(this.loginType==Constants.userType.guest){
      this.profilePic = this.avatarImg;
    }
  }
}
</script>

<style scoped>
@media (max-width: 361px) { 
  #navbarSupportedContent > ul.navbar-nav {
    display: none;
  }

  #aTag2 {
    margin: 0px;
  }
}
</style>