<template>
  <Teleport to="body">
    <LoadingOverlayComponet :loading="flagLoadingOverlay"/>
    <div class="m-fund-detail modal fade" :id="`detail-buy${id}`" ref="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
        <div class="modal-content modal-condition form bg-main">
          <div class="modal-header">
            <h2 class="subtitle-medium d-flex align-items-center"><div class="line-left"></div> <span class="head-hidden-m flex-grow-1">{{ $t('market.buy.head') }}</span>
              <a href="javascript:void(0)" class="kkp-toggle dropdown-toggle btn-toggle me-2" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <p class="body2-medium float-end mb-0"><img src="@/assets/image/portfolio3.png" width="30" class="mx-auto"><span>{{ data.curUnitHolder }}</span></p>
              </a>
              <a :id="`cc${id}`" href="javascript:void(0)" @click="closeConfirm"><img src="@/assets/image/close.png" class="mx-auto mx-2" width="35"></a>
            </h2>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-2 col-sm-1"></div>
              <div class="col-12 col-lg-8 col-sm-10">
                <div class="content-box6">
                  <h4 class="subtitle-regular m-b8">{{ $t('market.buy.check') }}</h4>
                  <div class="subbox" v-if="data.fundData">
                    <p v-if="data?.dcaTab=='yes'" class="body2-regular m-b8">{{ $t('market.buy.headDCA') }}</p>
                    <p v-else class="body2-regular m-b8">{{ $t('market.buy.head') }}</p>
                    <div class="subbox mb-2">
                      <h5 class="body-medium">{{ data.fundData.fund_code }}</h5>
                      <p class="body2-regular hint-text">{{ (lang=='TH')?data.fundData.fund_name_TH:data.fundData.fund_name_EN }}</p>
                      <p class="float-start me-2 head-tag">Risk LV. <img :src="getRiskImage(data.fundData.fund_risk_level)" class="mx-auto"></p>
                      <p class="float-start mb-2  head-tag">{{ data.fundData.fund_class_type }}</p>
                      <div class="clearfix"></div>
                    </div>
                    <div class="subbox none-br m-b16" v-if="data.item">
                    <div class="row">
                        <div class="col-6"><p class="body2-regular c-placeholder mb-0">จำนวนทั้งสิ้น</p></div>
                        <div class="col-6"><h5 class="subtitle-medium float-end mb-0">{{ data.item.unitBuy }} THB</h5></div>
                      </div>
                    </div>
                    <h4 class="body2-regular">{{ $t('market.buy.detail') }}</h4>
                    <div class="subbox none-br m-b16" v-if="data.item" :style="[validateFund?{border: '2px solid red'}:'']">
                      <div class="row">
                        <div class="col-6 col-sm-6">
                          <p class="body2-regular float-start c-placeholder m-0">{{ $t('market.buy.transDate') }}</p>
                        </div>
                        <div class="col-6 col-sm-6">
                          <p class="body-regular float-end f-17 m-0">{{ data.today }}</p>
                        </div>
                      </div>
                      <div class="row" v-if="data.dcaTab=='yes'">
                        <div class="col-6 col-sm-6">
                          <p class="body2-regular float-start c-placeholder m-0">{{ $t('market.buy.transDateEnd') }}</p>
                        </div>
                        <div class="col-6 col-sm-6">
                          <p class="body-regular float-end f-17 m-0" v-if="!data.item.dateBuy3">ไม่ระบุ</p>
                          <p class="body-regular float-end f-17 m-0" v-else>{{ convertDate(data.item.dateBuy3) }}</p>
                        </div>
                      </div>
                      <div class="row" v-if="data.dcaTab=='yes'">
                        <div class="col-6 col-sm-6">
                          <p class="body2-regular float-start c-placeholder m-0">{{ $t('market.buy.transEverday') }}</p>
                        </div>
                        <div class="col-6 col-sm-6">
                          <p class="body-regular float-end f-17 m-0"> {{ data.day }} {{ $t('market.buy.ofTherMonth') }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 col-sm-6">
                          <p class="body2-regular float-start c-placeholder m-0" :style="[validateFund?{color: 'red'}:'']">{{ $t('market.buy.effDate') }}</p>
                        </div>
                        <div class="col-6 col-sm-6">
                          <p class="body-regular float-end f-17 m-0" v-if="data.dcaTab=='yes'" :style="[validateFund?{color: 'red'}:'']">{{ convertDate(data.item.dateBuy2) }}</p>
                          <p class="body-regular float-end f-17 m-0" v-else :style="[validateFund?{color: 'red'}:'']">{{ convertDate(data.item.dateBuy1) }}</p>
                        </div>
                      </div>
                    </div>
                    <p class="va hvr-float label-required" v-if="validateFund" style="color: red;">
                      <img src="@/assets/image/Information2.png" class="mx-auto me-2"> {{ effDateValid }}
                    </p>
                    <div class="clearfix"></div>

                    <h4 class="body2-regular">{{ $t('market.buy.paymentMethod') }}</h4>
                    <div class="subbox">
                      <div class="row g-0">
                        <div class="col-12 col-sm-12">
                          <div class="head-slider" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                            <img :src="bankImage(data.curBankName)" class="mx-auto float-start me-3">
                            <h5 class="subtitle-medium mb-0">{{ data.curBankName }}</h5>
                            <p class="float-start me-2 mb-0 head-tag m-0"><img src="@/assets/image/book.png" class="mx-auto"> {{ data.curPayment }}</p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row g-3 m-l-t24 d-block d-sm-none">
                    <div class="col-12 col-sm-6 d-none d-md-block"></div>
                    <div class="col-12 col-sm-3">
                      <a href="#">
                        <button :id="`c1-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="confirm">{{ $t('global.confirm') }}</button>
                      </a>
                    </div>
                    <div class="col-12 col-sm-3">
                      <button :id="`e1-${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="edit">{{ $t('global.edit') }}</button>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-lg-2 col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-none d-sm-block">
          <div class="container-md">
            <div class="row g-3">
              <div class="col-12 col-sm-6 d-none d-lg-block"></div>
              <div class="col-12 col-lg-3 col-sm-12 order-lg-1 order-2">
                <button :id="`e2-${id}`" type="button" class="btn w-100 btn-sec hvr-float" @click="edit">{{ $t('global.edit') }}</button>
              </div>
              <div class="col-12 col-lg-3 col-sm-12 order-lg-2 order-1">
                <button :id="`c2-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="confirm">{{ $t('global.confirm') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-pwd modal fade logout" :id="`password${id}`" ref="oldPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content modal-logout modal-medium">
        <div class="modal-body p-0">
          <div class="text-center">
            <center><img src="@/assets/image/password1.png" class="mx-auto mt-56 mb-48"></center>
            <h4 class="title-medium m-s-b16">{{ $t('passwordSetting.inputPassword') }}</h4>
            <p class="body2-regular description-text m-m-b32">{{ $t('passwordSetting.inputPasswordDetail') }}</p>
            <div class="m-b56">
              <div class="position-relative form-toggle-password">
                <input :type="passwordType" class="form-control body-regular icon icon-password" placeholder="*********" v-model="item.password" v-on:keyup.enter="verifyPasswordHandle" :id="`cp-${id}`" :disabled="flagLoadingOverlay">
                <div class="toggle-password" style="cursor: pointer;margin-left: -3px;" @click="togglePassword">
                  <img data-status-icon="close" :src="imgEye">
                </div>
              </div>
              <div class="va hvr-float label-required" v-if="validate.password">
                <img src="@/assets/image/Information2.png" class="mx-auto me-2">  {{ $t('passwordSetting.validate.passwordRequired') }}
              </div>
              <div class="va hvr-float px-2 label-required" v-if="apiError.password">
                <img src="@/assets/image/Information2.png" class="mx-auto me-2">  <span v-html="apiError.password?apiError.password:''"></span>
              </div>
            </div>

            <div class="row g-3">
              <div class="col-12 col-sm-12 col-lg-6 order-lg-1 order-md-2  order-sm-2 order-2">
                <button :id="`closeP-${id}`" type="button" data-bs-dismiss="modal" class="btn w-100 btn-sec hvr-float" @click="closePassword">
                  {{ $t('global.cancel') }}
                </button>
              </div>
              <div class="col-12 col-sm-12 col-lg-6 order-lg-2  order-md-1  order-sm-1  order-1">
                <button :id="`c3-${id}`" type="button" class="btn w-100 btn-main hvr-float" @click="verifyPasswordHandle" :disabled="flagLoadingOverlay">
                  {{ $t('global.confirm') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SlipTransactionModalComponent ref="modalSlip" @cancel="cancel"/>
  <AlertComponent ref="alertModal"/>
  <AlertConfirmComponent ref="alertConfirmModal"/>
</Teleport>
</template>

<script>
import Constants from "@/utils/Constants";
import StringUtils from "@/utils/StringUtils";
import {Modal} from "bootstrap";
import SlipTransactionModalComponent from "@/components/includes/modals/SlipTransactionModalComponent";
import moment from 'moment';
import ValidateUtils from "@/utils/ValidateUtils";
import MessageUtils from "@/utils/MessageUtils";
import LoadingOverlayComponet from "@/components/includes/LoadingOverlayComponet";
import AlertComponent from "@/components/includes/alerts/AlertComponent";
import AlertConfirmComponent from "@/components/includes/alerts/AlertConfirmComponent";

export default {
  name: "BuyFundDetailModalComponent",
  components: {SlipTransactionModalComponent, AlertComponent, LoadingOverlayComponet, AlertConfirmComponent},
  data() {
    return {
      lang: 'TH',
      username: '',
      id: StringUtils.getUUIDV4(),
      modal: null,
      data: {},
      flagLoading: false,
      flagLoadingOverlay: false,
      validate: {},
      apiError: {},
      item: {},
      validateFund: false,
      passwordType: 'password',
      imgEye: require('@/assets/image/close.svg'),
      effDateValid: this.$t('market.buy.effDateValid')
    }
  },
  methods: {
    open(data){
      this.flagLoadingOverlay = true;
      this.data = data;
      // await this.validateFundDatetime();
      this.validateFund = false;
      this.modal.show();
      this.flagLoadingOverlay = false;
    },

    closeConfirm(){
      this.$refs.alertConfirmModal.show([this.$t('market.buy.confirmCloseDesc')], 'warning', ()=>{
        this.close();
      }, this.$t('global.warning'), this.$t('market.buy.confirmCloseBTN'));
    },
    
    close(){
      this.validateFund = false;
      this.modal.hide();
      this.$emit('clear');
    },

    async confirm(){
      this.flagLoadingOverlay = true;
      this.modal.hide();
      await this.validateFundDatetime();
      // this.PasswordModal.show();
      this.flagLoadingOverlay = false;
    },

    edit(){
      this.$emit('close');
      this.modal.hide();
    },

    convertDate(date){
      return moment(String(date), 'DD/MM/YYYY').format(Constants.date.dateShortYearDisplay);
    },

    closePassword(){
      this.item.password = '';
      if(this.passwordType != 'password'){
        this.togglePassword();
      }
      this.PasswordModal.hide();
      this.modal.show();
    },

    checkValidateVerifyPassword(){
      let flagRequired = true;
      this.validate = {};
      if(!this.item.password){
        this.validate.password = true;
        flagRequired = false;
      }

      return flagRequired;
    },

    cancel(){
      this.$emit('clear');
    },

    togglePassword(){
      if(this.passwordType == 'password'){
        this.passwordType = 'text';
        this.imgEye = require('@/assets/image/open.svg');
      }
      else{
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
      }
    },

    async validateFundDatetime(){
      let eff_date = '';
      let tempDate = '';
      let trans_type = "BUY";

      if(this.data.dcaTab == 'yes'){
        tempDate = this.data.item.dateBuy2.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        trans_type = "BUY(DCA)";
        // eff_date = moment(String(this.data.item.dateBuy2)).format(Constants.date.requestDateFormat);
      }
      else{
        tempDate = this.data.item.dateBuy1.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        // eff_date = moment(String(this.data.item.dateBuy1)).format(Constants.date.requestDateFormat);
      }

      const params = {username: this.username, fund_no: this.data.fund_no, trans_type: trans_type, eff_date: eff_date};
      const resp = await this.$HttpServices.postData("/fund/ValidateFundDatetime", params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'N'){
            if(this.data.dcaTab == 'yes'){
              this.data.item.dateBuy2 = v.eff_date;
            }
            else{
              this.data.item.dateBuy1 = v.eff_date;
            }
            this.validateFund = true;
            this.modal.show();
          }
          else{
            this.PasswordModal.show();
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, null, ()=>{
          this.modal.show();
        });
        const headers = resp.header;
        const result = resp.result;
        const focusMessages = MessageUtils.getFocusMessageError(headers);
        if(focusMessages.length>0){
          focusMessages.map(v=>{
            if(v.column == 'eff_date'){
              if(v.msg_desc) this.effDateValid = v.msg_desc;

              for(const v of result){
                if(v.is_valid == 'N'){
                  if(this.data.dcaTab == 'yes'){
                    this.data.item.dateBuy2 = v.eff_date;
                  }
                  else{
                    this.data.item.dateBuy1 = v.eff_date;
                  }
                  this.validateFund = true;
                  this.modal.show();
                }
              }

            }
          });
        }
      });
    },

    async verifyPasswordHandle(){
      if(!this.checkValidateVerifyPassword()){
        return;
      }
      this.flagLoadingOverlay = true;
      const params = {username: this.username, validated_field: 'password', validated_value: this.item.password};
      const resp = await this.$HttpServices.postData("/customer/ValidateDataByUsername", params);
      this.item.password = '';
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        this.validate = {};
        this.apiError = {};
        this.PasswordModal.hide();
        this.passwordType = 'password';
        this.imgEye = require('@/assets/image/close.svg');
        const result = resp.result;
        for(const v of result){
          if(v.is_valid == 'Y'){
            await this.saveTrans();
          }
        }

      }, ()=>{
        this.PasswordModal.hide();
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError, ()=>{this.PasswordModal.show()});
      });
      this.flagLoadingOverlay = false;
    },

    async saveTrans(){
      this.flagLoadingOverlay = true;
      let params = {};
      let api = '';
      let tempDate = '';
      let eff_date = '';
      let amt = this.data.item.unitBuy;
      if(typeof amt == "string") {
        amt = amt.replaceAll(',', '');
      }

      if(this.data.dcaTab == 'yes'){
        // dca
        tempDate = this.data.item.dateBuy2.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        // eff_date = moment(String(this.data.item.dateBuy2)).format(Constants.date.requestDateFormat);

        let end_dca_eff_date = "";
        if(this.data.item.dateBuy3){
          tempDate = this.data.item.dateBuy3.split('/');
          end_dca_eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
          end_dca_eff_date = moment(String(end_dca_eff_date)).format(Constants.date.requestDateFormat);
          // end_dca_eff_date = moment(String(this.data.item.dateBuy3)).format(Constants.date.requestDateFormat);
        }

        params = {username: this.username, fund_no: this.data.fund_no, trans_type: "BUY", fund_no_sw: 0, unitholder_id: this.data.curUnitHolder, req_amt: amt, req_unit: 0, start_dca_eff_date: eff_date, end_dca_eff_date: end_dca_eff_date, frequency: "MTH", dca_order_day: parseInt(this.data.day), payment: this.data.payment, req_all_unit: 'N'};
        api = "/trans/SaveDCATrans";
      }
      else{
        tempDate = this.data.item.dateBuy1.split('/');
        eff_date = (parseInt(tempDate[2])-543) +'/'+ tempDate[1] +'/'+ tempDate[0];
        eff_date = moment(String(eff_date)).format(Constants.date.requestDateFormat);
        // eff_date = moment(String(this.data.item.dateBuy1)).format(Constants.date.requestDateFormat);
        params = {username: this.username, fund_no: this.data.fund_no, trans_type: "BUY", fund_no_sw: 0, unitholder_id: this.data.curUnitHolder, req_amt: amt, req_unit: 0, eff_date: eff_date, payment: this.data.payment, req_all_unit: 'N'};
        api = "/trans/SaveTrans";
      }

      const resp = await this.$HttpServices.postData(api, params);
      ValidateUtils.validateResponse(resp, this.$refs.alertModal, async ()=>{
        const result = resp.result;
        for(const v of result){
          if(v.save_result == 'Y'){
            let trans_status = 'trans_status';
            if(this.data.dcaTab == 'yes'){
              trans_status = 'transDCA_status'
            }
            this.$refs.modalSlip.open(v.trans_no, trans_status, 'market');
            this.$emit('clear');
            window.$("body").trigger("refreshEnquiry");
          }
        }
      }, ()=>{
        MessageUtils.displayMessage(resp, this.$refs.alertModal, this.apiError);
      });
      this.flagLoadingOverlay = false;
    },

    getRiskImage(riskLevel){
      let img = '';
      try{
        img = require(`@/assets/image/risk0${riskLevel}.png`);
      }catch(e){
        img = '';
      }
      return img;
    },

    bankImage(data){
      return StringUtils.bankImage(data, this.lang);
    },

  },
  async mounted() {
    const user = this.$store.getJsonData(Constants.session.user);
    this.username = user.username;

    // get lang
    this.lang = this.$store.getData(Constants.session.lang);

    // modal
    this.modal = new Modal(this.$refs.modal);
    this.PasswordModal = new Modal(this.$refs.oldPasswordModal);
  }
}
</script>

<style scoped>

</style>