<template>
	<div class="overlay" v-if="loading">
		<div class="popup">
			<center>
				<img class="mt-5 pt-4" width="80" src="@/assets/image/load2.gif">
				<p class="text-white">{{ $t('global.pleaseWait') }}</p>
			</center>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoadingOverlayComponet",
	props: {loading: {default: false}}
}
</script>

<style scoped>
	.overlay {
		z-index: 9999 !important;
	}
</style>